/** node_modules dependencies */
import decode from 'jwt-decode';

/** local dependencies */
import { log } from 'utils/functions';
import { ERROR_LOGS, TOKEN_NAME } from 'constants/index';

const token = {
  /**
   * Gets the JWT token from the local storage
   * @returns {string | null}
   */
  get: () => {
    return window.localStorage.getItem(TOKEN_NAME);
  },

  /**
   * Sets the JWT token from into the local storage
   * @param value {string}
   * @returns {boolean}
   */
  set: (value) => {
    window.localStorage.setItem(TOKEN_NAME, value);
    return token.get() === value;
  },

  /**
   * Removes the JWT token from the local storage
   * @return {boolean}
   */
  clear: () => {
    localStorage.removeItem(TOKEN_NAME);
    return typeof token.get() !== 'string';
  },

  /**
   * Returns whether the token is expired or not
   * @returns {boolean}
   */
  isExpired: () => {
    try {
      const { exp } = decode(token.get());
      if (typeof exp === 'undefined') {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(ERROR_LOGS.JWT_NO_EXP_PROP);
      } else if (typeof exp !== 'number') {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(ERROR_LOGS.JWT_EXP_NOT_NUMBER);
      }
      const expireDate = exp * 1000;
      const currentDate = new Date().getTime();
      return currentDate > expireDate;
    } catch (error) {
      log.error(error);
      throw error;
    }
  },
};

export default token;
