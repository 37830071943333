import clsx from 'clsx';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
// @mui
import MuiLink from '@mui/material/Link';
// components
import Anchor from 'components/Link/Anchor/Anchor';
import NextLinkComposed from 'components/Link/NextLinkComposed';

// ----------------------------------------------------------------------

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const linkDefaultPropSx = {};

const Link = forwardRef(function Link(
  {
    href = '',
    noLinkStyle = false,
    activeClassName = 'active',
    className: classNameProps = '',
    sx = linkDefaultPropSx,
    ...rest
  },
  ref
) {
  const router = useRouter();
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === href && activeClassName,
  });

  const isExternal = href?.indexOf('http') === 0 || href?.indexOf('mailto:') === 0;

  if (isExternal) {
    if (noLinkStyle) {
      return <Anchor href={href} ref={ref} className={className} {...rest} />;
    }

    return <MuiLink href={href} ref={ref} className={className} sx={{ cursor: 'pointer', ...sx }} {...rest} />;
  }

  if (noLinkStyle) {
    return <NextLinkComposed to={href} ref={ref} className={className} {...rest} />;
  }

  return (
    <MuiLink
      {...rest}
      to={href}
      ref={ref}
      className={className}
      sx={{ cursor: 'pointer', ...sx }}
      component={NextLinkComposed}
    />
  );
});

Link.propTypes = {
  className: PropTypes.string,
  noLinkStyle: PropTypes.bool,
  activeClassName: PropTypes.string,
  href: PropTypes.string,
  sx: PropTypes.shape({}),
};

export default Link;
