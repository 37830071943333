import { useCallback, useState, useRef, useLayoutEffect } from 'react';
import { isBrowser, noop } from 'react-use/lib/misc/util';

const useEnhancedSessionStorage = (key, initialValue, options) => {
  if (!isBrowser) {
    return [initialValue, noop, noop];
  }
  if (!key) {
    throw new Error('useEnhancedSessionStorage key may not be falsy');
  }

  let deserializer;
  if (options) {
    if (options.raw) {
      deserializer = (value) => value;
    } else {
      deserializer = options.deserializer;
    }
  } else {
    deserializer = JSON.parse;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const initializer = useRef((item) => {
    try {
      let serializer;
      if (options) {
        if (options.raw) {
          serializer = String;
        } else {
          serializer = options.serializer;
        }
      } else {
        serializer = JSON.stringify;
      }

      const sessionStorageValue = sessionStorage.getItem(item);
      if (sessionStorageValue !== null) {
        return deserializer(sessionStorageValue);
      }

      if (initialValue) {
        sessionStorage.setItem(item, serializer(initialValue));
      }
      return initialValue;
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState(() => initializer.current(key));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => setState(initializer.current(key)), [key]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const set = useCallback(
    (valOrFunc) => {
      try {
        const newState = typeof valOrFunc === 'function' ? valOrFunc(state) : valOrFunc;
        if (typeof newState === 'undefined') return;

        let value;

        if (options)
          if (options.raw)
            if (typeof newState === 'string') value = newState;
            else value = JSON.stringify(newState);
          else if (options.serializer) value = options.serializer(newState);
          else value = JSON.stringify(newState);
        else value = JSON.stringify(newState);

        sessionStorage.setItem(key, value);
        setState(deserializer(value));
      } catch {
        // If user is in private mode or has storage restriction
        // sessionStorage can throw. Also JSON.stringify can throw.
      }
    },
    [key, setState]
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const remove = useCallback(() => {
    try {
      sessionStorage.removeItem(key);
      setState(undefined);
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw.
    }
  }, [key, setState]);

  return [state, set, remove];
};

export default useEnhancedSessionStorage;
