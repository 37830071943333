const languages = [
  {
    name: 'French',
    flag: '/assets/flags/FR.svg',
    code: 'fr',
  },
  {
    name: 'German',
    flag: '/assets/flags/DE.svg',
    code: 'de',
  },
  {
    name: 'Dutch',
    flag: '/assets/flags/NL.svg',
    code: 'nl',
  },
  {
    name: 'Italian',
    flag: '/assets/flags/IT.svg',
    code: 'it',
  },
  {
    name: 'Spanish',
    flag: '/assets/flags/ES.svg',
    code: 'es',
  },
  {
    name: 'Danish',
    flag: '/assets/flags/DK.svg',
    code: 'da',
  },
  {
    name: 'Swedish',
    flag: '/assets/flags/SE.svg',
    code: 'sv',
  },
  {
    name: 'Portuguese',
    flag: '/assets/flags/PT.svg',
    code: 'pt',
  },
  {
    name: 'Russian',
    flag: '/assets/flags/RU.svg',
    code: 'ru',
  },
  {
    name: 'English',
    flag: '/assets/flags/GB.svg',
    code: 'en',
  },
];

export default languages;
