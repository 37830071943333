import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

import { MAX_PRODUCTS_VISIT_ALLOWED } from 'constants';

import ProductsVisitedContext from 'context/ProductsVisitedContext';

const ProductsVisitedProvider = ({ children }) => {
  const [productsVisited, setProductsVisitedLocalStorage] = useLocalStorage('productsVisited', []);

  const setProductsVisited = useCallback(
    (productId) => {
      if (productsVisited.includes(productId)) return;
      setProductsVisitedLocalStorage([...productsVisited, productId].slice(-3)); // only the last 3
    },
    [setProductsVisitedLocalStorage, productsVisited]
  );

  const state = useMemo(
    () => ({
      productsVisited,
      setProductsVisited,
      showSignupPopup: productsVisited?.length === MAX_PRODUCTS_VISIT_ALLOWED,
    }),
    [productsVisited, setProductsVisited]
  );

  return <ProductsVisitedContext.Provider value={state}>{children}</ProductsVisitedContext.Provider>;
};

ProductsVisitedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductsVisitedProvider;
