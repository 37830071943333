import { useRouter } from 'next/router';

import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import ReferralsContext from 'context/ReferralsContext';

// Constants
const LOCAL_STORAGE_KEY = 'referrals-details';

const ReferralsProvider = ({ children }) => {
  const router = useRouter();
  const [referralsDetails, setReferralsDetails] = useLocalStorage(LOCAL_STORAGE_KEY, {});

  // Derived state for query params
  const queryParamsString = useMemo(() => {
    return Object.entries(referralsDetails || {})
      .filter(([, value]) => value !== undefined && value !== null && value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
  }, [referralsDetails]);

  const updateReferrals = useCallback(
    (params) => {
      const newReferralsDetails = {
        ...referralsDetails,
        ...(params.utm_term && { utm_term: params.utm_term }),
        ...(params.referred && { referred: params.referred }),
        ...(params.referrer && { referrer: params.referrer }),
        ...(params.utm_source && { utm_source: params.utm_source }),
        ...(params.utm_medium && { utm_medium: params.utm_medium }),
        ...(params.utm_content && { utm_content: params.utm_content }),
        ...(params.utm_campaign && { utm_campaign: params.utm_campaign }),
      };

      setReferralsDetails(newReferralsDetails);
    },
    [referralsDetails, setReferralsDetails]
  );

  useEffect(() => {
    const queryParams = router.query;
    if (queryParams && Object.keys(queryParams).length > 0) {
      updateReferrals(queryParams);
    }
  }, []);

  const value = useMemo(
    () => ({
      referralsDetails,
      queryParamsString,
      updateReferrals,
    }),
    [referralsDetails, queryParamsString, updateReferrals]
  );

  return <ReferralsContext.Provider value={value}>{children}</ReferralsContext.Provider>;
};

ReferralsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReferralsProvider;
