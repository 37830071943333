import PropTypes from 'prop-types';
import BasicLayout from './BasicLayout';

const fullWidthLayoutDefaultPropChildren = () => null;

const FullWidthLayout = ({ children = fullWidthLayoutDefaultPropChildren }) => {
  return (
    <BasicLayout layoutProps={{ header: { hideTopBar: false }, content: { fullWidth: true, noPadding: true } }}>
      {children}
    </BasicLayout>
  );
};

FullWidthLayout.propTypes = {
  children: PropTypes.node,
};

export default FullWidthLayout;
