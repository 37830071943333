import PropTypes from 'prop-types';

/** MUI */
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';

/** Components */
import Link from 'components/Link/Link';

/** Hooks */
import useTopMenu from 'hooks/useTopMenu';
import TopMenuLink from 'components/Link/TopMenuLink';

/** Consts & Utils */

export const HIGHLIGHT_COLOR = '#f50300';

const topMenuAreaDefaultPropSx = {};

const TopMenuArea = ({
  children = null,
  label,
  url,
  id,
  sx = topMenuAreaDefaultPropSx,
  isHighlighted = false,
  ...restProps
}) => {
  const { selectedMenuSection } = useTopMenu();

  const MenuArea = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '2px solid transparent',
    '&:hover': {
      cursor: 'pointer',
    },
    ...(selectedMenuSection.id === id &&
      selectedMenuSection.label === label && {
        backgroundColor: isHighlighted ? HIGHLIGHT_COLOR : '#FFE4D4',
        boxShadow: '4px 0px 12px rgb( 69 79 91 / 0.2)',
      }),
    ...sx,
  }));

  const TopMenuItem = styled(Typography)(() => ({
    textAlign: 'center',
    fontSize: '0.875',
    '@media screen and (min-width: 2000px)': {
      fontSize: '1.05rem',
    },
    '@media screen and (max-width: 1025px)': {
      fontSize: '0.75rem',
    },
    color: isHighlighted ? HIGHLIGHT_COLOR : 'inherit',
    ...(selectedMenuSection.id === id &&
      selectedMenuSection.label === label && {
        color: isHighlighted ? 'white' : 'black',
      }),
  }));

  const isExternal = url?.indexOf('http') === 0 || url?.indexOf('mailto:') === 0;

  return (
    <MenuArea
      sx={{
        px: 0.75,
        py: 0.25,
        '@media (max-width: 1144px)': {
          px: '0.5rem',
        },
      }}
      {...restProps}
    >
      {isExternal ? (
        <Link href={url} passHref underline="none" data-testid="top-section__button">
          <TopMenuItem>{label}</TopMenuItem>
        </Link>
      ) : (
        <TopMenuLink href={url} passHref underline="none" data-testid="top-section__button">
          <TopMenuItem>{label}</TopMenuItem>
        </TopMenuLink>
      )}

      {children}
    </MenuArea>
  );
};

export default TopMenuArea;

TopMenuArea.propTypes = {
  sx: PropTypes.shape({}),
  isSingleLevel: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};
