import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { useRef } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { log } from 'utils/functions';
import useCookiePreferences from 'hooks/useCookiePreferences';
import Image from 'next/image';
import useAuth from 'hooks/useAuth';
import { useRouter } from 'next/router';
import { pushToDataLayer } from 'utils/gtm';

const OutterBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'visible',
})(({ visible, theme }) => ({
  bottom: 0,
  zIndex: 2,
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '50%',
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
  position: 'fixed',
  display: visible ? 'block' : 'none',
}));

const InnerPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'visible',
})(({ visible, theme }) => ({
  display: visible ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('xs')]: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: Number(theme.shape.borderRadius) * 3,
  },
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 1),
  },
}));

const CTAWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '90%',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(0.5),
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
}));

const AcceptAllButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(0.5),
    width: '100%',
    marginRight: theme.spacing(0.75),
    marginLeft: theme.spacing(0.75),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1),
  },
}));

const CookieBanner = () => {
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { save, isAccepted } = useCookiePreferences();
  const { isAuthenticated } = useAuth();
  const router = useRouter();

  const handleError = (e) => {
    log.error(e);
    enqueueSnackbar('Something went wrong!', {
      persist: false,
      title: 'Woops!',
      variant: 'error',
    });
  };

  /**
   * Handles the success of saving CCP cookies on the user's browser.
   * Shows a success snackbar
   *
   * @param message {string}
   * @returns {void}
   */
  const handleSuccessWithMessage = (message) => {
    enqueueSnackbar(message, {
      variant: 'success',
      persist: false,
    });
  };

  /**
   * Handles the click event of the "Accept All" button, saves the cookie with all the optional consents,
   * shows a success snackbar or an error one, depending on the success of saving the cookie to the user's browser.
   * In the end, closes the banner.
   *
   * @returns {void}
   */
  const onAcceptAllHandler = () => {
    save({ advertising: true, functional: true, performance: true, isAuthenticated })
      .then(() => {
        handleSuccessWithMessage('You have accepted all our Cookies');
        pushToDataLayer({ event: 'cookies_accepted' });
      })
      .catch(handleError);
  };

  const onRejectAllHandler = () => {
    save({ advertising: false, functional: false, performance: false, isAuthenticated })
      .then(() => handleSuccessWithMessage('You have rejected all our Cookies'))
      .catch(handleError);
  };

  const handlePrivacyPolicyClick = () => {
    router.push(`/privacy-policy`);
  };

  return (
    <OutterBox visible={!isAccepted} data-test="outter-box-cookie-banner">
      <InnerPaper visible={!isAccepted} ref={ref} elevation={5}>
        <Box mb={1}>
          <Image
            src="/assets/images/homepage/icons/cookie.svg"
            width={60}
            height={50}
            alt="Trust Pilot icon"
            loading="lazy"
            data-testid="outter-box-cookie-banner-img"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Typography
          variant="h4"
          gutterBottom
          component="h2"
          color="textPrimary"
          sx={{ fontWeight: 'bold' }}
          data-testid="outter-box-cookie-banner-lbl-title"
        >
          Cookie Consent
        </Typography>
        <Typography
          variant="body2"
          component="p"
          color="textSecondary"
          sx={{ textAlign: 'center' }}
          data-testid="outter-box-cookie-banner-lbl-desc"
        >
          This website uses cookies or similar technologies, to enhance your browsing experience and provide
          personalized recommendations. By continuing to use our website, you agree to our{' '}
          <Typography
            variant="span"
            component="span"
            color="textPrimary"
            onClick={handlePrivacyPolicyClick}
            sx={{ cursor: 'pointer' }}
            className="notranslate"
            data-testid="outter-box-cookie-banner-lbl-privacy-policy"
          >
            Privacy Policy
          </Typography>
          .
        </Typography>
        <CTAWrapper>
          <AcceptAllButton
            data-testid="outter-box-cookie-banner-btn-reject-consent"
            variant="outlined"
            color="primary"
            onClick={onRejectAllHandler}
          >
            Reject
          </AcceptAllButton>
          <AcceptAllButton
            data-testid="outter-box-cookie-banner-btn-accept-consent"
            size="small"
            variant="contained"
            color="info"
            onClick={onAcceptAllHandler}
          >
            ACCEPT ALL
          </AcceptAllButton>
        </CTAWrapper>
      </InnerPaper>
    </OutterBox>
  );
};

export default CookieBanner;
