import bffAPI from 'services/api/bff';

export async function getUserDetails(userId) {
  return bffAPI.get(`/users/${userId}`).then(({ data }) => data);
}
export async function getUserCreditDetails(userId) {
  return bffAPI.get(`/users/${userId}/credit`).then(({ data }) => data);
}
export async function updateRetailerEmailVerificationStatus({ userId, body }) {
  return bffAPI.post(`/users/${userId}/verify-email`, body);
}
