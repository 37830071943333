import PropTypes from 'prop-types';

// Components
import TopMenuArea, { HIGHLIGHT_COLOR } from 'components/Header/TopMenu/TopMenuArea/TopMenuArea';

const menuEditDefaultPropSx = {};

const MenuEdit = ({ id, link, sx = menuEditDefaultPropSx, onMouseEnter, isHighlighted = false, ...rest }) => {
  const topMenuAreaBundle = {
    id,
    sx: {
      '&:hover': {
        backgroundColor: isHighlighted ? HIGHLIGHT_COLOR : '#FFE4D4',
        boxShadow: '4px 0px 12px rgb( 69 79 91 / 0.2)',
      },
      ...sx,
    },
    url: link.url,
    label: link.label,
    isSingleLevel: true,
    isHighlighted,
    onMouseEnter,
    ...rest,
  };
  return <TopMenuArea {...topMenuAreaBundle} />;
};

MenuEdit.propTypes = {
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  isHighlighted: PropTypes.bool,
  sx: PropTypes.shape({}),
  id: PropTypes.number.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
};

export default MenuEdit;
