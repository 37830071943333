import PropTypes from 'prop-types';

/** Components */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Portal from '@mui/material/Portal';

/** Hooks */
import useAuth from 'hooks/useAuth';
import useTopMenu from 'hooks/useTopMenu';

/** Components */
import Link from 'components/Link/Link';
import CustomImage from 'components/CustomImage';

import TopMenuArea from 'components/Header/TopMenu/TopMenuArea/TopMenuArea';

/** Blocks */
import PortalTopMenu from 'blocks/PortalTopMenu';

const topMenuSandboxSectionDefaultPropCategoriesColumn = {};
const topMenuSandboxSectionDefaultPropProductsColumn = {};
const topMenuSandboxSectionDefaultPropWholesalersColumn = {};
const topMenuSandboxSectionDefaultPropBannersColumn = {};
const topMenuSandboxSectionDefaultPropColumnsOrder = [];
const topMenuSandboxSectionDefaultPropSx = {};

const TopMenuSandboxSection = ({
  id,
  url = '#',
  label,
  categoriesColumn = topMenuSandboxSectionDefaultPropCategoriesColumn,
  productsColumn = topMenuSandboxSectionDefaultPropProductsColumn,
  wholesalersColumn = topMenuSandboxSectionDefaultPropWholesalersColumn,
  bannersColumn = topMenuSandboxSectionDefaultPropBannersColumn,
  columnsOrder = topMenuSandboxSectionDefaultPropColumnsOrder,
  sx = topMenuSandboxSectionDefaultPropSx,
  onMouseEnter,
  onCategoriesButtonClick,
  isHighlighted = false,
  ...rest
}) => {
  const { isAuthenticated } = useAuth();
  const { target, selectedMenuSection, isItemHovered } = useTopMenu();

  const handleCategoriesButtonClick = () => {
    setTimeout(() => onCategoriesButtonClick(), 750);
  };

  const topMenuAreaBundle = {
    id,
    sx,
    url,
    label,
    onMouseEnter,
    isHighlighted,
    ...rest,
  };

  return (
    <TopMenuArea {...topMenuAreaBundle}>
      <Portal container={target.current}>
        {selectedMenuSection.id === id && selectedMenuSection.label === label && isItemHovered && (
          <PortalTopMenu onMouseEnter={onMouseEnter}>
            <Grid container direction="row" justifyContent="space-evenly" columnSpacing={2.75}>
              {columnsOrder?.map((item) => {
                if (item.Column === 'Categories' && categoriesColumn?.id) {
                  return (
                    <Grid xs={12} md={3} item key={item.id}>
                      {categoriesColumn.Title && (
                        <PortalTopMenu.Header component="p" variant="h6">
                          {categoriesColumn.Title}
                        </PortalTopMenu.Header>
                      )}
                      <Box data-testid="categories-column">
                        {categoriesColumn.categories?.map((category) => (
                          <PortalTopMenu.Button
                            component={Link}
                            href={`/product-category/${category.slug}`}
                            passHref
                            fullWidth
                            key={`${category.slug}`}
                            onClick={handleCategoriesButtonClick}
                          >
                            <PortalTopMenu.ButtonText variant="body2">{category.name}</PortalTopMenu.ButtonText>
                          </PortalTopMenu.Button>
                        ))}
                        {categoriesColumn.collections?.map((collection) => (
                          <PortalTopMenu.Button
                            component={Link}
                            href={`/collections/${collection.slug}`}
                            passHref
                            fullWidth
                            key={`${collection.name}`}
                            onClick={handleCategoriesButtonClick}
                          >
                            <PortalTopMenu.ButtonText variant="body2">{collection.name}</PortalTopMenu.ButtonText>
                          </PortalTopMenu.Button>
                        ))}
                        {categoriesColumn.links?.map((link) => (
                          <PortalTopMenu.Button
                            component={Link}
                            href={link.url}
                            passHref
                            fullWidth
                            key={link.id}
                            onClick={handleCategoriesButtonClick}
                          >
                            <PortalTopMenu.ButtonText variant="body2">{link.label}</PortalTopMenu.ButtonText>
                          </PortalTopMenu.Button>
                        ))}
                      </Box>
                    </Grid>
                  );
                }
                if (item.Column === 'Products' && productsColumn?.id) {
                  return (
                    <Grid xs={12} md={3} item key={item.id}>
                      {productsColumn.Title && (
                        <PortalTopMenu.Header component="p" variant="h6">
                          {productsColumn.Title}
                        </PortalTopMenu.Header>
                      )}
                      <Box data-testid="products-column">
                        {productsColumn.products.map((product) => (
                          <PortalTopMenu.Button
                            component={Link}
                            href={`/product/${product.slug}`}
                            passHref
                            fullWidth
                            key={`${product.slug}`}
                            onClick={handleCategoriesButtonClick}
                          >
                            <PortalTopMenu.ButtonText variant="body2">{product.name}</PortalTopMenu.ButtonText>
                          </PortalTopMenu.Button>
                        ))}
                        {productsColumn.links?.map((link) => (
                          <PortalTopMenu.Button
                            component={Link}
                            href={link.url}
                            passHref
                            fullWidth
                            key={link.id}
                            onClick={handleCategoriesButtonClick}
                          >
                            <PortalTopMenu.ButtonText variant="body2">{link.label}</PortalTopMenu.ButtonText>
                          </PortalTopMenu.Button>
                        ))}
                      </Box>
                    </Grid>
                  );
                }
                if (item.Column === 'Wholesalers' && isAuthenticated && wholesalersColumn?.id) {
                  return (
                    <Grid xs={12} md={3} item key={item.id}>
                      {wholesalersColumn.Title && (
                        <PortalTopMenu.Header component="p" variant="h6">
                          {wholesalersColumn.Title}
                        </PortalTopMenu.Header>
                      )}
                      <Box data-testid="wholesalers-column">
                        {wholesalersColumn.wholesalers.map((wholesaler) => (
                          <PortalTopMenu.Button
                            component={Link}
                            href={`/store/${wholesaler?.slug}`}
                            passHref
                            fullWidth
                            key={`${wholesaler?.slug}`}
                            onClick={handleCategoriesButtonClick}
                          >
                            <PortalTopMenu.ButtonText className="notranslate" variant="body2">
                              {wholesaler.brand_name}
                            </PortalTopMenu.ButtonText>
                          </PortalTopMenu.Button>
                        ))}
                        {wholesalersColumn.links?.map((link) => (
                          <PortalTopMenu.Button
                            component={Link}
                            href={link.url}
                            passHref
                            fullWidth
                            key={link.id}
                            onClick={handleCategoriesButtonClick}
                          >
                            <PortalTopMenu.ButtonText variant="body2">{link.label}</PortalTopMenu.ButtonText>
                          </PortalTopMenu.Button>
                        ))}
                      </Box>
                    </Grid>
                  );
                }
                if (item.Column === 'Banners' && bannersColumn?.id) {
                  return (
                    <Grid data-testid="banners-column" xs={12} md={3} item height="100%" key={item.id}>
                      {bannersColumn.Title && (
                        <PortalTopMenu.Header component="p" variant="h6">
                          {bannersColumn.Title}
                        </PortalTopMenu.Header>
                      )}
                      {bannersColumn.banner.Desktop.map((banner) => (
                        <Link href={banner.Link} passHref key={banner.id} onClick={handleCategoriesButtonClick}>
                          <CustomImage
                            alt={banner.Link}
                            src={banner.Image.url}
                            width={banner.Image.width}
                            height={banner.Image.height}
                            objectFit="contain"
                          />
                        </Link>
                      ))}
                    </Grid>
                  );
                }
                return <Grid xs={12} md={3} item key={item.id} />;
              })}
            </Grid>
          </PortalTopMenu>
        )}
      </Portal>
    </TopMenuArea>
  );
};

TopMenuSandboxSection.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  isHighlighted: PropTypes.bool,
  categoriesColumn: PropTypes.shape({
    id: PropTypes.number.isRequired,
    Title: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
    collections: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  productsColumn: PropTypes.shape({
    id: PropTypes.number.isRequired,
    Title: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  wholesalersColumn: PropTypes.shape({
    id: PropTypes.number.isRequired,
    Title: PropTypes.string,
    wholesalers: PropTypes.arrayOf(
      PropTypes.shape({
        brand_name: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  bannersColumn: PropTypes.shape({
    id: PropTypes.number.isRequired,
    Title: PropTypes.string,
    banner: PropTypes.shape({
      Type: PropTypes.oneOf(['PortalTopMenu', 'Collection', 'TopMenu']),
      Desktop: PropTypes.arrayOf(
        PropTypes.shape({
          Link: PropTypes.string.isRequired,
          Image: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        })
      ),
    }),
  }),
  columnsOrder: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      Column: PropTypes.oneOf(['Products', 'Categories', 'Wholesalers', 'Banners', 'Empty']),
    })
  ),
  sx: PropTypes.shape({}),
  onMouseEnter: PropTypes.func.isRequired,
  onCategoriesButtonClick: PropTypes.func.isRequired,
};

export default TopMenuSandboxSection;
