import { PUBLIC_VPS_API_KEY, VPS_API_URL } from 'constants/runtimeConfig';
import { isNaN } from 'lodash';
import apiFactory from 'services/api/axios';
import Stripe from 'stripe';

export const getPaymentFetcher = (jwt) => {
  const fetcher = apiFactory({
    baseURL: VPS_API_URL,
    headers: { Authorization: jwt, 'x-api-key': PUBLIC_VPS_API_KEY },
  });

  return fetcher;
};

export const validateCard = async ({ currency, user, jwt, paymentMethod, checkCreditEndpoint }) => {
  const { retailerId, retailerEmail, retailerWpId } = user;
  const fetcher = apiFactory({
    baseURL: window.location.origin,
  });

  try {
    const response = await fetcher.post('/api/payments/validate-payment-method', {
      jwt,
      retailerEmail,
      currency,
      retailerId,
      retailerWpId,
      paymentMethodId: paymentMethod,
      checkCreditEndpoint,
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCardsList = async (retailerStripeCustomerId, secretKey) => {
  try {
    const StripeInstance = new Stripe(secretKey);
    return StripeInstance.paymentMethods.list({
      customer: retailerStripeCustomerId,
      type: 'card',
    });
  } catch (err) {
    throw new Error('Error fetching card details from stripe');
  }
};

export const collectPayment = async ({
  orderId,
  paymentMethodId,
  user,
  jwt,
  currency,
  amount,
  supportedOperations,
}) => {
  const { retailerId, id: retailerWpId, email: retailerEmail } = user;
  const fetcher = apiFactory({
    baseURL: window.location.origin,
  });

  try {
    const response = await fetcher.post('/api/payments/collect-payment', {
      jwt,
      amount: Math.floor(amount),
      orderId,
      currency,
      retailerId,
      paymentMethodId,
      supportedOperations,
      retailerWpId,
      retailerEmail,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCardInstitutionName = (brand) => {
  if (brand === 'amex') return 'American Express';
  return brand.toUpperCase();
};

/**
 * Calculates the payment split between upfront and later payment.
 *
 * @param {number} orderAmount - The total order amount.
 * @param {number} availableCredit - The available credit limit.
 * @param {number} upfrontPaymentPercentage - The percentage of the order to be paid upfront (between 0 and 100).
 * @returns {{ payNowAmount: number, payLaterAmount: number }} - The calculated upfront and later payment amounts.
 */
export const calculatePayment = (orderAmount, availableCredit, upfrontPaymentPercentage) => {
  const finalOrderAmount = typeof orderAmount === 'number' && !isNaN(orderAmount) ? orderAmount : 0;
  const finalAvailableCredit = typeof availableCredit === 'number' && !isNaN(availableCredit) ? availableCredit : 0;
  const finalUpfrontPaymentPercentage =
    typeof upfrontPaymentPercentage === 'number' && !isNaN(upfrontPaymentPercentage) ? upfrontPaymentPercentage : 0;

  const upfrontFraction = finalUpfrontPaymentPercentage / 100;
  const minimumPayNow = finalOrderAmount * upfrontFraction;
  const amountToPay = finalOrderAmount - finalAvailableCredit;

  const payNow =
    minimumPayNow < finalAvailableCredit ? Math.max(minimumPayNow, amountToPay) : Math.max(amountToPay, minimumPayNow);

  return {
    payNowAmount: payNow,
    payLaterAmount: finalOrderAmount - payNow,
  };
};

export const registerCustomerForPayment = async ({ user, provider, jwt }) => {
  const { retailerId, id: retailerWpId, email: retailerEmail } = user;
  const fetcher = apiFactory({
    baseURL: window.location.origin,
  });

  try {
    const response = await fetcher.post('/api/payments/register-customer', {
      jwt,
      retailerId,
      retailerWpId,
      retailerEmail,
      provider,
    });
    return JSON.parse(response.data);
  } catch (error) {
    return error;
  }
};
