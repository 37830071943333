import PropTypes from 'prop-types';
import themeConfig from 'theme/config';

import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';

const getTheme = (additionalThemeConfig = {}) => createTheme(themeConfig, additionalThemeConfig);

const themeProviderDefaultPropsAdditionalThemeConfig = {};
const ThemeProvider = ({ children, additionalThemeConfig = themeProviderDefaultPropsAdditionalThemeConfig }) => (
  <MuiThemeProvider theme={getTheme(additionalThemeConfig)}>{children}</MuiThemeProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  additionalThemeConfig: PropTypes.shape({}),
};

export default ThemeProvider;
