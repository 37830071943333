import Container from '@mui/material/Container';
import PropTypes from 'prop-types';

import Footer from 'components/Footer';
import Header from 'components/Header';

import { useTheme } from '@mui/material/styles';

const basicLayoutDefaultPropContainerProps = {};
const basicLayoutDefaultPropLayoutProps = {};

const BasicLayout = ({
  children,
  containerProps = basicLayoutDefaultPropContainerProps,
  layoutProps = basicLayoutDefaultPropLayoutProps,
}) => {
  const { header, content, footer } = layoutProps || {};
  const theme = useTheme();

  return (
    <>
      {!header?.hide && (
        <Header hideAppBar={header?.hideAppBar} hideTopBar={header?.hideTopBar} hideTopMenu={header?.hideTopMenu} />
      )}
      {!content?.hide && (
        <Container
          disableGutters={content?.noPadding}
          maxWidth={!content?.fullWidth && 'xl'}
          sx={{
            pt: content?.noPadding ? 0 : 2,
            [theme.breakpoints.down('md')]: {
              pt: 'initial',
              px: 1,
            },
          }}
          {...containerProps}
        >
          {children}
        </Container>
      )}
      {!footer?.hide && <Footer {...footer} />}
    </>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  containerProps: PropTypes.objectOf(PropTypes.any),
  layoutProps: PropTypes.shape({
    header: PropTypes.shape({
      hide: PropTypes.bool,
      hideTopBar: PropTypes.bool,
      hideAppBar: PropTypes.bool,
      hideTopMenu: PropTypes.bool,
    }),
    content: PropTypes.shape({
      hide: PropTypes.bool,
      fullWidth: PropTypes.bool,
      noPadding: PropTypes.bool,
    }),
    footer: PropTypes.shape({
      hide: PropTypes.bool,
    }),
  }),
};

export default BasicLayout;
