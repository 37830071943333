/* eslint-disable no-case-declarations */
import useCurrency from 'hooks/useCurrency';
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import { ORDER_API_TIMEOUT_THRESHOLD } from 'constants/constants';
import useAuth from 'hooks/useAuth';
import useFeatureFlags from 'hooks/useFeatureFlags';
import produce from 'immer';
import apiFactory from 'services/api/axios';
import token from 'services/api/token';
import { isCheckoutEmpty } from 'utils/cart';
import { log, noOp } from 'utils/functions';

export const ACTION_TYPE = {
  // Checkout data related actions
  SET_CHECKOUT_DATA: 'SET_CHECKOUT_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_FIRST_REFRESH: 'SET_FIRST_REFRESH',

  // Checkout setup related actions
  SET_ADDRESS: 'SET_ADDRESS',
  SET_ADDRESS_LOADING: 'SET_ADDRESS_LOADING',
  SET_USE_BILLING_ADDRESS: 'SET_USE_BILLING_ADDRESS',
  SET_SHIPMENT: 'SET_SHIPMENT',
  SET_CREDIT_BALANCE: 'SET_CREDIT_BALANCE',
  SET_CREDIT_BALANCE_TO_USE: 'SET_CREDIT_BALANCE_TO_USE',
  SET_PAY_AMOUNTS: 'SET_PAY_AMOUNTS',
  SET_PAYMENT: 'SET_PAYMENT',
  SET_SELECTED_CARD_ID: 'SET_SELECTED_CARD_ID',
  SET_HAS_AGREED_TNC: 'SET_HAS_AGREED_TNC',
  SET_OVERDUE_AMOUNT: 'SET_OVERDUE_AMOUNT',
};

export const reducerInitialState = {
  checkoutData: {
    checkoutReadyItems: [],
    otherItems: [],
    orderSummary: {},
    orderAudit: {},
    validations: [],
  },
  checkoutSetup: {
    credit: {
      creditBalance: 0,
      payNowAmount: 0,
      payLaterAmount: 0,
      creditBalanceToUse: 0,
      canFullPayWithCredit: false,
    },
    paymentMethod: 'payNow',
    paymentType: 'list',
    selectedCardId: null,
    addressLoading: false,
    hasAgreedTnC: false,
    useBillingAddress: false,
    address: null,
    shipmentDate: null,
    shipmentType: 'asap',
    overdueAmount: 0,
  },
  loading: false,
  error: null,
  isFirstRefresh: true,
  refreshCheckoutData: noOp,
  dispatch: noOp,
};

const LocalStateContext = createContext(reducerInitialState);

const checkoutReducer = produce((draft, action) => {
  switch (action.type) {
    // Checkout data related actions
    case ACTION_TYPE.SET_CHECKOUT_DATA:
      draft.checkoutData = action.payload;
      break;
    case ACTION_TYPE.SET_LOADING:
      draft.loading = action.payload;
      break;
    case ACTION_TYPE.SET_ERROR:
      draft.error = action.payload;
      break;
    case ACTION_TYPE.SET_FIRST_REFRESH:
      draft.isFirstRefresh = action.payload;
      break;

    // Checkout setup related actions
    case ACTION_TYPE.SET_ADDRESS:
      draft.checkoutSetup.address = action.payload;
      break;
    case ACTION_TYPE.SET_ADDRESS_LOADING:
      draft.checkoutSetup.addressLoading = action.payload;
      break;
    case ACTION_TYPE.SET_WOULD_LIKE_TO_USE_BILLING_ADDRESS:
      draft.checkoutSetup.wouldLikeToUseBillingAddress = action.payload;
      break;
    case ACTION_TYPE.SET_SHIPMENT:
      const { shipmentDate, shipmentType } = action.payload;
      if (shipmentDate !== undefined) {
        draft.checkoutSetup.shipmentDate = shipmentDate;
      }
      if (shipmentType !== undefined) {
        draft.checkoutSetup.shipmentType = shipmentType;
      }
      break;
    case ACTION_TYPE.SET_CREDIT_BALANCE:
      draft.checkoutSetup.credit.creditBalance = action.payload;
      break;
    case ACTION_TYPE.SET_CREDIT_BALANCE_TO_USE:
      draft.checkoutSetup.credit.creditBalanceToUse = action.payload;
      break;
    case ACTION_TYPE.SET_PAY_AMOUNTS:
      const { payNowAmount, payLaterAmount, creditBalanceToUse } = action.payload;
      if (payNowAmount !== undefined) {
        draft.checkoutSetup.credit.payNowAmount = payNowAmount;
      }
      if (payLaterAmount !== undefined) {
        draft.checkoutSetup.credit.payLaterAmount = payLaterAmount;
      }
      if (creditBalanceToUse !== undefined) {
        draft.checkoutSetup.credit.creditBalanceToUse = creditBalanceToUse;
      }
      break;
    case ACTION_TYPE.SET_PAYMENT:
      const { paymentMethod, paymentType } = action.payload;
      if (paymentMethod !== undefined) {
        draft.checkoutSetup.paymentMethod = paymentMethod;
      }
      if (paymentType !== undefined) {
        draft.checkoutSetup.paymentType = paymentType;
      }
      break;
    case ACTION_TYPE.SET_SELECTED_CARD_ID:
      draft.checkoutSetup.selectedCardId = action.payload;
      break;
    case ACTION_TYPE.SET_HAS_AGREED_TNC:
      draft.checkoutSetup.hasAgreedTnC = action.payload;
      break;
    case ACTION_TYPE.SET_OVERDUE_AMOUNT:
      draft.checkoutSetup.overdueAmount = action.payload;
      break;
    default:
      throw new Error(
        `Invalid action type provided for "checkoutReducer": ${action.type} with value: ${JSON.stringify(
          action.payload
        )}`
      );
  }
});

const CheckoutProvider = ({ children, initialState = reducerInitialState }) => {
  const [state, dispatch] = useReducer(checkoutReducer, initialState ?? reducerInitialState);
  const { checkoutData, checkoutSetup } = state;
  const canFullPayWithCredit = useMemo(
    () => checkoutData.orderSummary?.total <= checkoutSetup.credit?.creditBalance,
    [checkoutData.orderSummary?.total, checkoutSetup.credit?.creditBalance]
  );

  const { userCredit, retailerId } = useAuth();
  const { currency } = useCurrency();
  const { SHOW_PROFIT_MARGIN } = useFeatureFlags();

  const getCheckoutCart = useCallback(
    async (voucherCode) => {
      const fetcher = apiFactory({
        baseURL: window.location.origin,
        timeout: ORDER_API_TIMEOUT_THRESHOLD,
      });
      let checkoutCart = {
        checkoutReadyItems: [],
        otherItems: [],
      };

      if (retailerId) {
        try {
          checkoutCart = await fetcher.post('api/checkout/checkout-cart', {
            retailerId,
            currency: currency.name,
            voucherCode,
            jwt: token.get(),
          });
          return checkoutCart.data;
        } catch (err) {
          dispatch({ type: ACTION_TYPE.SET_ERROR, payload: err });
          log.error('Error on getCheckoutCart. Error: ', err);
        }
      }
      return checkoutCart;
    },
    [currency, retailerId]
  );

  const getCheckoutCartWithProfit = useCallback(
    async (checkoutDataPayload) => {
      log.info('Calling getCheckoutCartWithProfit function with: ', checkoutDataPayload);
      let checkoutCartWithProfit = {
        checkoutReadyItems: [],
        otherItems: [],
      };

      if (!checkoutDataPayload || (checkoutDataPayload && Object.keys(checkoutDataPayload).length === 0)) {
        log.warn('No checkoutDataPayload provided to getCheckoutCartWithProfit function. Returning empty object.');
        return checkoutCartWithProfit;
      }

      const fetcher = apiFactory({
        baseURL: window.location.origin,
        timeout: ORDER_API_TIMEOUT_THRESHOLD,
        headers: {
          Authorization: token.get(),
        },
      });

      const url = 'api/checkout/checkout-cart-profit';
      const requestPayload = {
        ...checkoutDataPayload,
        retailerId,
      };
      log.info('Calling checkoutCartWithProfit API with: ', { url, requestPayload });

      checkoutCartWithProfit = await fetcher.post(url, requestPayload);

      log.info('Success response from getCheckoutCartWithProfit: ', checkoutCartWithProfit);
      return checkoutCartWithProfit.data;
    },
    [retailerId]
  );

  const refreshCheckoutData = useCallback(
    async (silentRefresh = false, voucherCode = null) => {
      dispatch({ type: ACTION_TYPE.SET_FIRST_REFRESH, payload: false });
      try {
        if (!silentRefresh) {
          dispatch({ type: ACTION_TYPE.SET_LOADING, payload: true });
        }

        // Get Checkout Cart Data and set it to state
        log.info('Calling getCheckoutCart function with voucherCode:', voucherCode);
        const checkoutCartData = await getCheckoutCart(voucherCode);
        log.info('Checkout Cart Data:', checkoutCartData);

        // // If Checkout Cart Data is empty, don't try to calculate profit margin
        if (isCheckoutEmpty(checkoutCartData)) {
          log.info('Checkout Cart Data is empty. Setting checkoutCartData to state without validating profit.');
          dispatch({ type: ACTION_TYPE.SET_CHECKOUT_DATA, payload: checkoutCartData });
          return;
        }

        // Try to get Checkout Cart Data with Profit Margin and set it to state (if SHOW_PROFIT_MARGIN is enabled)
        if (SHOW_PROFIT_MARGIN) {
          log.info('SHOW_PROFIT_MARGIN is enabled. Calling getCheckoutCartWithProfit with:', checkoutCartData);
          try {
            const checkoutCartDataWithProfit = await getCheckoutCartWithProfit(checkoutCartData);
            dispatch({ type: ACTION_TYPE.SET_CHECKOUT_DATA, payload: checkoutCartDataWithProfit });
          } catch (err) {
            log.error('Error calling getCheckoutCartWithProfit function. Setting checkoutCartData to state.', err);
            dispatch({ type: ACTION_TYPE.SET_CHECKOUT_DATA, payload: checkoutCartData });
          }
        } else {
          log.info('SHOW_PROFIT_MARGIN is disabled. Setting checkoutCartData to state.');
          dispatch({ type: ACTION_TYPE.SET_CHECKOUT_DATA, payload: checkoutCartData });
        }
      } catch (err) {
        throw new Error('Error calling getCheckoutCart function');
      } finally {
        if (!silentRefresh) {
          dispatch({ type: ACTION_TYPE.SET_LOADING, payload: false });
        }
      }
    },
    [SHOW_PROFIT_MARGIN, getCheckoutCart, getCheckoutCartWithProfit]
  );

  // Update User Credit Balance whenever it changes
  useEffect(() => {
    if (userCredit?.creditBalance) {
      dispatch({ type: ACTION_TYPE.SET_CREDIT_BALANCE, payload: Number(userCredit?.creditBalance || 0) * 100 });
    }
  }, [userCredit?.creditBalance]);

  // State with working functions (refreshCheckoutData, dispatch) and updated canFullPayWithCredit
  const returningState = useMemo(
    () => ({
      ...state,
      checkoutSetup: {
        ...state.checkoutSetup,
        credit: {
          ...state.checkoutSetup.credit,
          canFullPayWithCredit,
        },
      },
      refreshCheckoutData,
      dispatch,
    }),
    [state, canFullPayWithCredit, refreshCheckoutData]
  );

  return <LocalStateContext.Provider value={returningState}>{children}</LocalStateContext.Provider>;
};

const useCheckout = () => useContext(LocalStateContext);

CheckoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.shape({
    checkoutData: PropTypes.shape({
      checkoutReadyItems: PropTypes.arrayOf(PropTypes.object),
      orderAudit: PropTypes.shape({}),
      orderSummary: PropTypes.shape({}),
      otherItems: PropTypes.arrayOf(PropTypes.object),
      validations: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    checkoutSetup: PropTypes.shape({
      credit: PropTypes.shape({
        creditBalance: PropTypes.number,
        payNowAmount: PropTypes.number,
        payLaterAmount: PropTypes.number,
        creditBalanceToUse: PropTypes.number,
        canFullPayWithCredit: PropTypes.bool,
      }).isRequired,
      addressLoading: PropTypes.bool,
      hasAgreedTnC: PropTypes.bool,
      wouldLikeToUseBillingAddress: PropTypes.bool,
      address: PropTypes.shape({}),
      shipmentDate: PropTypes.string,
      shipmentType: PropTypes.string,
      overdueAmount: PropTypes.number,
    }),
    loading: PropTypes.bool,
    error: PropTypes.shape({}),
    isFirstRefresh: PropTypes.bool,
    refreshCheckoutData: PropTypes.func,
    dispatch: PropTypes.func,
  }),
};

export { CheckoutProvider, useCheckout };
