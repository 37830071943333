export const RESOLUTION = Object.freeze({
  CARD: {
    DESKTOP: '640x640',
    MOBILE: '360x360',
  },
  BANNER: {
    DESKTOP: '2880x780',
    MOBILE: '475x390',
  },
  LOGO: {
    DESKTOP: '200x200',
    MOBILE: '95x95',
  },
  PDP: {
    DESKTOP: '1200x1200',
    MOBILE: '360x360',
    THUMBNAIL: '360x360',
  },
});
