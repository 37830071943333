import bffAPI from 'services/api/bff';

export const getCurrenciesExchangeRate = async (currencies) => {
  let url = '/business-services/currency/exchange';
  if (Array.isArray(currencies) && currencies.length > 0) {
    url += `?currencies=${currencies.join(',')}`;
  }

  return bffAPI.get(url).then(({ data }) => data);
};
