import { brandFlairsList } from './flairs-filters';
import { NEXT_PUBLIC_ENV } from './runtimeConfig';

export const GUEST_TOKEN = 'GUEST';
export const USER_COOKIE_NAME = 'user';
export const PAYMENT_STATUS_COOKIE_NAME = 'payment_status';
export const LAST_CREATED_ORDER_COOKIE_NAME = 'last_created_order';
export const TOKEN_NAME = 'session-token';
export const WISHLIST_COOKIE_NAME = 'wishlist';
export const JWT_COOKIE_NAME = 'creoate_user_jwt';
export const FACEBOOK_FBC = '_fbc';
export const FACEBOOK_FBP = '_fbp';
export const USER_CREDIT_COOKIE_NAME = 'user_credit';
export const IMAGE_RESIZING_CDN_BASE_URL = 'https://media.creoate.com';
export const USER_FOLLOWING_BRANDS_COOKIE_NAME = 'user_following_brands';
export const THANK_YOU_PAGE_SESSION_STORAGE_NAME = 'thank_you_order_details';
export const CCP_COOKIE_NAME = 'ccp';
export const GOOGLE_CONCENT_COOKIE_NAME = 'google_consent';
export const UTM_PARAMS = ['utm_source', 'UTM_source', 'UTM_SOURCE', 'uTM_source'];
export const UTM_COOKIE_NAME = 'cr_user_utm_source';
export const CURRENCY_COOKIE_NAME = 'creoate_current_currency';
export const NOTIFY_DISCOUNTS_NAME = 'notify-discounts';
export const USER_CART_EMPTY_COOKIE_NAME = 'cart_empty_on_thank_you';
export const NON_LOGGED_IN_USER_ID = 'non_logged_in_user_id';
// Products Visit
export const MAX_PRODUCTS_VISIT_ALLOWED = 2;

// Brands Visit
export const MAX_BRANDS_VISIT_ALLOWED = 2;

// Feature Flags
export const FEATURE_FLAGS_COOKIE_NAME = 'feature_flags';

// Order Api Timeout Threshold
export const ORDER_API_TIMEOUT_THRESHOLD = 45000;

export const IS_PRODUCTION = NEXT_PUBLIC_ENV === 'production';

// For CreoateRecommendsSection component
export const FLAIR_ID_CREOATE_RECOMMENDS = brandFlairsList.find(
  (item) => item.code === (IS_PRODUCTION ? 'creoaterecommends' : 'creoatespecial')
).flairId;

export const ALGOLIA_PURCHASE_EVENT_OBJECT_ID_LIMIT = 20;

export const ALGOLIA_ATTRIBUTES = [
  'productIsOnOffer',
  'productCategoryName',
  'productWholesalerCountryId',
  'productWholesalerName',
  'productWholesalerBrandValues',
  'leadTime',
  'productWholesalerStoreMinOrderAmountGbp',
  'flairs',
  'productCollectionName',
];
