import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// components
import NextLink from 'next/link';
import Link from 'components/Link/Link';

// hooks
import useResponsive from 'hooks/useResponsive';

// Material-UI
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

// Components
import Logo from 'components/Logo';
import SocialsButton from 'components/SocialsButton';
import CopyrightText from 'components/Header/TopMenu/MobileTopMenu/CopyrightText';

// Constants
import ROUTES from 'constants/routes';

export const LINKS = [
  {
    headline: (
      <>
        ABOUT <span className="notranslate">CREOATE</span>
      </>
    ),
    children: [
      { name: 'About', href: ROUTES.footer.about },
      { name: 'Blog', href: ROUTES.footer.blog },
      { name: 'Join Our Team', href: ROUTES.footer.joinOurTeam },
      { name: 'Terms of Service', href: ROUTES.footer.termsService },
      { name: 'Privacy Policy', href: ROUTES.footer.privacy },
      { name: 'Contact Us', href: ROUTES.footer.contactUs },
      { name: 'What is Wholesale?', href: ROUTES.footer.WhatIsWholesale },
      { name: 'Best Wholesale Marketplaces', href: ROUTES.footer.BestMarketplaces },
      { name: 'Site Map', href: ROUTES.footer.sitemap },
      { name: 'Affiliates', href: ROUTES.footer.affiliates },
    ],
  },
  {
    headline: 'BUYING ON CREOATE',
    children: [
      { name: 'Help Center', href: ROUTES.footer.buyerHelpCenter },
      { name: 'Delivery', href: ROUTES.footer.delivery },
      { name: 'Returns', href: ROUTES.footer.returns },
    ],
  },
  {
    headline: 'SELLING ON CREOATE',
    children: [
      {
        name: (
          <>
            Sell on&nbsp;<span className="notranslate">Creoate</span>
          </>
        ),
        href: ROUTES.footer.joinUs,
      },
      { name: 'Trade Direct: Offer 90 Day Terms', href: ROUTES.footer.tradeDirect },
      { name: 'Sign up to Seller Academy', href: ROUTES.footer.SellerAcademy },
    ],
  },
  {
    headline: 'CATEGORIES',
    children: [
      { name: 'Jewellery', href: ROUTES.footer.jewellery },
      { name: 'Gifts', href: ROUTES.footer.gifts },
      { name: 'Stationery', href: ROUTES.footer.stationery },
      { name: 'Beauty', href: ROUTES.footer.beauty },
      { name: 'Birthday Cards', href: ROUTES.footer.birthday },
      { name: 'Mugs', href: ROUTES.footer.mugs },
      { name: 'Wax Melts', href: ROUTES.footer.waxMelts },
      { name: 'Candles', href: ROUTES.footer.candles },
      { name: 'Discover', href: ROUTES.footer.discover },
      { name: 'Collections', href: ROUTES.footer.collections },
      { name: 'Categories', href: ROUTES.footer.categories },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#E0E0E0',
  color: theme.palette.primary.main,
}));

const Footer = () => {
  const router = useRouter();
  const isTablet = useResponsive('down', 'md');
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!isTablet) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [isTablet]);

  const onClickHandler = async () => {
    await router.push(ROUTES.page.root);
  };

  const handleChangeControlled = (headline) => (event, isExpanded) => {
    setExpanded(isExpanded ? headline : false);
  };

  return (
    <RootStyle data-testid="footer">
      <Divider />
      <Container sx={{ pt: 4 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' }, marginBottom: { xs: '2rem', md: '2rem' } }}
        >
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Logo onClick={onClickHandler} variant="full" sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" data-testid="footer-description">
              Discover and buy unique wholesale products online at Creoate. Shop wholesale homeware, home decor
              products, jewellery, fashion accessories, stationery, gifts, food, drinks, kids and baby products etc.
              from thousands of independent wholesale vendors.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 3, mb: { xs: 2, md: 0 } }}
              data-testid="footer-social-buttons"
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack
              spacing={1}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              data-testid="footer-links"
            >
              {LINKS.map((list, index) => (
                <Accordion
                  sx={{
                    backgroundColor: 'inherit',
                    '&.MuiAccordion-root:before': {
                      display: 'none',
                    },
                  }}
                  key={list.headline}
                  elevation={0}
                  expanded={!isTablet ? true : expanded === list.headline}
                  onChange={handleChangeControlled(list.headline)}
                  disableGutters
                >
                  <AccordionSummary
                    sx={{
                      '&.MuiAccordionSummary-root': {
                        display: 'inline-flex',
                      },
                      width: !isTablet ? '100%' : 'auto',
                      p: 0,
                      justifyContent: 'flex-start',
                      minHeight: 0,
                    }}
                    expandIcon={isTablet ? <ExpandMoreIcon width={20} height={20} /> : null}
                  >
                    <Typography
                      data-testid={`footer-headline-${index}`}
                      component="p"
                      variant="overline"
                      display="inline-flex"
                      gap={0.3}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {list.headline}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: '1rem 0 0 0' }}>
                    <Stack gap={1} mb={{ xs: 1, md: 0 }}>
                      {list.children.map((link, i) => {
                        return (
                          <NextLink key={link.name} href={link.href} passHref legacyBehavior>
                            <Link
                              target="_blank"
                              color="inherit"
                              variant="body2"
                              underline="none"
                              sx={{ display: 'block' }}
                              rel="noopener noreferrer"
                              data-testid={`footer-name-${index}-${i}`}
                            >
                              {link.name}
                            </Link>
                          </NextLink>
                        );
                      })}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <CopyrightText data-testid="footer-copyright" sx={{ marginTop: { xs: '1rem' } }} />
      </Container>
    </RootStyle>
  );
};

export default Footer;
