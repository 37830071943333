import { useRouter } from 'next/router';
import { forwardRef } from 'react';

// @mui
import MuiLink from '@mui/material/Link';
import NextLink from 'next/link';
import clsx from 'clsx';

const TopMenuLink = forwardRef(function Link(props) {
  const { href, activeClassName, className: classNameProps, sx, ...rest } = props;
  const router = useRouter();

  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === href && activeClassName,
  });

  return (
    <MuiLink
      {...rest}
      component={NextLink}
      href={href}
      underline="none"
      className={className}
      sx={{ cursor: 'pointer', ...sx }}
    />
  );
});

export default TopMenuLink;
