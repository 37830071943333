import { log } from 'utils/functions';

/**
 * @type {
 *  {
 *    success: logger.success,
 *    error: logger.error
 *  }
 *}
 */
export const logger = {
  /**
   * @param instance {'axios' | 'bff' }
   * @param type {'request' | 'response'}
   * @param path [string]
   * @param payload [any]
   */
  success: ({ instance, type, path }, payload) => {
    if (!instance || !type) return;
    log.info(`[SUCCESS] Instance: ${instance} | type: ${type} | path: ${path}`, JSON.stringify(payload));
  },
  /**
   * @param instance {'axios' | 'bff' }
   * @param type {'request' | 'response'}
   * @param payload [any]
   */
  error: ({ instance, type }, payload) => {
    if (!instance || !type) {
      log.error(JSON.stringify(payload));
      return;
    }
    log.error(`[ERROR] Instance: ${instance} | type: ${type}`, JSON.stringify(payload));
  },
  /**
   * @param instance {'axios' | 'bff' }
   * @param type {'request' | 'response'}
   * @param payload [any]
   */
  warn: ({ instance, type }, payload) => {
    if (!instance || !type) {
      log.warn(JSON.stringify(payload));
      return;
    }
    log.warn(`[WARN] Instance: ${instance} | type: ${type}`, JSON.stringify(payload));
  },
};
