export const ENVIRONMENTS = {
  TEST: 'test',
  STAGING: 'staging',
  PRODUCTION: 'production',
  INTEGRATION: 'integration',
};

export const GTM_ENVS = [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.STAGING];
export const POPUPSMART_TOOL_ENVS = [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.STAGING];
export const HUBSPOT_ENVS = [ENVIRONMENTS.PRODUCTION];
export const NO_INDEXING_META_ENVS = [ENVIRONMENTS.INTEGRATION, ENVIRONMENTS.STAGING, ENVIRONMENTS.TEST];
