import { createContext } from 'react';
import { noOp } from 'utils/functions';

const CurrencyContext = createContext({
  currency: null,
  exchangeRates: null,
  setMyCurrency: noOp,
});

export default CurrencyContext;
