export const DEFAULT_BRANDS_LIMIT = 20;
export const DEFAULT_PRODUCTS_LIMIT = 48;

export const DEFAULT_SORT_BY_VALUE = 'Recommended';

export const SORT_BY = {
  LATEST: 'latest',
  RECOMMENDED: 'Recommended',
};

export const DEFAULT_LIMITS = Object.freeze({
  brands: DEFAULT_BRANDS_LIMIT,
  products: DEFAULT_PRODUCTS_LIMIT,
});
