import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import * as addressAPI from 'api/address';
import useAuth from 'hooks/useAuth';

import AddressContext from 'context/AddressContext';
import { BillingAddressSchema } from 'components/AddressForm/useAddressForm';

const AddressProvider = ({ children }) => {
  // own state
  const [billingAddress, setBillingAddress] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);

  // external dependencies
  const { user } = useAuth();
  const userCountry = useMemo(() => user?.shippingCountry, [user?.shippingCountry]);

  // context functions
  const saveBillingAddress = useCallback(
    async (addressDetails) => {
      if (user.retailerId) {
        const { shippingCountry } = user;

        const updatedAddressDetails = {
          ...addressDetails,
          countryCode: shippingCountry,
        };

        const address = await addressAPI.saveBillingAddress(user.retailerId, updatedAddressDetails);
        setBillingAddress(address);
      }
    },
    [user?.retailerId]
  );

  const saveShippingAddress = useCallback(
    async (addressDetails) => {
      if (user.retailerId) {
        const { shippingCountry } = user;

        const updatedAddressDetails = {
          ...addressDetails,
          countryCode: shippingCountry,
        };
        const address = await addressAPI.saveShippingAddress(user.retailerId, updatedAddressDetails);
        setShippingAddress(address);
      }
    },
    [user?.retailerId]
  );

  // react to log in / log out
  useEffect(() => {
    if (user?.shippingAddress) {
      setShippingAddress(user.shippingAddress);
    }
    if (user?.billingAddress) {
      setBillingAddress(user.billingAddress);
    }
  }, [user?.billingAddress, user?.shippingAddress]);

  // On startup, when shipping address is invalid, copy it from billing address
  useEffect(() => {
    (async () => {
      if (user && user.billingAddress) {
        const isShippingAddressValid = await BillingAddressSchema.isValid(shippingAddress, {
          context: { userCountry },
        });
        if (!isShippingAddressValid) {
          const isBillingAddressValid = await BillingAddressSchema.isValid(billingAddress, {
            context: { userCountry },
          });
          if (isBillingAddressValid) {
            await saveShippingAddress(billingAddress);
          }
        }
      }
    })();
  }, [billingAddress, saveShippingAddress, shippingAddress, user, userCountry]);

  // wishlist state
  const state = useMemo(
    () => ({
      billingAddress,
      shippingAddress,
      saveBillingAddress,
      saveShippingAddress,
    }),
    [billingAddress, shippingAddress, saveBillingAddress, saveShippingAddress]
  );

  return <AddressContext.Provider value={state}>{children}</AddressContext.Provider>;
};

AddressProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AddressProvider;
