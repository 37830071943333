import Typography from '@mui/material/Typography';

const CopyrightText = () => {
  return (
    <Typography
      className="notranslate"
      component="p"
      variant="body2"
      sx={{
        fontSize: { xs: '0.625rem', md: 13 },
        textAlign: { xs: 'center', md: 'left' },
        mt: { xs: 0, md: 4 },
        pb: { xs: 0, md: 2 },
      }}
    >
      {`© ${new Date().getFullYear()} CREOATE`}
    </Typography>
  );
};

export default CopyrightText;
