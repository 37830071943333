import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// @mui
import Logout from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// constants
import ROUTES from 'constants/routes';
import WP_ROUTES from 'constants/wordpress-routes';

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalMallIcon from '@mui/icons-material/LocalMallOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';

// hooks
import useMediaQuery from '@mui/material/useMediaQuery';
import useAuth from 'hooks/useAuth';
import useCart from 'hooks/useCart';
import useConfiguredRoutes from 'hooks/useConfiguredRoutes';
import useWishlist from 'hooks/useWishlist';
import useReferrals from 'hooks/useReferrals';
import useFollowing from 'hooks/useFollowing';
import useResponsive from 'hooks/useResponsive';

// Utils
import { renderKycInfo } from 'utils/functions';

// components
import SearchBar from 'components/Header/SearchBar';
import Link from 'components/Link';
import Logo from 'components/Logo';
import KycButton from '../KycButton/KycButton';

const HideAppBar = ({ handleTopMenuDrawerToggle, isScrolled }) => {
  const router = useRouter();
  const { isAuthenticated, user, userCredit, logout } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [showSearchInput, setShowSearchInput] = useState(!isScrolled);

  // routes
  const { cartRoute, sellOnCreoateRoute } = useConfiguredRoutes();

  // wishlist state
  const { wishlist } = useWishlist();
  const { following } = useFollowing();
  const [numWishlistItems, setNumWishlistItems] = useState(0);

  // cart state
  const { cart } = useCart();
  const [numCartItems, setNumCartItems] = useState(0);

  const theme = useTheme();
  const isTablet = useResponsive('down', 'md');
  const isMobile = useResponsive('down', 'sm');
  const below320PX = useMediaQuery('(max-width: 320px)');

  const { queryParamsString } = useReferrals();

  useEffect(() => {
    if (wishlist) {
      const numItems = wishlist.reduce((aggregator, current) => {
        return aggregator + current.quantity;
      }, 0);

      setNumWishlistItems(numItems);
    }
  }, [wishlist]);

  useEffect(() => {
    if (cart) {
      const numItems = cart.reduce((aggregator, current) => {
        return aggregator + current.quantity;
      }, 0);

      setNumCartItems(numItems);
    }
  }, [cart]);

  useEffect(() => {
    if (isScrolled && showSearchInput) setShowSearchInput(false);
  }, [isScrolled]);

  const handleAccountCircleIconClick = async (event) => {
    if (!isAuthenticated) await router.push(`${ROUTES.auth.login}?redirectTo=${router.asPath}`);
    else setAnchorEl(event.currentTarget);
  };

  const handleWhislistCircleIconClick = async (event) => {
    if (!isAuthenticated) await router.push(`${ROUTES.auth.login}?redirectTo=${router.asPath}`);
    else setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const onClickHandler = async () => {
    if (isAuthenticated && user.userType === 'Retailer') {
      await router.push(ROUTES.page.forYou);
    } else {
      await router.push(ROUTES.page.root);
    }
  };

  const handleSellOnCreoateClick = async () => {
    await router.push(sellOnCreoateRoute);
  };

  const handleAccountClick = async () => {
    await router.push(WP_ROUTES.account);
  };

  const handleBrandFollowedClick = async () => {
    setAnchorEl2(null);
    await router.push('/for-you/brands-you-follow?tab=brands&reference=follow');
  };

  const handleCartClick = async () => {
    await router.push(cartRoute);
  };

  const handleWishlistClick = async () => {
    setAnchorEl2(null);
    await router.push('/for-you/wishlist?tab=products&reference=wishlist');
  };

  const handleLogoutClick = async () => {
    logout(true, WP_ROUTES.logout);
    handleMenuClose();
  };

  return (
    <AppBar elevation={0} position="static">
      <StyledToolbar isTablet={isTablet}>
        <PrimaryWrapper>
          <LogoWrapper>
            <Logo onClick={onClickHandler} variant="full" width={150} height={53} />
          </LogoWrapper>

          {!isTablet && (
            <SearchBarDesktopWrapper>
              <SearchBar />
            </SearchBarDesktopWrapper>
          )}

          <AppBarControlsWrapper
            data-testid="app-bar-user-account-area"
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {!isMobile && renderKycInfo(user, userCredit) && (
              <KycButton
                data-testid="app-bar-kyc-button"
                sx={{
                  height: 30.75,
                  mr: { sm: 0.25, lg: 1 },
                  borderRadius: 1,
                  textTransform: 'uppercase',
                  boxShadow: 4,
                }}
              />
            )}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                maxHeight: '44px',
              }}
            >
              {!isAuthenticated && (
                <Box
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    [theme.breakpoints.down('md')]: { alignItems: 'center' },
                  }}
                >
                  <Button
                    size="small"
                    component={Link}
                    href={`${ROUTES.auth.login}?redirectTo=${router.asPath}`}
                    sx={{
                      [theme.breakpoints.down('md')]: {
                        textAlign: 'center',
                      },
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    data-testid="app-bar-sell-on-creoate-button"
                    size="small"
                    component={Link}
                    href={sellOnCreoateRoute}
                    onClick={handleSellOnCreoateClick}
                    sx={{
                      [theme.breakpoints.down('md')]: {
                        textAlign: 'center',
                      },
                    }}
                  >
                    Sell on creoate
                  </Button>
                  <Button
                    data-testid="app-bar-register-button"
                    size="small"
                    variant="contained"
                    component={Link}
                    href={`${WP_ROUTES.register}?${queryParamsString}`}
                    sx={{
                      py: '2px',
                      ml: '16px',
                      '@media screen and (max-width: 364px)': {
                        p: '2px',
                      },
                      [theme.breakpoints.down('md')]: {
                        textAlign: 'center',
                      },
                    }}
                  >
                    Register to buy
                  </Button>
                </Box>
              )}

              {isAuthenticated && (
                <>
                  <Tooltip title="Account settings">
                    <IconButton
                      size="large"
                      color="inherit"
                      aria-haspopup="true"
                      aria-controls="menu-user-account"
                      data-testid="app-bar-user-account-icon"
                      aria-label="account of the current user"
                      onClick={handleAccountCircleIconClick}
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          px: 0.5,
                        },
                      }}
                    >
                      <AccountCircleIcon
                        sx={{
                          height: '1.875rem',
                          '@media screen and (min-width: 2000px)': {
                            width: '2.25rem',
                            height: '2.25rem',
                          },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Access to your Favourites">
                    <IconButton
                      size="large"
                      color="inherit"
                      aria-haspopup="true"
                      aria-controls="menu-whishlist"
                      data-testid="app-bar-whishlist-icon"
                      aria-label="favourites of the current user"
                      onClick={handleWhislistCircleIconClick}
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          px: 0.5,
                        },
                      }}
                    >
                      <Badge className="notranslate" badgeContent={numWishlistItems} color="error">
                        <FavoriteBorderIcon
                          sx={{
                            height: '1.875rem',
                            '@media screen and (min-width: 2000px)': {
                              width: '2.25rem',
                              height: '2.25rem',
                            },
                          }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Access to your Cart">
                    <IconButton
                      data-testid="app-bar-cart-icon"
                      size="large"
                      color="inherit"
                      className="notranslate"
                      onClick={handleCartClick}
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          px: 0.5,
                        },
                      }}
                    >
                      <Badge data-testid="app-bar-cart-item-count" badgeContent={numCartItems} color="error">
                        <LocalMallIcon
                          sx={{
                            height: '1.875rem',
                            '@media screen and (min-width: 2000px)': {
                              width: '2.25rem',
                              height: '2.25rem',
                            },
                          }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </>
              )}

              {!below320PX && isTablet && !isAuthenticated && (
                <Button
                  variant="contained"
                  component={Link}
                  href={`${WP_ROUTES.register}?${queryParamsString}`}
                  sx={{
                    py: '2px',
                    '@media (max-width: 375px)': {
                      px: '10px',
                      fontSize: '12px',
                      lineHeight: '1.5rem',
                    },
                  }}
                >
                  Register to buy
                </Button>
              )}

              {user && (
                <Menu
                  keepMounted
                  anchorEl={anchorEl}
                  id="menu-user-account"
                  open={Boolean(anchorEl)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={handleMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 0.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                >
                  <ListItem data-testid="app-bar-welcome-message">
                    <Typography component="p" variant="subtitle1">
                      Hello <span className="notranslate"> {user.firstName}</span>!{' '}
                    </Typography>
                  </ListItem>
                  <Divider />
                  <MenuItem sx={{ mt: 0.5 }} data-testid="app-bar-account-button" onClick={handleAccountClick}>
                    <ListItemIcon fontSize="small">
                      <Avatar alt="User Image" src={user.avatar} sx={{ width: 24, height: 24 }} />
                    </ListItemIcon>
                    My account
                  </MenuItem>
                  <MenuItem data-testid="app-bar-logout-button" onClick={handleLogoutClick}>
                    <ListItemIcon>
                      <Logout sx={{ mr: 1.5 }} />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              )}

              {wishlist && (
                <Menu
                  keepMounted
                  anchorEl={anchorEl2}
                  id="menu-favourites"
                  open={Boolean(anchorEl2)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={handleMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 0.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                >
                  <MenuItem
                    sx={{ mt: 0.5 }}
                    data-testid="app-bar-wishlist-button"
                    disabled={!wishlist || !wishlist?.length || wishlist.length === 0}
                    onClick={handleWishlistClick}
                  >
                    Wishlist
                  </MenuItem>
                  <MenuItem
                    data-testid="app-bar-brandsFollowed-button"
                    disabled={!following || !following?.length || following.length === 0}
                    onClick={handleBrandFollowedClick}
                  >
                    Brands You Follow
                  </MenuItem>
                </Menu>
              )}

              {isTablet && (
                <IconButton
                  aria-label="Menu"
                  size="large"
                  color="inherit"
                  onClick={handleTopMenuDrawerToggle}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      mr: '-8px',
                    },
                    [theme.breakpoints.down('sm')]: {
                      ml: 0,
                      px: 0.5,
                    },
                  }}
                >
                  <MenuIcon sx={{ height: '1.875rem' }} />
                </IconButton>
              )}
            </Box>
          </AppBarControlsWrapper>
        </PrimaryWrapper>
        {isTablet && (
          <>
            <FlexNewLine />
            <Box sx={{ flexGrow: 1 }}>
              <SearchBar />
            </Box>
          </>
        )}
      </StyledToolbar>
    </AppBar>
  );
};

export default HideAppBar;

/* PropTypes */
HideAppBar.propTypes = {
  handleTopMenuDrawerToggle: PropTypes.func.isRequired,
  isScrolled: PropTypes.bool.isRequired,
};

/*  Styled Components */
const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'isTablet',
})(({ theme, isTablet }) => ({
  flexWrap: 'wrap',
  ...(isTablet ? { boxShadow: '0px 0px 6px #DFE3E8' } : {}),
  ...(isTablet ? { height: '100px' } : {}),
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const PrimaryWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '4.5rem',
  [theme.breakpoints.down('md')]: {
    height: '3.5rem',
  },
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '150px',
  [theme.breakpoints.between('sm', 'md')]: {
    padding: theme.spacing(0.5),
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '100px',
    maxWidth: '120px',
  },
}));

const AppBarControlsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: 'calc(100% - 120px)',
    justifyContent: 'flex-end',
  },
}));

const SearchBarDesktopWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  marginInline: theme.spacing(1),
}));

const FlexNewLine = styled(Box)(() => ({
  flexBasis: '100%',
  height: 0,
}));
