import PropTypes from 'prop-types';
import { useLocalStorage } from 'react-use';
import { useCallback, useMemo } from 'react';

import languages from 'constants/languages';
import LanguageContext from 'context/LanguageContext';

const LanguageProvider = ({ children }) => {
  // own state
  const [language, setLocalLanguage] = useLocalStorage(
    'language',
    languages.find((lang) => lang.name === 'English')
  );

  // actions
  const setLanguage = useCallback(
    (newLanguageName) => {
      setLocalLanguage(languages.find((lang) => lang.name === newLanguageName));
    },
    [setLocalLanguage]
  );

  // language state
  const state = useMemo(
    () => ({
      language,
      setLanguage,
    }),
    [language, setLanguage]
  );

  return <LanguageContext.Provider value={state}>{children}</LanguageContext.Provider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
