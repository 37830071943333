import bffAPI from 'services/api/bff';

export async function getWishlist() {
  return bffAPI.get(`/users/wishlist`).then(({ data }) => data);
}

export async function addToWishlist(wishlistId, productsToAdd) {
  return bffAPI.post(`/users/wishlist/${wishlistId}/add`, productsToAdd).then(({ data }) => data);
}

export async function removeFromWishlist(wishlistId, productToRemove) {
  return bffAPI.post(`/users/wishlist/${wishlistId}/remove`, productToRemove).then(({ data }) => data);
}
