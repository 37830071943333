import NextLink from 'next/link';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
// components
import Anchor from 'components/Link/Anchor/Anchor';

// ----------------------------------------------------------------------

const NextLinkComposed = forwardRef(function NextLinkComposed(
  { to, replace = false, scroll = true, shallow = false, prefetch = false, locale = '', ...rest },
  ref
) {
  return (
    <NextLink
      passHref
      href={to}
      scroll={scroll}
      locale={locale}
      replace={replace}
      shallow={shallow}
      prefetch={prefetch}
      legacyBehavior
    >
      <Anchor ref={ref} {...rest} />
    </NextLink>
  );
});

NextLinkComposed.propTypes = {
  /** The path or URL to navigate to. This is the only required prop */
  to: PropTypes.string.isRequired,
  /**
   * The active locale is automatically prepended. locale allows for providing a different locale.
   * When false href has to include the locale as the default behavior is disabled.
   */
  locale: PropTypes.string,
  /**
   * Prefetch the page in the background. Defaults to true. Any <Link /> that is in the viewport
   * (initially or through scroll) will be preloaded. Prefetch can be disabled by passing prefetch={false}.
   * When prefetch is set to false, prefetching will still occur on hover.
   * Pages using Static Generation will preload JSON files with the data for faster page transitions.
   * Prefetching is only enabled in production.
   */
  prefetch: PropTypes.bool,
  /** Replace the current history state instead of adding a new url into the stack. Defaults to false */
  replace: PropTypes.bool,
  /** Scroll to the top of the page after a navigation. Defaults to true */
  scroll: PropTypes.bool,
  /** Update the path of the current page without rerunning */
  shallow: PropTypes.bool,
};

export default NextLinkComposed;
