import { createContext } from 'react';
import { noOp } from 'utils/functions';

const TopMenuContext = createContext({
  error: null,
  target: null,
  topMenu: [],
  isLoading: true,
  selectedMenuSection: { id: null, label: null },
  setSelectedMenuSection: noOp(),
  isItemHovered: false,
  setIsItemHovered: noOp(),
});

export default TopMenuContext;
