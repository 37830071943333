import Markdown from 'markdown-it';

/** Hooks */
import useTopBar from 'hooks/useTopBar';
import useResponsive from 'hooks/useResponsive';

/** Components */
import LanguagePicker from 'components/LanguagePicker';

/** Material-UI */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const markdown = new Markdown();

const TopBar = () => {
  const { content } = useTopBar();
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box>
      <Box
        data-testid="top-bar"
        sx={{
          backgroundColor: 'primary.main',
          color: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '29px',
          justifyContent: 'center',
          '& a': {
            textDecoration: 'underline',
            color: 'background.default',
          },
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          align="center"
          data-testid="top-bar-text"
          dangerouslySetInnerHTML={{ __html: markdown.render(content) }}
          sx={{
            fontSize: { xs: '1rem', sm: '1rem', lg: '1rem', xxl: '1.5rem' },
            '& p': { m: { xs: 0.25, md: 0.25 } },
            px: { sm: '2rem', md: '4.5rem', lg: '4.5rem', xl: '4.5rem' },
          }}
          lineHeight="initial"
        />
      </Box>
      {!isMobile && content && <LanguagePicker />}
    </Box>
  );
};

export default TopBar;
