import React from 'react';
import NextLink from 'next/link';

// mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import Link from 'components/Link';

// hooks
import useResponsive from 'hooks/useResponsive';

// constants
import ROUTES from 'constants/routes';

const StyledBox = styled(Box)(() => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SecondaryFooter = () => {
  const isMobile = useResponsive('down', 'sm');

  return (
    <StyledBox
      sx={{
        flexDirection: 'column',
      }}
    >
      <StyledBox
        height={90}
        data-testid="secondary-app-bar-footer"
        sx={{
          backgroundColor: '#575757',
          width: '100%',
        }}
      >
        <NextLink href={ROUTES.footer.buyerHelpCenter} passHref legacyBehavior>
          <Link
            target="_blank"
            variant="body2"
            underline="always"
            sx={{
              display: 'block',
              color: 'white',
              textDecorationColor: 'white',
              '&:hover': {
                textDecorationColor: 'white',
              },
            }}
            rel="noopener noreferrer"
            data-testid="secondary-app-bar-footer-link-help-center"
          >
            Help Center
          </Link>
        </NextLink>
      </StyledBox>

      {isMobile && (
        <Box
          height={138.5}
          width="100%"
          sx={{
            backgroundColor: 'transparent',
            display: 'block',
          }}
        />
      )}
    </StyledBox>
  );
};

export default SecondaryFooter;
