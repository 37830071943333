import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocalStorage } from 'react-use';

import * as authAPI from 'api/authentication';
import * as userAPI from 'api/user';

import token from 'services/api/token';
import { log } from 'utils/functions';

import {
  CCP_COOKIE_NAME,
  GUEST_TOKEN,
  JWT_COOKIE_NAME,
  USER_COOKIE_NAME,
  USER_CREDIT_COOKIE_NAME,
  USER_FOLLOWING_BRANDS_COOKIE_NAME,
} from 'constants/index';
import AuthContext from 'context/AuthContext';

import routes from 'constants/routes';
import { NEXT_PUBLIC_BFF_URL, NEXT_PUBLIC_COOKIE_DOMAIN } from 'constants/runtimeConfig';
import useConfigs from 'hooks/useConfigs';
import { getJwtFromCookie } from 'utils/cookies';
import { GTMPostSignupEvent, GTMUserChangeEvent } from 'utils/gtm';
import { syncUserCookieAcceptance } from 'utils/syncCookie.util';
import apiFactory from 'services/api/axios';
import { serverLogger } from 'utils/serverLogs.utils';
import { registerCustomerForPayment } from 'utils/payment.utils';

const COOKIE_DOMAIN = NEXT_PUBLIC_COOKIE_DOMAIN;
const COOKIE_OPTIONS = { path: '/', domain: COOKIE_DOMAIN, maxAge: 604800 };

const AuthProvider = ({ children, userDetails, userCreditDetails }) => {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([JWT_COOKIE_NAME, CCP_COOKIE_NAME]);
  const [userCredit, setUserCredit] = useState(null);
  const { getConfigs, removeConfigs } = useConfigs();
  const [registeringStripeCustomer, setRegisteringStripeCustomer] = useState(false);

  // user data
  const [user, setUser, removeUser] = useLocalStorage(USER_COOKIE_NAME, null);
  const [userId, setUserId, removeUserId] = useLocalStorage('userId', null);
  const [retailerId, setRetailerId, removeRetailerId] = useLocalStorage('retailerId', null);

  // const renewIntervalRef = useRef(cookies.user);

  // register user with stripe if it not yet registered
  const registerStripeCustomer = async (userInfo) => {
    const jwt = token.get();
    if (!userInfo.retailerStripeCustomerId && !registeringStripeCustomer) {
      setRegisteringStripeCustomer(true);
      const { customerId } = await registerCustomerForPayment({
        user: userInfo,
        jwt,
        provider: 'stripe',
      });
      if (customerId) {
        const newUserInfo = { ...userInfo, retailerStripeCustomerId: customerId };
        setCookie(USER_COOKIE_NAME, newUserInfo, {
          ...COOKIE_OPTIONS,
          maxAge: 43200, // 12 hours
        });
        setUser(newUserInfo);
      }
      setRegisteringStripeCustomer(false);
    }
  };

  useEffect(() => {
    const jwt = getJwtFromCookie({ cookies });

    if (jwt === GUEST_TOKEN) {
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, [cookies, userId]);

  // const renewCookie = () => {
  //   console.log('DF DEBUG renewCookie');
  //   const userSession = renewIntervalRef.current;
  //   if (userSession) {
  //     const currentTime = new Date().getTime();
  //     console.log('DF DEBUG expirationTime', userSession.expires);
  //     const expirationTime = userSession.expires ? new Date(userSession.expires).getTime() : currentTime;
  //     console.log('DF DEBUG currentTime', currentTime);
  //     const timeUntilExpiration = expirationTime - currentTime;
  //     console.log('DF DEBUG timeUntilExpiration', timeUntilExpiration);
  //     // Renew the cookie if it is close to expiring (e.g., within 5 minutes)
  //     if (timeUntilExpiration < 0.5 * 60 * 1000) {
  //       console.log('DF DEBUG Cookie is about to expire. Renewing...');
  //       const newExpirationTime = new Date(currentTime + 1 * 60 * 1000); // 30 minutes from now
  //       const cookieValue = { ...userSession, expires: newExpirationTime.toUTCString() };
  //       renewIntervalRef.current =  cookieValue;
  //       setCookie(USER_COOKIE_NAME, cookieValue, {
  //         ...COOKIE_OPTIONS,
  //         maxAge: 43200, // 12 hours
  //       });
  //       console.log('DF DEBUG Cookie has been renewed.');
  //     }
  //   }
  // };

  // useEffect(() => {
  //   console.log('DF DEBUG cookies.user', cookies.user);

  //   const interval = setInterval(renewCookie, 15 * 1000); // Check every minute
  //   return () => clearInterval(interval);
  // }, [cookies.user]);

  // update userDetails
  useEffect(() => {
    console.log('DF DEBUG inside userDetails useEffect', userDetails);
    const userCookie = cookies[USER_COOKIE_NAME];

    if (
      typeof userDetails !== 'function' &&
      userDetails !== undefined &&
      userDetails &&
      (userDetails.id !== user?.id || !userCookie)
    ) {
      console.log('DF DEBUG inside userDetails useEffect setting cookie', userDetails);
      setUser(userDetails);
      GTMUserChangeEvent(userDetails);
      setUserId(userDetails.id);
      setRetailerId(userDetails.retailerId);
      setCookie(USER_COOKIE_NAME, userDetails, {
        ...COOKIE_OPTIONS,
        maxAge: 43200, // 12 hours
      });
      token.set(cookies.creoate_user_jwt);
      registerStripeCustomer(userDetails);
    }
  }, [userDetails, setCookie, setRetailerId, setUser, setUserId, cookies]);

  // update userCreditDetails
  useEffect(() => {
    if (typeof userDetails !== 'function' && userDetails !== undefined && userDetails) {
      const newUserCreditDetails = cookies?.[USER_CREDIT_COOKIE_NAME] || {};
      if (userCreditDetails?.creditLimit) {
        newUserCreditDetails.creditLimit = userCreditDetails.creditLimit;
      }
      if (userCreditDetails?.creditBalance) {
        newUserCreditDetails.creditBalance = userCreditDetails.creditBalance;
      }
      // Intentionally using "==" null to check for null or undefined
      if (!(userCreditDetails?.overdueAmount == null)) {
        newUserCreditDetails.overdueAmount = userCreditDetails.overdueAmount;
      }
      setUserCredit(newUserCreditDetails);
      setCookie(USER_CREDIT_COOKIE_NAME, newUserCreditDetails, {
        ...COOKIE_OPTIONS,
        maxAge: 43200, // 12 hours
      });
    }
  }, [userCreditDetails, setCookie, setUserCredit, cookies]);

  const removeWordPressCookie = useCallback(() => {
    const cookieNames = document.cookie.split(';');
    cookieNames.forEach((cookie) => {
      if (cookie.indexOf('wordpress') !== -1 || cookie.indexOf('woocommerce') !== -1) {
        removeCookie(cookie, COOKIE_OPTIONS);
      }
    });
  }, [removeCookie]);

  const cleanUpCreditAndOverdue = useCallback(() => {
    removeCookie(USER_CREDIT_COOKIE_NAME, COOKIE_OPTIONS);
  }, [removeCookie]);

  const fetchAndUpdateCreditAndOverdue = useCallback(async () => {
    const jwtToken = cookies[JWT_COOKIE_NAME];

    serverLogger.info(
      `Fetching user's credit async for retailerId: ${retailerId}`,
      { retailerId },
      'checkout',
      'client'
    );
    // Fetch user's credit details
    const newCreditDetails =
      (
        await apiFactory({
          baseURL: NEXT_PUBLIC_BFF_URL,
          headers: { Authorization: jwtToken },
          params: { includeBalance: 'true' },
        }).get(`/users/${userId}/credit`)
      )?.data || {};
    serverLogger.info(
      `User's credit fetched for retailerId: ${retailerId}`,
      { retailerId, newCreditDetails },
      'checkout',
      'client'
    );

    // Fetch user's overdue amount
    serverLogger.info(
      `Fetching user's overdue amount asynchronously for retailerId: ${retailerId}`,
      { retailerId },
      'checkout',
      'client'
    );
    const overdueData =
      (
        await apiFactory({
          baseURL: NEXT_PUBLIC_BFF_URL,
          headers: { Authorization: jwtToken },
          params: { includeBalance: 'true' },
        }).get(`/users/${userId}/overdueAmount`)
      )?.data?.data || {};
    serverLogger.info(
      `User's overdue amount fetched for retailerId: ${retailerId}`,
      { retailerId, overdueData },
      'checkout',
      'client'
    );

    // Merge the overdue amount into the credit details
    newCreditDetails.overdueAmount = overdueData.overdueAmount;
    serverLogger.info(
      `Setting new credit and overdue amount (merged) for retailerId: ${retailerId}`,
      { retailerId, newCreditDetails },
      'checkout',
      'client'
    );

    setUserCredit(newCreditDetails);
    setCookie(USER_CREDIT_COOKIE_NAME, newCreditDetails, { ...COOKIE_OPTIONS, maxAge: 43200 }); // 12 hours
  }, [retailerId, setCookie, userId]);

  const logout = useCallback(
    (shouldRedirect = true, redirectTo = '/') => {
      // jwt
      token.clear();
      removeCookie(JWT_COOKIE_NAME, COOKIE_OPTIONS);
      removeCookie(USER_COOKIE_NAME, COOKIE_OPTIONS);
      removeCookie(USER_CREDIT_COOKIE_NAME, COOKIE_OPTIONS);
      removeCookie(USER_FOLLOWING_BRANDS_COOKIE_NAME, COOKIE_OPTIONS);
      removeWordPressCookie();
      // user data
      removeUserId();
      removeRetailerId();
      removeUser();
      removeConfigs();

      if (shouldRedirect) {
        router.push(redirectTo);
      }
    },
    [removeCookie, router, removeRetailerId, removeUser, removeUserId, removeWordPressCookie, removeConfigs]
  );

  const updateUserDetails = useCallback(
    (details) => {
      setUser(details);
      setUserId(details.id);
      setRetailerId(details.retailerId);
      setCookie(USER_COOKIE_NAME, details, COOKIE_OPTIONS);
      registerStripeCustomer(details);
    },
    [setCookie, setRetailerId, setUser]
  );

  const getUserDetails = useCallback(
    async (userIdToFetch) => {
      try {
        const details = await userAPI.getUserDetails(userIdToFetch);
        updateUserDetails(details);
        return details;
      } catch (e) {
        log.error('Error on getUserDetails. Error: ', e);
        return Promise.reject(e);
      }
    },
    [updateUserDetails]
  );

  const getUserAndUpdateGTM = useCallback(
    async (userIdToFetch) => {
      try {
        const details = await userAPI.getUserDetails(userIdToFetch);
        await updateUserDetails(details);

        const gtmUserDetails = {
          userId: details.userId || null,
          userCountry: details.user?.shippingCountry || null,
          storeType: details.user?.storeType || null,
          email: details.user?.email || null,
          companyHouseNumber: details.user?.companyHouseNumber || null,
          businessType: details.user?.businessType || null,
        };

        GTMPostSignupEvent(gtmUserDetails);

        return details;
      } catch (e) {
        log.error('Error on getUserDetails. Error: ', e);
        return Promise.reject(e);
      }
    },
    [updateUserDetails]
  );

  // context handler
  const login = useCallback(
    async ({ identifier, password }, { shouldRedirect = false, redirectTo = '/' } = {}) => {
      try {
        const loggedInData = await authAPI.login(identifier, password);

        if (loggedInData) {
          token.set(loggedInData.jwt);
          setCookie(JWT_COOKIE_NAME, loggedInData.jwt, COOKIE_OPTIONS);
          setUserId(loggedInData.userId);

          await Promise.all([getConfigs(), getUserDetails(loggedInData.userId)]);

          if (cookies[CCP_COOKIE_NAME]) {
            syncUserCookieAcceptance();
          }

          enqueueSnackbar('Login successful', {
            variant: 'success',
          });

          if (shouldRedirect) {
            await router.push(redirectTo);
          }

          return loggedInData;
        }
        return loggedInData;
      } catch (error) {
        log.error({ error });

        logout(true, routes.auth.login);

        if (error.data?.errorCode === 'AUTH001') {
          enqueueSnackbar(`Couldn't log user in. Please review your credentials and try again`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(`Couldn't log user in. Error code ${error.data?.errorCode}. Please contact support`, {
            variant: 'error',
          });
        }

        return Promise.reject(error);
      }
    },
    [setCookie, setUserId, getUserDetails, cookies, enqueueSnackbar, router, logout, getConfigs]
  );

  const resetPassword = useCallback(
    async (e) => {
      try {
        await authAPI.resetPassword(e);

        enqueueSnackbar('Recovered password successfully', {
          variant: 'success',
        });
      } catch (error) {
        log.error('Error on resetPassword. Error: ', error);

        enqueueSnackbar(`Couldn't recover password. Please review your email and try again`, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  const state = useMemo(
    () => ({
      isAuthenticated,
      userId,
      retailerId,
      user,
      login,
      logout,
      resetPassword,
      userCredit,
      getUserAndUpdateGTM,
      fetchAndUpdateCreditAndOverdue,
      cleanUpCreditAndOverdue,
    }),
    [
      isAuthenticated,
      userId,
      retailerId,
      user,
      login,
      logout,
      resetPassword,
      userCredit,
      getUserAndUpdateGTM,
      fetchAndUpdateCreditAndOverdue,
      cleanUpCreditAndOverdue,
    ]
  );

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userDetails: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayName: PropTypes.string,
    shippingCountry: PropTypes.string,
    retailerId: PropTypes.string,
    paymentDays: PropTypes.number,
    creditLimit: PropTypes.number,
  }),
  userCreditDetails: PropTypes.shape({
    creditLimit: PropTypes.number,
    creditBalance: PropTypes.number,
  }),
};

export default AuthProvider;
