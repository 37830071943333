import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useLocalStorage } from 'react-use';
import { COOKIE_OPTIONS } from 'utils/cookies';
import { useCallback, useMemo, useEffect } from 'react';

import useAuth from 'hooks/useAuth';
import { log } from 'utils/functions';
import * as followAPI from 'api/follow';

import FollowingContext from 'context/FollowingContext';
import { USER_FOLLOWING_BRANDS_COOKIE_NAME } from 'constants';

const FollowingProvider = ({ children }) => {
  // own state
  const [following, setFollowing, removeFollowing] = useLocalStorage('following', []);
  const [, setCookie] = useCookies([USER_FOLLOWING_BRANDS_COOKIE_NAME]);

  // external dependencies
  const { userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // context functions
  const follow = useCallback(
    async (brandId) => {
      try {
        if (userId) {
          const data = await followAPI.follow(brandId);
          setFollowing(data.following);
          setCookie(USER_FOLLOWING_BRANDS_COOKIE_NAME, data.following, COOKIE_OPTIONS);

          enqueueSnackbar(`Brand followed successfully`, {
            variant: 'success',
          });
        }
      } catch (error) {
        log.error('Error on follow. Error: ', error);

        enqueueSnackbar(`Couldn't follow brand. Please try again`, {
          variant: 'error',
        });
      }
    },
    [userId, setFollowing, enqueueSnackbar, setCookie]
  );

  const unfollow = useCallback(
    async (brandId) => {
      try {
        if (userId) {
          const data = await followAPI.unfollow(brandId);
          setFollowing(data.following);
          setCookie(USER_FOLLOWING_BRANDS_COOKIE_NAME, data.following, COOKIE_OPTIONS);

          enqueueSnackbar(`Brand unfollowed successfully`, {
            variant: 'success',
          });
        }
      } catch (error) {
        log.error('Error on unfollow. Error: ', error);

        enqueueSnackbar(`Couldn't unfollow brand. Please try again`, {
          variant: 'error',
        });
      }
    },
    [userId, setFollowing, enqueueSnackbar, setCookie]
  );

  const getFollowing = useCallback(async () => {
    if (userId) {
      try {
        const data = await followAPI.getFollowing();
        setFollowing(data.following);
        setCookie(USER_FOLLOWING_BRANDS_COOKIE_NAME, data.following, COOKIE_OPTIONS);
      } catch (error) {
        log.error('Error on getFollowing. Error: ', error);
      }
    }
  }, [userId, setFollowing, setCookie]);

  // react to log in / log out
  // CANNOT ADD getFollowing TO DEPENDENCIES !!!!!
  useEffect(() => {
    (async () => {
      if (userId) {
        await getFollowing();
      } else {
        await removeFollowing();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // wishlist state
  const state = useMemo(
    () => ({
      following,
      getFollowing,
      follow,
      unfollow,
    }),
    [following, getFollowing, follow, unfollow]
  );

  return <FollowingContext.Provider value={state}>{children}</FollowingContext.Provider>;
};

FollowingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FollowingProvider;
