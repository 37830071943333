import { isEmpty, isFunction, isString } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';

import TranslateContext from 'context/TranslateContext';
import useLanguage from 'hooks/useLanguage';
import { log } from 'utils/functions';

const defaultLanguage = 'en';

function GTranslateFireEvent(element, event) {
  try {
    if (document.createEventObject) {
      const evt = document.createEventObject();
      element.fireEvent(`on${event}`, evt);
    } else {
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent(event, true, true);
      element.dispatchEvent(evt);
    }
  } catch (e) {
    log.error(`Error on google translate fire event ${event}`, e);
  }
}

const googleTranslateElementInit = () => {
  // eslint-disable-next-line no-new
  new window.google.translate.TranslateElement(
    {
      pageLanguage: defaultLanguage,
      layout: window.google.translate.TranslateElement.InlineLayout.VERTICAl,
    },
    'google-translate-element'
  );
};

const getLangIndex = (data, lang) => {
  let el = null;

  data.forEach((option, index) => {
    if (option.value === lang) {
      el = index;
    }
  });
  return el;
};

/**
 * @param lang {string}
 */
const translateTo = (lang) => {
  if (isEmpty(lang) || !isString(lang)) {
    return;
  }
  if (isFunction(window.ga)) {
    window?.ga(
      'send',
      'event',
      'GTranslate',
      lang,
      window.location.hostname + window.location.pathname + window.location.search
    );
  }
  let teCombo;
  const sel = document.getElementsByTagName('select');
  for (let i = 0; i < sel.length; i += 1)
    if (sel[i].className.indexOf('goog-te-combo') !== -1) {
      teCombo = sel[i];
      break;
    }
  if (!teCombo) {
    setTimeout(() => {
      translateTo(lang);
    }, 3000);
  } else {
    const index = getLangIndex([].slice.call(teCombo.options), lang);
    if (index === null) {
      setTimeout(() => {
        translateTo(lang);
      }, 500);
    }
    teCombo.selectedIndex = index;
    GTranslateFireEvent(teCombo, 'change');
  }
};

const TranslateProvider = ({ children }) => {
  const { language } = useLanguage();

  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useEffect(() => {
    if (language.code) {
      translateTo(language.code);
    }
  }, [language]);

  const state = useMemo(
    () => ({
      translateTo,
    }),
    []
  );

  return (
    <TranslateContext.Provider value={state}>
      <div id="google-translate-element">{children}</div>
    </TranslateContext.Provider>
  );
};

TranslateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TranslateProvider;
