import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { initialValues as fallbackValues } from 'constants/address-form';
import { GTMUpdateAddressErrorEvent, GTMUpdateBillingAddressEvent, GTMUpdateShippingAddressEvent } from 'utils/gtm';

import useAddress from 'hooks/useAddress';
import useAuth from 'hooks/useAuth';
import { useEffect, useMemo, useState } from 'react';

export const BillingAddressSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required').nullable(),
  lastName: Yup.string().required('Last name is required').nullable(),
  phone: Yup.string().required('Phone number is required').min(10, 'Minimum 10 digits'),
  vat: Yup.string()
    .test('valid-vat', 'Invalid VAT format', function (value) {
      const regexUKVAT = /^(GB|XI)\d{9}$/;
      if (value && !regexUKVAT.test(value.toUpperCase())) {
        return false;
      }
      return true;
    })
    .nullable(),
  addressLine1: Yup.string().required('Address Line 1 is required').nullable(),
  addressLine2: Yup.string().required('Address Line 2 is required').nullable(),
  zipCode: Yup.string().required('Zip code is required').nullable(),
  city: Yup.string().required('City is required').nullable(),
  state: Yup.string()
    .nullable()
    .when('$userCountry', {
      is: 'US',
      then: Yup.string().nullable().required('State is required'),
      otherwise: Yup.string().nullable(),
    }),
});

const useAddressForm = (
  { initialValues, isShipping, onSaveComplete, forceShowErrors } = { initialValues: fallbackValues }
) => {
  const { userId, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { shippingAddress, saveShippingAddress, billingAddress, saveBillingAddress } = useAddress();
  const [isBillingAddressValid, setIsBillingAddressValid] = useState(false);
  const [isShippingAddressValid, setIsShippingAddressValid] = useState(false);
  const [countryStates, setCountryStates] = useState([]);

  const userCountry = useMemo(() => user?.shippingCountry, [user?.shippingCountry]);

  /**
   * Get the states for a given country code
   * @param {string} countryCode The country code
   * @returns {Promise<any[]>} The states for the country
   */
  const getStatesByCountryCode = (countryCode) => {
    // eslint-disable-next-line import/no-unresolved
    return import('constants/statesByCountry')
      .then(({ STATES_BY_COUNTRY }) => {
        const country = STATES_BY_COUNTRY.find((o) => o.countryCode === countryCode && o.states?.length);
        return country?.states ?? [];
      })
      .catch((err) => {
        throw new Error('Error getting countries list', err);
      });
  };

  /**
   * Find a state by a key (name or isoCode) and value ignoring case when searching by name
   * @param {'name'|'isoCode'} keyToSearch The key to search by
   * @param {string} valueToSearch The value to search for
   * @returns {any|undefined} The state object
   */
  const findStateBy = (keyToSearch, valueToSearch) => {
    if (['name', 'isoCode'].indexOf(keyToSearch) === -1) {
      throw new Error('Invalid key to search');
    }

    return countryStates.find((state) => state[keyToSearch].toLowerCase() === valueToSearch.toLowerCase());
  };

  const formik = useFormik({
    initialValues,
    validate: async (values) => {
      try {
        await BillingAddressSchema.validate(values, { context: { userCountry }, abortEarly: false });
        return {};
      } catch (err) {
        if (err.inner && err.inner.length) {
          return err.inner.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.path]: cur.message,
            }),
            {}
          );
        }
        return { [err.path]: err.message };
      }
    },
    onSubmit: (values) => {
      let saveFunction;
      if (isShipping) {
        saveFunction = saveShippingAddress;
        GTMUpdateShippingAddressEvent(userId);
      } else {
        saveFunction = saveBillingAddress;
        GTMUpdateBillingAddressEvent(userId);
      }

      saveFunction(values)
        .then(() => {
          enqueueSnackbar(`Address Saved Successfully`, {
            variant: 'success',
          });
          onSaveComplete();
        })
        .catch(() => {
          GTMUpdateAddressErrorEvent(userId);
          enqueueSnackbar(`Couldn't save Address. Please try again`, {
            variant: 'error',
          });
        });
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { errors, touched, handleSubmit, getFieldProps, isSubmitting, isValid, values, resetForm } = formik;

  // Reduce cognitive complexity in the error message and helper text fields
  const cityError = (touched.city || forceShowErrors) && errors.city;
  const phoneError = (touched.phone || forceShowErrors) && errors.phone;
  const zipCodeError = (touched.zipCode || forceShowErrors) && errors.zipCode;
  const lastNameError = (touched.lastName || forceShowErrors) && errors.lastName;
  const firstNameError = (touched.firstName || forceShowErrors) && errors.firstName;
  const addressLine1Error = (touched.addressLine1 || forceShowErrors) && errors.addressLine1;
  const addressLine2Error = (touched.addressLine2 || forceShowErrors) && errors.addressLine2;
  const vatError = (touched.vat || forceShowErrors) && errors.vat;
  const stateError = (touched.state || forceShowErrors) && errors.state;

  // Update states based on the retailer's country
  useEffect(() => {
    if (userCountry === 'US') {
      getStatesByCountryCode(userCountry).then((states) => {
        setCountryStates(states);
      });
    }
  }, [userCountry]);

  // Update the validity of the addresses
  useEffect(() => {
    (async () => {
      setIsBillingAddressValid(await BillingAddressSchema.isValid(billingAddress, { context: { userCountry } }));
      setIsShippingAddressValid(await BillingAddressSchema.isValid(shippingAddress, { context: { userCountry } }));
    })();
  }, [billingAddress, shippingAddress, userCountry]);

  return {
    inputErrors: {
      cityError,
      phoneError,
      zipCodeError,
      lastNameError,
      firstNameError,
      addressLine1Error,
      addressLine2Error,
      vatError,
      stateError,
    },
    formik,
    values,
    isValid,
    resetForm,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    formikErrors: errors,
    onSaveComplete,
    userCountry,
    countryStates,
    findStateBy,
    isBillingAddressValid,
    isShippingAddressValid,
  };
};

export default useAddressForm;
