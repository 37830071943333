/**
 *
 * @param {array} exceptionCountryCodesArr array of country codes ['GB', 'FR]
 * @param {array} allCountriesArr array of all countries
 * @returns {array} array of sorted country names with the exception at the top
 */
const sortCountriesByAlphabetExcept = (exceptionCountryCodesArr, allCountriesArr) => {
  const filteredCountries = allCountriesArr.filter(
    ({ countryCode }) => !exceptionCountryCodesArr.includes(countryCode)
  );
  const exceptionCountries = allCountriesArr.filter(({ countryCode }) =>
    exceptionCountryCodesArr.includes(countryCode)
  );

  return [...exceptionCountries, ...filteredCountries.sort((a, b) => a.countryName.localeCompare(b.countryName))];
};

export const minOrderList = [
  {
    text: '£100 & less',
    slug: '100-and-less',
    value: 100,
  },
  {
    text: '£200 & less',
    slug: '200-and-less',
    value: 200,
  },
  {
    text: '£300 & less',
    slug: '300-and-less',
    value: 300,
  },
  {
    text: '£400 & less',
    slug: '400-and-less',
    value: 400,
  },
  {
    text: '£500 & less',
    slug: '500-and-less',
    value: 500,
  },
];

export const sortByList = ['Recommended', 'Latest'];

export const sortByListUpdated = [
  { text: 'Recommended', value: 'recommended', id: 'recommended' },
  { text: 'Latest', value: 'latest', id: 'latest' },
];

const allCountries = [
  { countryName: 'United Arab Emirates', countryCode: 'AE', phoneCode: '971' },
  { countryName: 'Austria', countryCode: 'AT', phoneCode: '43' },
  { countryName: 'Belgium', countryCode: 'BE', phoneCode: '32' },
  { countryName: 'Bulgaria', countryCode: 'BG', phoneCode: '359' },
  { countryName: 'Canada', countryCode: 'CA', phoneCode: '1' },
  { countryName: 'Switzerland', countryCode: 'CH', phoneCode: '41' },
  { countryName: 'China', countryCode: 'CN', phoneCode: '86' },
  { countryName: 'Cyprus', countryCode: 'CY', phoneCode: '357' },
  { countryName: 'Czech Republic', countryCode: 'CZ', phoneCode: '420' },
  { countryName: 'Germany', countryCode: 'DE', phoneCode: '49' },
  { countryName: 'Denmark', countryCode: 'DK', phoneCode: '45' },
  { countryName: 'Estonia', countryCode: 'EE', phoneCode: '372' },
  { countryName: 'Spain', countryCode: 'ES', phoneCode: '34' },
  { countryName: 'Finland', countryCode: 'FI', phoneCode: '358' },
  { countryName: 'France', countryCode: 'FR', phoneCode: '33' },
  { countryName: 'United Kingdom', countryCode: 'GB', phoneCode: '44' },
  { countryName: 'Greece', countryCode: 'GR', phoneCode: '30' },
  { countryName: 'Croatia', countryCode: 'HR', phoneCode: '385' },
  { countryName: 'Hungary', countryCode: 'HU', phoneCode: '36' },
  { countryName: 'Ireland', countryCode: 'IE', phoneCode: '353' },
  { countryName: 'Israel', countryCode: 'IL', phoneCode: '972' },
  { countryName: 'India', countryCode: 'IN', phoneCode: '91' },
  { countryName: 'Italy', countryCode: 'IT', phoneCode: '39' },
  { countryName: 'Lithuania', countryCode: 'LT', phoneCode: '370' },
  { countryName: 'Luxembourg', countryCode: 'LU', phoneCode: '352' },
  { countryName: 'Latvia', countryCode: 'LV', phoneCode: '371' },
  { countryName: 'Netherlands', countryCode: 'NL', phoneCode: '31' },
  { countryName: 'Norway', countryCode: 'NO', phoneCode: '47' },
  { countryName: 'Poland', countryCode: 'PL', phoneCode: '48' },
  { countryName: 'Portugal', countryCode: 'PT', phoneCode: '351' },
  { countryName: 'Romania', countryCode: 'RO', phoneCode: '40' },
  { countryName: 'Sweden', countryCode: 'SE', phoneCode: '46' },
  { countryName: 'Singapore', countryCode: 'SG', phoneCode: '65' },
  { countryName: 'Slovenia', countryCode: 'SI', phoneCode: '386' },
  { countryName: 'Slovakia', countryCode: 'SK', phoneCode: '421' },
  { countryName: 'Turkey', countryCode: 'TR', phoneCode: '90' },
  { countryName: 'Ukraine', countryCode: 'UA', phoneCode: '380' },
  { countryName: 'United States', countryCode: 'US', phoneCode: '1' },
  { countryName: 'England', countryCode: 'England', phoneCode: '44' },
  { countryName: 'Scotland', countryCode: 'Scotland', phoneCode: '44' },
  { countryName: 'Northern Ireland', countryCode: 'Northern Ireland', phoneCode: '44' },
  { countryName: 'Wales', countryCode: 'Wales', phoneCode: '44' },
];

const allCategories = [
  {
    name: 'Home Fragrance',
    slug: 'home-garden',
  },
  {
    name: 'Kitchen',
    slug: 'kitchen',
  },
  {
    name: 'Soft Furnishings',
    slug: 'soft-furnishings',
  },
  {
    name: 'Organisation',
    slug: 'organisation',
  },
  {
    name: 'Wall Art',
    slug: 'wall-art',
  },
  {
    name: 'Rugs & Door Mats',
    slug: 'rugs-and-door-mats',
  },
  {
    name: 'Hanging Decorations',
    slug: 'hanging-decorations',
  },
  {
    name: "Women's Clothing",
    slug: 'women-clothing',
  },
  {
    name: 'Fashion Accessories',
    slug: 'accessories',
  },
  {
    name: 'Jewellery',
    slug: 'jewellery',
  },
  {
    name: 'Skincare',
    slug: 'skincare',
  },
  {
    name: 'Bath & Body',
    slug: 'bath-and-body',
  },
  {
    name: 'Health & Wellbeing',
    slug: 'health-wellbeing',
  },
  {
    name: "Men's Grooming",
    slug: 'men-grooming',
  },
  {
    name: 'Makeup',
    slug: 'makeup',
  },
  {
    name: 'Hygiene & Personal Care',
    slug: 'hygiene-personal-care',
  },
  {
    name: 'Nail Care',
    slug: 'nails',
  },
  {
    name: 'Hair Care',
    slug: 'hair-care',
  },
  {
    name: 'Beauty Gift Sets',
    slug: 'beauty-sets',
  },
  {
    name: 'Stationery',
    slug: 'stationery',
  },
  {
    name: 'Greetings Cards',
    slug: 'greeting-cards',
  },
  {
    name: 'Gifts',
    slug: 'gifts',
  },
  {
    name: 'Hobbies & Activities',
    slug: 'hobbies-activities',
  },
  {
    name: "Men's Clothing",
    slug: 'men-clothing',
  },
  {
    name: 'Candles',
    slug: 'candles',
  },
  {
    name: 'Incense and Incense Holders',
    slug: 'incense-incense-holders',
  },
  {
    name: 'Garden & Outdoor',
    slug: 'garden-outdoor',
  },
  {
    name: 'Pet Supplies',
    slug: 'pet-supplies',
  },
  {
    name: 'Kids',
    slug: 'kids',
  },
  {
    name: 'Chocolate and Sweets',
    slug: 'chocolate-sweets',
  },
  {
    name: 'Gourmet Food',
    slug: 'gourmet-food',
  },
  {
    name: 'Gourmet Drinks',
    slug: 'gourmet-drinks',
  },
];

export const categoriesList = allCategories.sort((a, b) => a.name.localeCompare(b.name));

export const countriesList = sortCountriesByAlphabetExcept(['GB', 'US'], allCountries);

export const leadTimesList = [
  { text: '1 business day', minimumDays: 1, maximumDays: 1 },
  { text: '1-2 business days', minimumDays: 1, maximumDays: 2 },
  { text: '1-3 business days', minimumDays: 1, maximumDays: 3 },
  { text: '3-5 business days', minimumDays: 3, maximumDays: 5 },
  { text: '1-2 weeks', minimumDays: 7, maximumDays: 14 },
  { text: '2-3 weeks', minimumDays: 14, maximumDays: 21 },
  { text: '3-4 weeks', minimumDays: 21, maximumDays: 28 },
  { text: '4-6 weeks', minimumDays: 28, maximumDays: 42 },
  { text: '6-8 weeks', minimumDays: 42, maximumDays: 56 },
];

export const filterUrlQueryParams = Object.freeze({
  PRICE: 'wsp',
  BUNDLE: 'bundle',
  FLAIRS: 'flairs',
  ITEM_ID: 'item-id',
  SORT_BY: 'sort-by',
  CATEGORY: 'category',
  ON_OFFER: 'on-offer',
  CURRENCY: 'currency',
  COUNTRIES: 'countries',
  COLLECTION: 'collection',
  MIN_ORDER: 'minimum-value',
  BRAND_VALUE: 'brandValues',
  SORT_DIRECTION: 'sortDirection',
  LEAD_TIME_MAX_DAYS: 'leadtime-max-days',
  BRANDS_YOU_MIGHT_LIKE: 'brands-you-might-like',
});

export const brandValuesList = [
  { text: 'Eco friendly', value: 'Eco Friendly' },
  { text: 'LGBT Owned', value: 'LGBT owned' },
  { text: 'Vegan', value: 'Vegan' },
  { text: 'Minority owned', value: 'Minority Owned' },
  { text: 'Fair trade', value: 'Fair Trade' },
  { text: 'Hand made', value: 'Handmade' },
  { text: 'Women owned', value: 'Women Owned' },
];

export const getPriceList = ({ symbol = '£' } = { symbol: '£' }) => [
  { text: `${symbol}20 & less`, value: 20 },
  { text: `${symbol}40 & less`, value: 40 },
  { text: `${symbol}60 & less`, value: 60 },
  { text: `${symbol}80 & less`, value: 80 },
  { text: `${symbol}100 & less`, value: 100 },
];

export const sortByStorePageList = [
  { text: 'Recommended', value: 'recommended', id: 'recommended' },
  { text: 'Latest', value: 'latest' },
  { text: 'Price (Low to High)', value: 'wsp' },
  { text: 'Trending', value: 'trending', id: 'trending' },
];
