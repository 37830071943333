import { createContext } from 'react';

import { noOp } from 'utils/functions';

const WishlistContext = createContext({
  wishlist: [],
  getWishlist: noOp,
  addToWishlist: noOp,
  removeFromWishlist: noOp,
  cleanWishlistState: noOp,
});

export default WishlistContext;
