import { useRouter } from 'next/router';
import { useLocalStorage } from 'react-use';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Link from 'components/Link/Link';

import WP_ROUTES from 'constants/wordpress-routes';
import useReferrals from 'hooks/useReferrals';

const SignupPopupData = {
  id: '5303a85919490c20754da753d2db0a94b7dfdcb5907052ee1a1227389d6d4e84',
  title: 'WHOLESALE SHOPPING MADE EASY',
  subtitle1: 'Hassle-free returns',
  description1: `Return anything that doesn't sell in 60 days`,
  subtitle2: 'Free shipping',
  description2: 'We ship for free to the UK, wider Europe and the US',
  subtitle3: 'Buy now, pay later',
  description3: 'Pay upfront, or up to 60 days later',
  buttonText: 'REGISTER TO BUY',
  buttonLink: `${WP_ROUTES.register}?ref=register_button`,
  advertaisingText: `( Don't worry, we'll bring you back to this product right after )`,
};

const SignupPopup = () => {
  const router = useRouter();
  const [, setRedirectUrl] = useLocalStorage('redirect-url', '');
  const { queryParamsString } = useReferrals();

  const onSignupClick = async () => {
    const currentPath = router.asPath;
    await setRedirectUrl(currentPath);
    const registerUrl = `${SignupPopupData.buttonLink}&${queryParamsString}`;
    router.push(registerUrl);
  };

  return (
    <SignupPopupWrapper id={SignupPopupData.id}>
      <ContentStyle>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1.5} sx={{ maxWidth: 375 }}>
          <Typography variant="h5" sx={{ textAlign: 'center', color: 'white' }}>
            {SignupPopupData.title}
          </Typography>
          <Stack direction="column" spacing={0.5}>
            <Stack direction="column" spacing={0.315}>
              <Typography variant="h6" sx={{ textAlign: 'center', color: 'white' }}>
                {SignupPopupData.subtitle1}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center', color: 'white' }}>
                {SignupPopupData.description1}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={0.315}>
              <Typography variant="h6" sx={{ textAlign: 'center', color: 'white' }}>
                {SignupPopupData.subtitle2}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center', color: 'white' }}>
                {SignupPopupData.description2}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={0.315}>
              <Typography variant="h6" sx={{ textAlign: 'center', color: 'white' }}>
                {SignupPopupData.subtitle3}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center', color: 'white' }}>
                {SignupPopupData.description3}
              </Typography>
            </Stack>
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            borderRadius="8px"
            component={Link}
            href={`${SignupPopupData.buttonLink}&${queryParamsString}`}
            onClick={onSignupClick}
          >
            {SignupPopupData.buttonText}
          </Button>
          <Typography variant="caption" sx={{ textAlign: 'center', color: 'white' }}>
            {SignupPopupData.advertaisingText}
          </Typography>
        </Stack>
      </ContentStyle>
    </SignupPopupWrapper>
  );
};

export default SignupPopup;

const SignupPopupWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: 'url(/assets/images/ctas/SignUpCTA_Popup.webp)',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderRadius: 16,
}));

const ContentStyle = styled('div')(() => ({
  textAlign: 'center',
  display: 'flex',
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));
