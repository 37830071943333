import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { useEffect, useState } from 'react';
import { syncUserCookieAcceptance } from 'utils/syncCookie.util';
import { useCookies } from 'react-cookie';
import { CCP_COOKIE_NAME, GOOGLE_CONCENT_COOKIE_NAME } from 'constants';
import { GTMGoogleConsent } from 'utils/gtm';

const expires = 365 * 24 * 60 * 60 * 1000; // 1 year
const COOKIE_OPTIONS = { path: '/', maxAge: expires };

/**
 * Parses ccp cookie and checks if any of the consents is bigger than zero.
 *
 * @param ccp {string}
 * @return {boolean}
 */
const parse = (ccp) => {
  if (!isString(ccp)) {
    throw new Error('Parameter "ccp" must be a string');
  }
  if (isEmpty(ccp)) {
    throw new Error('Parameter "ccp" must not be empty');
  }

  return ccp
    .split('_')
    .map((consent) => Number(consent.split('.')[2]) > 0)
    .some((timestampBiggerThanZero) => timestampBiggerThanZero === true);
};

const CCPPossibleValues = ['denied', 'granted'];

/**
 * Hook to get and set cookie preferences.
 *
 * @contract a.0.0_f.0.0_p.0.0
 * The cookie "ccp" has the following structure:
 * - a.0.0
 *    - the firt param, "a" means advertising consent
 *    - the second param, "0" means the default value for the cookie param ("a", "f" or "p"). The value must be >= 0 & <= 1 (truthy or falsy)
 *    - the third param, "0" means the default timestamp for the cookie param. The value must be >= 0
 *    - All three params are separated by a dot (".")
 * - f.0.0
 *    - the firt param, "f" means advertising consent
 *    - the second param, "0" means the default value for the cookie param ("a", "f" or "p"). The value must be >= 0 & <= 1 (truthy or falsy)
 *    - the third param, "0" means the default timestamp for the cookie param. The value must be >= 0
 *    - All three params are separated by a dot (".")
 * - p.0.0
 *    - the firt param, "p" means advertising consent
 *    - the second param, "0" means the default value for the cookie param ("a", "f" or "p"). The value must be >= 0 & <= 1 (truthy or falsy)
 *    - the third param, "0" means the default timestamp for the cookie param. The value must be >= 0
 *    - All three params are separated by a dot (".")
 * All three params ("a.0.0", "f.0.0", "p.0.0") are separated by an underscore ("_").
 *
 * @returns {Object}
 */

const useCookiePreferences = () => {
  // eslint-disable-next-line no-unused-vars
  const [{ [CCP_COOKIE_NAME]: ccp, [GOOGLE_CONCENT_COOKIE_NAME]: googleConcent }, setCookie] = useCookies([
    CCP_COOKIE_NAME,
    GOOGLE_CONCENT_COOKIE_NAME,
  ]);
  const [isAccepted, setIsAccepted] = useState(true);

  useEffect(() => {
    const acceptedAdvertisingValue = Number(ccp?.split('.')[1] || 0);
    const isConsentAccepted = Boolean(acceptedAdvertisingValue);

    setCookie(
      GOOGLE_CONCENT_COOKIE_NAME,
      {
        ad_storage: CCPPossibleValues[acceptedAdvertisingValue],
        analytics_storage: CCPPossibleValues[acceptedAdvertisingValue],
        ad_user_data: CCPPossibleValues[acceptedAdvertisingValue],
        ad_personalization: CCPPossibleValues[acceptedAdvertisingValue],
        functionality_storage: CCPPossibleValues[acceptedAdvertisingValue],
        personalization_storage: CCPPossibleValues[acceptedAdvertisingValue],
        security_storage: CCPPossibleValues[acceptedAdvertisingValue],
        wait_for_update: 500,
      },
      COOKIE_OPTIONS
    );

    GTMGoogleConsent(isConsentAccepted);
    setIsAccepted(ccp ? parse(ccp) : false);
  }, [ccp]);

  const save = ({ advertising = false, functional = false, performance = false, isAuthenticated = false }) => {
    return new Promise((resolve, reject) => {
      try {
        /** @type {Date} */
        const now = Date.now();
        setCookie(
          CCP_COOKIE_NAME,
          `a.${Number(advertising)}.${now}_f.${Number(functional)}.${now}_p.${Number(performance)}.${now}`,
          COOKIE_OPTIONS
        );

        if (isAuthenticated) {
          syncUserCookieAcceptance();
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    save,
    isAccepted,
    // a: {
    //   get: () => {
    //     return cookies.ccp ? cookies.ccp.split('_a.')[1].split('_')[0] : 0;
    //   },
    //   set: () => {
    //     const now = new Date();
    //     updateCcp('ccp', `a.${a}._f.${f}.p.${p}`, { path: '/' });
    //   },
    // },
  };
};

export default useCookiePreferences;
