import React from 'react';
import { useRouter } from 'next/router';

// mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import Logo from 'components/Logo';

// hooks
import useResponsive from 'hooks/useResponsive';

// constants
import ROUTES from 'constants/routes';

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '150px',
  [theme.breakpoints.between('sm', 'md')]: {
    padding: theme.spacing(0.5),
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '100px',
    maxWidth: '120px',
  },
}));

const SecondaryHeader = () => {
  const isDesktop = useResponsive('up', 'lg');
  const router = useRouter();

  const handleLogoClick = async () => {
    await router.push(ROUTES.page.root);
  };

  return (
    <Box
      data-testid="secondary-app-bar-header"
      height={isDesktop ? 90 : 50}
      sx={{
        top: 0,
        zIndex: 5,
        backgroundColor: '#D9D9D9',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: `1rem ${isDesktop ? '2rem' : '1rem'}`,
      }}
    >
      <LogoWrapper>
        <Logo
          width={150}
          height={53}
          variant="full"
          onClick={handleLogoClick}
          data-testid="secondary-app-bar-header-logo"
        />
      </LogoWrapper>
    </Box>
  );
};

export default SecondaryHeader;
