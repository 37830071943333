import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

// Components
import Link from 'components/Link/Link';
// Icons
import PinterestIcon from '@mui/icons-material/Pinterest';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

// Constants
import ROUTES from 'constants/routes';

const socialsButtonDefaultPropSx = {};

// ----------------------------------------------------------------------

const SocialsButton = ({ initialColor = false, simple = true, sx = socialsButtonDefaultPropSx, ...other }) => {
  const SOCIALS = [
    {
      name: 'FaceBook',
      icon: <FacebookIcon />,
      socialColor: '#1877F2',
      path: ROUTES.footer.facebook,
    },
    {
      name: 'Pinterest',
      icon: <PinterestIcon />,
      socialColor: '#00AAEC',
      path: ROUTES.footer.pinterest,
    },
    {
      name: 'Instagram',
      icon: <InstagramIcon />,
      socialColor: '#E02D69',
      path: ROUTES.footer.instagram,
    },
    {
      name: 'Linkedin',
      icon: <LinkedInIcon />,
      socialColor: '#007EBB',
      path: ROUTES.footer.linkedin,
    },
  ];

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center">
      {SOCIALS.map((social) => {
        const { name, icon, path, socialColor } = social;
        return simple ? (
          <Link key={name} href={path} target="_blank">
            <Tooltip title={name} placement="top">
              <IconButton
                color="inherit"
                sx={{
                  width: 20,
                  height: 20,
                  ...(initialColor && {
                    color: socialColor,
                    '&:hover': {
                      bgcolor: alpha(socialColor, 0.08),
                    },
                  }),
                  ...sx,
                }}
                {...other}
              >
                {icon}
              </IconButton>
            </Tooltip>
          </Link>
        ) : (
          <Button
            key={name}
            href={path}
            color="inherit"
            variant="outlined"
            size="small"
            startIcon={icon}
            sx={{
              m: 0.5,
              flexShrink: 0,
              ...(initialColor && {
                color: socialColor,
                borderColor: socialColor,
                '&:hover': {
                  borderColor: socialColor,
                  bgcolor: alpha(socialColor, 0.08),
                },
              }),
              ...sx,
            }}
            {...other}
          >
            {name}
          </Button>
        );
      })}
    </Stack>
  );
};

export default SocialsButton;

SocialsButton.propTypes = {
  initialColor: PropTypes.bool,
  simple: PropTypes.bool,
  sx: PropTypes.shape({}),
};
