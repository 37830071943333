import { Resource } from '@opentelemetry/resources';
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import { WebTracerProvider, BatchSpanProcessor } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { NEXT_PUBLIC_OTEL_EXPORTER_OTLP_TRACES_ENDPOINT, NEXT_PUBLIC_ENV } from 'constants/runtimeConfig';
import { SpanStatusCode } from '@opentelemetry/api';

const isClient = typeof window !== 'undefined';

if (isClient) {
  const isLocalHost = window.location.hostname === 'localhost';

  if (!isLocalHost) {
    const collectorExporter = new OTLPTraceExporter({
      url: NEXT_PUBLIC_OTEL_EXPORTER_OTLP_TRACES_ENDPOINT,
      headers: {},
    });

    const provider = new WebTracerProvider({
      resource: new Resource({
        [ATTR_SERVICE_NAME]: `${NEXT_PUBLIC_ENV}-retailer-site`,
      }),
    });

    provider.addSpanProcessor(new BatchSpanProcessor(collectorExporter));
    provider.register();
    const propagateTraceHeaderCorsUrls = [
      /https?:\/\/([a-zA-Z0-9_-]+\.)*creoate\.com(\/[^\s]*)?/g,
      /https?:\/\/([a-zA-Z0-9_-]+\.)*creoate-tech\.com(\/[^\s]*)?/g,
      /https?:\/\/([a-zA-Z0-9_-]+\.)*amazonaws\.com(\/[^\s]*)?/g,
    ];

    const ignoreUrls = [
      /https?:\/\/([a-zA-Z0-9_-]+\.)*clarity\.ms(\/[^\s]*)?/g,
      /https?:\/\/([a-zA-Z0-9_-]+\.)*analytics\.google\.com(\/[^\s]*)?/g,
      /https?:\/\/([a-zA-Z0-9_-]+\.)*hubspot\.com(\/[^\s]*)?/g,
    ];

    const applyCustomAttributesOnHttpSpan = (span, request, result) => {
      const { attributes } = span;

      span.updateName(`${attributes['http.method'] || 'Unknown'} ${attributes['http.url'] || 'Unknown'}`);

      if (attributes.component === 'fetch' && result.status && (result.status > 299 || result.status === 0)) {
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: `HTTP Error ${result.status} ${result.message || result.statusText}`,
        });
      }
    };

    registerInstrumentations({
      tracerProvider: provider,
      instrumentations: [
        getWebAutoInstrumentations({
          // load custom configuration for xml-http-request instrumentation
          '@opentelemetry/instrumentation-xml-http-request': {
            propagateTraceHeaderCorsUrls,
            applyCustomAttributesOnSpan: applyCustomAttributesOnHttpSpan,
            ignoreUrls,
          },
          // load custom configuration for fetch instrumentation
          '@opentelemetry/instrumentation-fetch': {
            propagateTraceHeaderCorsUrls,
            applyCustomAttributesOnSpan: applyCustomAttributesOnHttpSpan,
            ignoreUrls,
          },
          '@opentelemetry/instrumentation-document-load': {
            ignoreNetworkEvents: true,
          },
        }),
      ],
    });
  }
}

// eslint-disable-next-line react/prop-types, react/jsx-no-useless-fragment
const TraceProvider = ({ children }) => <>{children}</>;

export default TraceProvider;
