import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import TextMaxLine from 'components/TextMaxLine';

export const StyledSectionButton = styled(Button)(() => ({
  display: 'flex',
  textAlign: 'left',
  flexDirection: 'row',
  textTransform: 'none',
  justifyContent: 'space-between',
  height: '36px',
  '@media screen and (max-width: 1024px)': {
    height: '30px',
  },
}));

export const TopMenuHeader = styled(TextMaxLine)(() => ({
  paddingBottom: '0.5rem',
  fontWeight: 'bold',
}));

export const ButtonText = ({ children, ...restProps }) => (
  <TextMaxLine line={1} {...restProps}>
    {children}
  </TextMaxLine>
);

const PortalTopMenu = styled(Paper)(() => ({
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  marginLeft: 'auto',
  height: '430px',
  overflow: 'hidden',
  marginRight: 'auto',
  position: 'absolute',
  padding: '1rem 55px 1rem 55px',
  zIndex: 1,
  '@media screen and (min-device-width: 768px) and (max-device-width: 1024px)': {
    padding: '1rem 16px 1rem 16px',
    height: '372px',
  },
}));

PortalTopMenu.Header = TopMenuHeader;
PortalTopMenu.Button = StyledSectionButton;
PortalTopMenu.ButtonText = ButtonText;

export default PortalTopMenu;

ButtonText.propTypes = {
  children: PropTypes.node.isRequired,
};
