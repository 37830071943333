import FEATURE_FLAGS from 'constants/feature-flags';
import useConfigs from './useConfigs';

const useFeatureFlags = () => {
  const { configs } = useConfigs();

  const IS_WORDPRESS_CONNECTED = configs.find((x) => x.name === FEATURE_FLAGS.IS_WORDPRESS_CONNECTED)?.value;
  const IS_COUPON_ENABLED = configs.find((x) => x.name === FEATURE_FLAGS.IS_COUPON_ENABLED)?.value;
  const SEARCH_RESULTS_ALGOLIA = configs.find((x) => x.name === FEATURE_FLAGS.SEARCH_RESULTS_ALGOLIA)?.value;
  const FACETED_FILTERS_ENABLED = configs.find((x) => x.name === FEATURE_FLAGS.FACETED_FILTERS_ENABLED)?.value;
  const SHOW_PROFIT_MARGIN = configs.find((x) => x.name === FEATURE_FLAGS.SHOW_PROFIT_MARGIN)?.value;
  const CATEGORY_ALGOLIA = configs.find((x) => x.name === FEATURE_FLAGS.CATEGORY_ALGOLIA)?.value;
  const ALGOLIA_BDP = configs.find((x) => x.name === FEATURE_FLAGS.ALGOLIA_BDP)?.value;

  return {
    IS_WORDPRESS_CONNECTED: IS_WORDPRESS_CONNECTED ?? false,
    IS_COUPON_ENABLED: IS_COUPON_ENABLED ?? false,
    SEARCH_RESULTS_ALGOLIA: SEARCH_RESULTS_ALGOLIA ?? true,
    FACETED_FILTERS_ENABLED: FACETED_FILTERS_ENABLED ?? true,
    SHOW_PROFIT_MARGIN: SHOW_PROFIT_MARGIN ?? true,
    CATEGORY_ALGOLIA: CATEGORY_ALGOLIA ?? true,
    ALGOLIA_BDP: ALGOLIA_BDP ?? true,
  };
};

export default useFeatureFlags;
