/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/** Hooks */
import { useCallback, useRef } from 'react';
import useAuth from 'hooks/useAuth';
import useTopMenu from 'hooks/useTopMenu';

/** Components */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';
import TopMenuEdit from 'components/Header/TopMenu/TopMenuEdit';
import TopMenuSection from 'components/Header/TopMenu/TopMenuSection';
import TopMenuSandboxSection from 'components/Header/TopMenu/TopMenuSandboxSection';

const TopMenu = () => {
  const { isAuthenticated } = useAuth();
  const { target, topMenu, isLoading, setSelectedMenuSection, setIsItemHovered } = useTopMenu();
  const timerRef = useRef(null);

  const handleMouseEnter = useCallback(
    (id, label) => {
      setSelectedMenuSection((prev) => (prev.id === id && prev.label === label ? prev : { id, label }));

      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }

      timerRef.current = setTimeout(() => {
        setIsItemHovered((prev) => prev || true);
      }, 500);
    },
    [setSelectedMenuSection, setIsItemHovered]
  );

  const handleMouseLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setIsItemHovered(false);
    setSelectedMenuSection({ id: null, label: null });
  };

  return (
    <Container maxWidth="xl">
      {!isLoading ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          data-testid="top-menu"
          onMouseLeave={handleMouseLeave}
        >
          {topMenu?.length &&
            topMenu.map((item) => {
              const shouldNotRender = !item.availableForAnonymousUser && !isAuthenticated;
              if (shouldNotRender) return undefined;
              return item.__component === 'ui.edit' ? (
                <TopMenuEdit
                  id={item.id}
                  link={item.link}
                  isHighlighted={item.isHighlighted}
                  key={`ui-edit-${item.id}`}
                  data-testid="top-menu-edit"
                  onMouseEnter={() => handleMouseEnter(item.id, item.link.label)}
                />
              ) : item.__component === 'ui.menu-section' ? (
                <TopMenuSection
                  id={item.id}
                  label={item.label}
                  url={item.link?.url}
                  banners={item.banners}
                  categories={item.categories}
                  isHighlighted={item.isHighlighted}
                  key={`ui-section-${item.id}`}
                  wholesalers={item.wholesalers}
                  data-testid="top-menu-section"
                  onCategoriesButtonClick={handleMouseLeave}
                  onMouseEnter={() => handleMouseEnter(item.id, item.label)}
                />
              ) : (
                <TopMenuSandboxSection
                  key={`ui-section-${item.id}`}
                  id={item.id}
                  label={item.label}
                  isHighlighted={item.isHighlighted}
                  url={item.url || '#'}
                  columnsOrder={item.ColumnsOrder}
                  bannersColumn={item.BannersColumn}
                  productsColumn={item.ProductsColumn}
                  categoriesColumn={item.CategoriesColumn}
                  wholesalersColumn={item.WholesalersColumn}
                  onCategoriesButtonClick={handleMouseLeave}
                  onMouseEnter={() => handleMouseEnter(item.id, item.label)}
                />
              );
            })}
        </Stack>
      ) : (
        <Stack direction="column" sx={{ mb: 0.75 }}>
          <Skeleton variant="text" />
        </Stack>
      )}
      <Box sx={{ position: 'relative' }} ref={target} />
    </Container>
  );
};

export default TopMenu;
