import Routes from 'constants/routes';
import Router from 'next/router';
import apiFactory from 'services/api/axios';
import { logger } from 'services/api/logger';
import token from 'services/api/token';
import { GUEST_TOKEN } from 'constants/index';

const existingAuthToken = process.browser && token.get();

// the bff-api requires an Authorization header to always exist so it can perform authorization checks
// e.g. check if the request is from a guest or an authenticated user
const local = apiFactory({
  headers: {
    Authorization: existingAuthToken || GUEST_TOKEN,
  },
});

local.interceptors.request.use(
  (config) => {
    /** For the future authentication routine * */
    const authToken = process.browser && token.get();
    const currConfig = { ...config };

    // handle authentication header
    currConfig.headers.Authorization = authToken || GUEST_TOKEN;

    logger.success({ instance: 'localApi', type: 'request', path: currConfig.url }, currConfig);

    return currConfig;
  },
  (err) => {
    logger.error({ instance: 'localApi', type: 'request' }, err);
    const error = new Error(err.response.statusText);
    error.statusCode = err.response.status;
    error.data = err.response.data;
    return Promise.reject(error);
  }
);

local.interceptors.response.use(
  (response) => {
    logger.success({ instance: 'localApi', type: 'response', path: response.config.url }, response);
    return response;
  },
  async (err) => {
    logger.error({ instance: 'localApi', type: 'response' }, err);
    if (err.response?.status === 401 || err.response?.status === 403) {
      await Router.push(Routes.auth.logout);
    }
    const error = new Error(err.response?.data.errorMsg);
    error.data = err.response?.data;
    error.statusCode = err.response?.status;
    return Promise.reject(error);
  }
);

export default local;
