export const getUnmetMOVBrands = (otherItemsArr) => {
  return otherItemsArr.map((oI) => oI.name).join(', ');
};

export const getTotalQuantity = (itemsArr) => {
  return itemsArr.reduce((acc, curr) => {
    return acc + curr.products.reduce((av, cv) => av + cv.quantity, 0);
  }, 0);
};

export const amountToDisplay = (currencyInPence) => (Number(currencyInPence) / 100).toFixed(2);
export const percentageToDisplay = (percentageInPence) => `${Number(percentageInPence) / 100}%`;

export const calculateDiscountMessage = (currencySymbol, differenceAmount, discountPercentage) => {
  return `Add ${currencySymbol}${amountToDisplay(differenceAmount)} more to receive an extra ${percentageToDisplay(
    discountPercentage
  )} discount`;
};

export const calculateProductDiscountMessage = (remainingQuantity, discountPercentage) => {
  return `Add ${remainingQuantity} more to receive an extra ${percentageToDisplay(discountPercentage)} discount`;
};

export const calculateDiscountAppliedMessage = (discountPercentage) => {
  return `${percentageToDisplay(discountPercentage)} discount applied`;
};

export const calculateTotalWithDiscount = (orderSummary) => {
  const { direct, bulk, store } = orderSummary.discounts;
  const hasDiscount = direct > 0 || bulk > 0 || store > 0;

  return hasDiscount ? orderSummary.total : orderSummary.subtotal;
};

export const removeNullProperties = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null));

export const sortWholesalersByMOVDifference = (items, currency) => {
  const clonedItems = [...items];

  return clonedItems.sort((previousWholesaler, nextWholesaler) => {
    // Helper function to calculate the difference
    const calculateDifference = (wholesaler) => {
      const minOrderList = wholesaler.wholesalerOrderSummary.isFirstOrder
        ? wholesaler.minOrderValue
        : wholesaler.minReOrderValue;

      // Find the relevant currency object
      let minOrderObj = minOrderList.find((each) => each.storeMinOrderCurrency === currency);

      // Fallback to `minOrderValue` if the object is not found or invalid
      if (!minOrderObj || !minOrderObj.storeMinOrderAmount) {
        minOrderObj = wholesaler.minOrderValue.find((each) => each.storeMinOrderCurrency === currency);
      }

      // Calculate the difference
      if (wholesaler.wholesalerOrderSummary.total < minOrderObj.storeMinOrderAmount) {
        return minOrderObj.storeMinOrderAmount - wholesaler.wholesalerOrderSummary.total;
      }

      // If no difference, return a large number to deprioritize
      return Infinity;
    };

    // Calculate differences for `previousWholesaler` and `nextWholesaler`
    const previousWholesalerDifference = calculateDifference(previousWholesaler);
    const nextWholesalerDifference = calculateDifference(nextWholesaler);

    // Sort by smallest difference
    return previousWholesalerDifference - nextWholesalerDifference;
  });
};

/**
 * Check if the cart is empty (according to either considering the other items or not)
 * @param {*} cart Cart/Checkout object to be checked
 * @param {*} considerOtherItems Whether to consider other items in the cart for the evaluation
 * @returns {boolean} Whether the cart is empty or not
 */
export const isCheckoutEmpty = (cart, considerOtherItems = false) =>
  !cart || cart.checkoutReadyItems?.length <= 0 || (considerOtherItems && cart.otherItems?.length <= 0);
