import { useEffect, useState } from 'react';

// constants
import ROUTES from 'constants/routes';
import WP_ROUTES from 'constants/wordpress-routes';

import useFeatureFlags from './useFeatureFlags';

const useConfiguredRoutes = () => {
  const { IS_WORDPRESS_CONNECTED } = useFeatureFlags();

  // routes
  const [cartRoute, setCartRoute] = useState(WP_ROUTES.cart);
  const [wishlistRoute, setWishlistRoute] = useState(WP_ROUTES.wishlist);
  const [brandFollowedRoute, setBrandFollowedRoute] = useState(WP_ROUTES.brandFollowed);
  const [sellOnCreoateRoute, setSellOnCreoateRoute] = useState(WP_ROUTES.registerWholesaler);

  useEffect(() => {
    // ONLY want to check against false here, no undefined or null
    if (IS_WORDPRESS_CONNECTED === false) {
      setCartRoute(ROUTES.page.cart);
      setWishlistRoute(ROUTES.page.wishlist);
      setBrandFollowedRoute(ROUTES.page.brandFollowed);
    } else {
      setCartRoute(WP_ROUTES.cart);
      setWishlistRoute(WP_ROUTES.wishlist);
      setBrandFollowedRoute(WP_ROUTES.brandFollowed);
    }

    // always redirect wholesalers to sell on creoate WP page
    setSellOnCreoateRoute(WP_ROUTES.registerWholesaler);
  }, [IS_WORDPRESS_CONNECTED]);

  return {
    cartRoute,
    wishlistRoute,
    brandFollowedRoute,
    sellOnCreoateRoute,
  };
};

export default useConfiguredRoutes;
