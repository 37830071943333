import { createContext } from 'react';

import { noOp } from 'utils/functions';

const CartContext = createContext({
  cart: [],
  setCart: noOp,
  getCart: noOp,
  getCheckoutCart: noOp,
  addToCart: noOp,
  removeFromCart: noOp,
  increaseInCart: noOp,
  decreaseInCart: noOp,
  removeBrandFromCart: noOp,
  isCartLoading: false,
  setIsCartLoading: noOp,
});

export default CartContext;
