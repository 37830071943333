import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import apiFactory from 'services/api/axios';
import token from 'services/api/token';

import CouponContext from 'context/CouponContext';

const CouponProvider = ({ children }) => {
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const authToken = token.get();

      const fetcher = apiFactory({
        baseURL: window.location.origin,
      });

      const fetchCoupons = async () => {
        try {
          const { data } = await fetcher.get('/api/discount', {
            headers: {
              jwt: authToken,
            },
          });
          setCoupons(data.vouchers);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
        }
      };
      if (authToken === 'null' || authToken === 'undefined' || !authToken || authToken === undefined) {
        setCoupons([]);
      } else {
        fetchCoupons();
      }
    })();
  }, []);

  // Coupons context value
  const couponContextValue = useMemo(
    () => ({
      coupons,
      isLoading,
    }),
    [coupons, isLoading]
  );

  return <CouponContext.Provider value={couponContextValue}>{children}</CouponContext.Provider>;
};

CouponProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CouponProvider;
