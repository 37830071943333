import PropTypes from 'prop-types';

import List from '@mui/material/List';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import useResponsive from 'hooks/useResponsive';

const SuggestionsTitle = ({ title, loading }) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');

  const capitalizeFirstLetter = (text) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <List
      sx={{
        pb: 0,
        [theme.breakpoints.down('sm')]: {
          py: 0,
        },
      }}
    >
      <ListItem
        sx={{
          py: 0,
          [theme.breakpoints.down('sm')]: {
            px: 0.5,
          },
        }}
      >
        {loading ? (
          <Skeleton variant="text" animation="wave" width="15ch" height={isMobile ? '1.5rem' : '2rem'} />
        ) : (
          <ListItemText
            primary={
              <Typography
                color="textPrimary"
                variant="h6"
                sx={{
                  m: 0,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '1rem',
                  },
                }}
              >
                {capitalizeFirstLetter(title)}
              </Typography>
            }
          />
        )}
      </ListItem>
    </List>
  );
};

export default SuggestionsTitle;

SuggestionsTitle.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
