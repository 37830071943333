import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------
// Styled Components
// ----------------------------------------------------------------------

// 'BRAND_DISCOUNT', 'PRODUCT_DISCOUNT, NEW', 'BUNDLE', 'BEST_SELLER', 'STAR_SUPPLIER', 'DELIVERY_SUCCESS_RATE', 'LEAD_TIME ', 'LAST_LOGIN',
const colors = {
  HIGH_MARGIN: {
    color: '#FFF',
    backgroundColor: '#E44DA8',
  },
  BRAND_DISCOUNT: {
    color: '#FFF',
    backgroundColor: '#EA4335',
  },
  PRODUCT_DISCOUNT: {
    color: '#FFF',
    backgroundColor: '#EA4335',
  },
  NEW: {
    color: '#FFF',
    backgroundColor: '#4285F4',
  },
  BUNDLE: {
    color: '#FFF',
    backgroundColor: '#338377',
  },
  BEST_SELLER: {
    color: '#FFF',
    backgroundColor: '#34A853',
  },
  STAR_SUPPLIER: {
    color: '#FFF',
    backgroundColor: '#FBBC04',
  },
  DELIVERY_SUCCESS_RATE: {
    color: '#FFF',
    backgroundColor: '#34A853',
  },
  LEAD_TIME: {
    color: '#FFF',
    backgroundColor: '#4285F4',
  },
  LAST_LOGIN: {
    color: '#FFF',
    backgroundColor: '#000',
  },
  DISCOUNT_BUTTON: {
    color: '#FFF',
    backgroundColor: '#FF6464',
  },
};

const RootStyle = styled('span')(({ theme, ownerState }) => {
  const isLight = theme.palette.mode === 'light';
  const { type, variant } = ownerState;

  const styleFilled = () => ({
    color: colors[type].color,
    backgroundColor: colors[type].backgroundColor,
  });

  const styleOutlined = () => ({
    color: colors[type].color,
    backgroundColor: 'transparent',
    border: `1px solid ${colors[type].backgroundColor}`,
  });

  const styleGhost = () => ({
    color: colors[type].color[isLight ? 'dark' : 'light'],
    backgroundColor: alpha(colors[type].backgroundColor, 0.16),
  });

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 8,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,

    ...(type !== 'default'
      ? {
          ...(variant === 'filled' && { ...styleFilled() }),
          ...(variant === 'outlined' && { ...styleOutlined() }),
          ...(variant === 'ghost' && { ...styleGhost() }),
        }
      : {
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500_32]}`,
          }),
          ...(variant === 'ghost' && {
            color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
            backgroundColor: theme.palette.grey[500_16],
          }),
        }),
  };
});

// ----------------------------------------------------------------------

const Label = ({ type, variant = 'filled', children = null, ...other }) => {
  return (
    <RootStyle ownerState={{ type, variant }} {...other}>
      {children}
    </RootStyle>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'HIGH_MARGIN',
    'BRAND_DISCOUNT',
    'PRODUCT_DISCOUNT',
    'NEW',
    'BUNDLE',
    'BEST_SELLER',
    'STAR_SUPPLIER',
    'DELIVERY_SUCCESS_RATE',
    'LEAD_TIME',
    'LAST_LOGIN',
    'DISCOUNT_BUTTON',
  ]).isRequired,
  variant: PropTypes.oneOf(['filled', 'outlined', 'ghost']),
};

export default Label;
