import { NON_LOGGED_IN_USER_ID } from 'constants';
import { GTM_ENVS } from 'constants/environments';
import { ALGOLIA_INDEX_NAME, NEXT_PUBLIC_ENV } from 'constants/runtimeConfig';
import { ALGOLIA_PURCHASE_EVENT_OBJECT_ID_LIMIT } from 'constants/constants';
import token from 'services/api/token';
import decode from 'jwt-decode';
import { serverLogger } from './serverLogs.utils';
import { amountToDisplay } from './cart';

const moment = require('moment');

const isStagingOrProduction = GTM_ENVS.includes(NEXT_PUBLIC_ENV);

const isNotStagingOrProduction = !isStagingOrProduction;

export const pushToDataLayer = (eventData) => {
  const customData = { ...eventData };
  const extraData = {};
  if (window && window.dataLayer) {
    const user = window.localStorage?.user ? JSON.parse(window.localStorage?.user) : null;
    if (user) {
      if (user?.retailerId) extraData.retailerId = user?.retailerId;
      if (user?.retailerWpId) extraData.retailerWpId = user?.retailerWpId;
      if (user?.id) extraData.userId = user?.id;
    }
    if (Object.keys(extraData).length > 0) {
      customData.extraData = extraData;
    }
    window.dataLayer.push(customData);
  }
};

// GTM for every page view
export const GTMGoogleConsent = (consentGiven) => {
  const consentEvent = consentGiven
    ? {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
        wait_for_update: 500,
      }
    : {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
        wait_for_update: 500,
      };
  pushToDataLayer({
    event: 'google_consent',
    consentEvent,
  });
};

// GTM for every page view
export const GTMPageView = (url, userDetails) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const { id: userId, userType } = userDetails || {};
  const isRegistered = !!userId;
  const pageEvent = {
    event: 'pageview',
    page: url,
    isRegistered,
    userType: userType || 'Guest',
  };
  if (userId) {
    pageEvent.user_id = userId;
  }

  pushToDataLayer(pageEvent);

  return pageEvent;
};

// GTM for every category visit
export const GTMViewCategory = (description, categoryName, productIds, userId = null) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const viewCategoryEvent = {
    event: 'viewCategoryEvent',
    data: {
      content_name: description,
      content_category: categoryName,
      content_ids: productIds,
      content_type: 'product',
    },
  };
  if (userId) {
    viewCategoryEvent.user_id = userId;
  }

  pushToDataLayer(viewCategoryEvent);

  return viewCategoryEvent;
};

// GTM for every Brand Page visit
export const GTMVisitedBrandPage = (
  brandName,
  brandId,
  brandCountry,
  brandShipIn,
  brandUrl,
  brandLogo,
  brandMinimum,
  currency,
  brandCategories,
  brandDescription,
  brandFlairs,
  userId = null
) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const visitedBrandPageEvent = {
    event: 'VisitedBrandPage',
    data: {
      store_name: brandName,
      store_id: brandId,
      store_country: brandCountry,
      store_ship_in: brandShipIn,
      store_url: brandUrl,
      store_logo: brandLogo,
      store_minimum: brandMinimum,
      currency,
      store_categories: brandCategories,
      store_description: brandDescription,
      store_flairs: brandFlairs,
    },
  };
  if (userId) {
    visitedBrandPageEvent.user_id = userId;
  }

  pushToDataLayer(visitedBrandPageEvent);

  return visitedBrandPageEvent;
};

// GTM for every Brand follow
export const GTMFollowBrand = (brandName, brandId, brandCountry, brandShipIn, userId = null) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const BrandFollowEvent = {
    event: 'FollowedBrand',
    data: {
      store_name: brandName,
      store_id: brandId,
      store_country: brandCountry,
      store_ship_in: brandShipIn,
    },
  };
  if (userId) {
    BrandFollowEvent.user_id = userId;
  }

  pushToDataLayer(BrandFollowEvent);

  return BrandFollowEvent;
};

// GTM for every Product Page visit or click
export const GTMProductEvents = (
  productName,
  productId,
  productWsp,
  productBrand,
  productImage,
  productUrl,
  brandUrl,
  currency,
  type = 'click',
  userId = null
) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const productPageEvent = {
    event: type === 'click' ? 'clickedProduct' : 'viewedProduct',
    data: {
      product_id: productId,
      product_wsp: productWsp,
      product_name: productName,
      product_brand: productBrand,
      product_image: productImage,
      product_url: productUrl,
      brand_url: brandUrl,
      currency,
    },
  };

  if (userId) {
    productPageEvent.user_id = userId;
  }

  pushToDataLayer(productPageEvent);

  return productPageEvent;
};

// GTM for every Product wishlist
export const GTMProductWishlistEvents = (
  productName,
  productId,
  productWsp,
  productBrand,
  productUrl,
  productImageUrl,
  productCategories,
  userId = null
) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const productWishlistEvent = {
    event: 'AddToWishlist',
    data: {
      id: productId,
      price: productWsp,
      name: productName,
      brand: productBrand,
      url: productUrl,
      image: productImageUrl,
    },
  };
  if (userId) {
    productWishlistEvent.user_id = userId;
  }
  if (productCategories?.length) {
    productWishlistEvent.data.categories = productCategories;
  }

  pushToDataLayer(productWishlistEvent);

  return productWishlistEvent;
};

// GTM for every Product add to cart
export const GTMProductAddToCartEvents = (
  productName,
  productId,
  productWsp,
  productBrand,
  productlUrl,
  productImageUrl,
  currencyName = null,
  userId = null
) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const productAddToCartEvent = {
    event: 'AddToCart',
    value: productWsp,
    ecommerce: {
      item_id: productId,
      price: productWsp,
      item_name: productName,
      item_brand: productBrand,
      item_url: productlUrl,
      item_image: productImageUrl,
    },
  };
  if (userId) {
    productAddToCartEvent.user_id = userId;
  }
  if (currencyName) {
    productAddToCartEvent.currency = currencyName;
  }

  pushToDataLayer(productAddToCartEvent);

  return productAddToCartEvent;
};

// GTM for User Login
export const GTMUserLoggedinEvent = (userId = null) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const userLoggedinEvent = {
    event: 'UserLoggedin',
  };
  if (userId) {
    userLoggedinEvent.user_id = userId;
  }

  pushToDataLayer(userLoggedinEvent);

  return userLoggedinEvent;
};

// GTM for post signup
export const GTMPostSignupEvent = ({
  userId = null,
  storeType,
  userCountry,
  email,
  companyHouseNumber,
  businessType,
}) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  if (storeType && userCountry && userId && email) {
    const postSignupEventData = {
      storeType,
      userCountry,
      email,
    };
    if (companyHouseNumber) {
      postSignupEventData.companyHouseNumber = companyHouseNumber;
    }
    if (businessType) {
      postSignupEventData.businessType = businessType;
    }
    const postSignupEvent = {
      event: 'creoate-user-registered',
      data: postSignupEventData,
    };

    if (userId) {
      postSignupEvent.user_id = userId;
    }

    pushToDataLayer(postSignupEvent);

    return postSignupEvent;
  }

  return null;
};

// GTM for open new kyc dialog
export const GTMOpenDialogEvent = ({ id, label }) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  if (id && label) {
    const postSignupEventData = {
      dialog_id: id,
      label,
    };
    const postSignupEvent = {
      event: 'creoate-user-open-kyc-dialog',
      data: postSignupEventData,
    };

    pushToDataLayer(postSignupEvent);

    return postSignupEvent;
  }

  return null;
};

export const GTMUserChangeEvent = (userDetails = {}) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const { lastOrderDate, signUpDate } = userDetails;

  const hasPurchase = !!lastOrderDate;

  const currentTimestamp = moment();
  const signupTimestamp = signUpDate ? moment(signUpDate) : moment(0);
  const daysSinceSignup = currentTimestamp.diff(signupTimestamp, 'days');

  const lastPurchaseTimestamp = lastOrderDate ? moment(lastOrderDate) : moment(0);
  const daysSinceLastPurchase = currentTimestamp.diff(lastPurchaseTimestamp, 'days');

  const userChangeEvent = {
    event: 'UserDetailsChange',
    hasPurchase,
    daysSinceSignup,
    daysSinceLastPurchase,
    ...userDetails,
  };

  pushToDataLayer(userChangeEvent);

  return userChangeEvent;
};

// checkout page gtm events

// GTM for TermsAndConditions checkbox clicked
export const GTMTermsAndConditionsCheckedEvent = (userId) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const TermsAndConditionsCheckedEvent = {
    event: 'T-and-C_ticked_checkout',
    user_id: userId,
  };

  pushToDataLayer(TermsAndConditionsCheckedEvent);

  return TermsAndConditionsCheckedEvent;
};

// GTM for VoucherApplied event
export const GTMVoucherAppliedEvent = (userId, voucherCode) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const VoucherAppliedEvent = {
    event: 'apply_coupon_checkout',
    user_id: userId,
    voucher: voucherCode,
  };

  pushToDataLayer(VoucherAppliedEvent);

  return VoucherAppliedEvent;
};

// GTM for CheckoutPageViewed event
export const GTMCheckoutPageViewedEvent = (userId) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const CheckoutPageViewedEvent = {
    event: 'view_checkout_page',
    user_id: userId,
  };

  pushToDataLayer(CheckoutPageViewedEvent);

  return CheckoutPageViewedEvent;
};

// GTM for CheckoutPageViewed event
export const GTMCheckoutPageCreditBalance = (userId, creditBalance) => {
  const creditBalanceAmount = amountToDisplay(creditBalance);
  if (isNotStagingOrProduction) {
    return null;
  }

  const CheckoutCreditBalanceEvent = {
    event: 'checkout_credit_balance',
    credit_balance: creditBalanceAmount,
  };
  if (userId) {
    CheckoutCreditBalanceEvent.user_id = userId;
  }

  pushToDataLayer(CheckoutCreditBalanceEvent);

  return CheckoutCreditBalanceEvent;
};

// GTM for UpdateBillingAddress event
export const GTMUpdateBillingAddressEvent = (userId) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const BillingUpdatedEvent = {
    event: 'update_billing_address',
    user_id: userId,
  };

  pushToDataLayer(BillingUpdatedEvent);

  return BillingUpdatedEvent;
};

// GTM for UpdateShippingAddress event
export const GTMUpdateShippingAddressEvent = (userId) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const ShippingUpdatedEvent = {
    event: 'update_shipping_address',
    user_id: userId,
  };

  pushToDataLayer(ShippingUpdatedEvent);

  return ShippingUpdatedEvent;
};

// GTM for UpdateAddressError event
export const GTMUpdateAddressErrorEvent = (userId) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const UpdateAddressErrorEvent = {
    event: 'update_address_error',
    user_id: userId,
  };

  pushToDataLayer(UpdateAddressErrorEvent);

  return UpdateAddressErrorEvent;
};

// GTM for CompleteOrder event
export const GTMCompleteOrderEvent = (userId) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const CompleteOrderEvent = {
    event: 'complete_order',
    user_id: userId,
  };

  pushToDataLayer(CompleteOrderEvent);

  return CompleteOrderEvent;
};

// GTM for UpdateShipmentDate clicked
export const GTMUpdateShipmentDateEvent = (userId, date) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const UpdateShipmentDateEvent = {
    event: 'changed_shipment_date',
    user_id: userId,
  };

  if (date) UpdateShipmentDateEvent.date = date;

  pushToDataLayer(UpdateShipmentDateEvent);

  return UpdateShipmentDateEvent;
};

// GTM for NewStripeCardAdded clicked
export const GTMNewStripeCardAddedEvent = (userId) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const NewStripeCardAddedEvent = {
    event: 'new_stripe_card_added',
    user_id: userId,
  };

  pushToDataLayer(NewStripeCardAddedEvent);

  return NewStripeCardAddedEvent;
};

// GTM for NewStripeCardAdded Error event
export const GTMNewStripeCardAddedErrorEvent = (userId) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const NewStripeCardAddedErrorEvent = {
    event: 'new_stripe_card_added_error',
    user_id: userId,
  };

  pushToDataLayer(NewStripeCardAddedErrorEvent);

  return NewStripeCardAddedErrorEvent;
};

// GTM for Thank You Page Viewed event
export const GTMThankYouPageViewedEvent = (userId, gtmData) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const thankYouPageViewedEvent = {
    event: 'purchase',
    ecommerce: gtmData,
  };
  if (userId) {
    thankYouPageViewedEvent.user_id = userId;
  }

  pushToDataLayer(thankYouPageViewedEvent);

  return thankYouPageViewedEvent;
};

// GTM for custom events
export const GTMCustomEventWithElementId = (eventName, elementId = '') => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const CustomEventWithElementId = {
    event: eventName,
  };

  if (elementId !== '') {
    CustomEventWithElementId.elementId = elementId;
  }

  pushToDataLayer(CustomEventWithElementId);

  return CustomEventWithElementId;
};

// GTM for SearchTermUsed
export const GTMSearchedTermUsedEvent = (searchedTerm, userId = '', eventNameSuffix = '') => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const searchedTermUsedEvent = {
    event: `SearchTermUsed${eventNameSuffix}`,
    data: {
      searchedTerm,
    },
  };

  if (userId) {
    searchedTermUsedEvent.user_id = userId;
  }

  pushToDataLayer(searchedTermUsedEvent);

  return searchedTermUsedEvent;
};

// GTM for for changing filters
export const GTMFiltersUsedEvent = (filtersUsed, listingPage, userId = '') => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const filtersUsedEvent = {
    event: 'FiltersUsed',
    data: {
      filtersUsed,
      listingPage,
    },
  };

  if (userId) {
    filtersUsedEvent.user_id = userId;
  }

  pushToDataLayer(filtersUsedEvent);

  return filtersUsedEvent;
};

/**
 *
 * @param {Object} data
 * @param {string} data.brandName brand name
 * @param {string} data.brandSlug brand slug
 * @param {string} data.searchTerm actual search term
 * @param {string} [userId] user id of currently logged in user
 * @returns
 */
export const GTMRedirectedToBrandPageFromSearchBar = (data, userId) => {
  if (isNotStagingOrProduction) {
    return null;
  }

  const { eventNameSuffix = '' } = data;
  const userRedirectedToBrandPageFromSearchBarEvent = {
    event: `RedirectedToBrandPageFromSearchBar${eventNameSuffix}`,
    ...data,
  };
  if (userId) {
    userRedirectedToBrandPageFromSearchBarEvent.user_id = userId;
  }

  pushToDataLayer(userRedirectedToBrandPageFromSearchBarEvent);

  return userRedirectedToBrandPageFromSearchBarEvent;
};

/**
 * Pushes an Algolia event to the dataLayer if specific conditions are met.
 *
 * @param {string} event - The name of the event to push to the data layer.
 * @param {Object} data - The data object containing event details.
 */
const pushAlgoliaEventToDataLayer = (event, data) => {
  const jwt = token.get() || null;
  const nonLoggedInUserId = JSON.parse(window.sessionStorage?.[NON_LOGGED_IN_USER_ID]) || '';

  // Decode JWT if available
  const decodedJwt = jwt ? decode(jwt) : {};
  const { id: userWpId = '', email = '', role: userRole = '' } = decodedJwt?.data || {};

  // Determine if the event should be pushed
  const isRetailer = userRole === 'Retailer';
  const emailDomain = email.split('@')[1]?.toLowerCase();
  const isCreoateDomain = emailDomain === 'creoate.com';
  const shouldPushEvents = isRetailer && !isCreoateDomain;

  // Exit if dataLayer is unavailable or if conditions aren't met for logged-in users
  if (!window?.dataLayer || (jwt && !shouldPushEvents)) return;

  const algoliaUserToken = jwt ? userWpId : nonLoggedInUserId;

  // Exit if algoliaUserToken is not found
  if (!algoliaUserToken) {
    serverLogger.error(
      'Algolia user token not found',
      {
        event,
        data: {
          ...data,
          algoliaUserToken,
          algoliaIndexName: ALGOLIA_INDEX_NAME,
        },
      },
      'algolia',
      'client'
    );
    return;
  }

  // Push event to dataLayer
  window.dataLayer.push({
    event,
    data: {
      ...data,
      algoliaUserToken,
      algoliaIndexName: ALGOLIA_INDEX_NAME,
    },
  });
};

/**
 * Pushes an Algolia click event to the data layer.
 *
 * @param {string} type - The type object being clicked. Can be either 'product' or 'brand'.
 * @param {Object} eventData - An object containing the event data.
 * @param {string} eventData.objectID - The ID of the object being clicked.
 * @param {string} [eventData.queryID] - The ID of the query related to the click (optional).
 * @param {number} eventData.position - The position of the item in the list (1-based index).
 * @param {boolean} isAfterSearch - Flag to determine if the event occurred after a search.
 */
export const pushAlgoliaClickEvent = (type = 'product', eventData = {}, isAfterSearch = false) => {
  const allowedTypes = ['product', 'brand'];

  if (!allowedTypes.includes(type.toLowerCase())) {
    return;
  }

  const eventName = isAfterSearch ? `Algolia ${type} click after search` : `Algolia ${type} click`;

  pushAlgoliaEventToDataLayer(eventName, eventData);
};

/**
 * Pushes an Algolia add to cart event to the data layer.
 *
 * @param {string} objectID - The ID of the object being added to the cart.
 * @param {string} queryID - The ID of the query related to the add to cart action.
 */
export const pushAlgoliaAddToCartEvent = (eventData = {}, isAfterSearch = false) => {
  const eventName = isAfterSearch
    ? 'Algolia product - add to cart click after search'
    : 'Algolia product - add to cart click';

  pushAlgoliaEventToDataLayer(eventName, eventData);
};

/**
 * Pushes Algolia purchased product events to the data layer in batches.
 *
 * If the number of purchased objects exceeds the allowed limit (`ALGOLIA_PURCHASE_EVENT_OBJECT_ID_LIMIT`),
 * the event data is split into multiple batches and sent separately.
 *
 * Each batch is processed only if the `objectIDs` and `objectData` lengths match.
 * If a mismatch is found, an error is logged, and the batch is skipped.
 *
 * The event name is determined based on whether any purchased product has a `queryId`:
 * - `"Algolia purchased product after search"` if at least one product was purchased after a search.
 * - `"Algolia purchased product"` otherwise.
 *
 * @param {Object} eventData - The data of the objects being purchased.
 * @param {string[]} eventData.objectIDs - The unique IDs of the purchased objects.
 * @param {Object[]} eventData.objectData - The details of each purchased object, including quantity and price.
 * @param {number} eventData.value - The total value of the purchase.
 * @param {string} eventData.currency - The currency of the purchase.
 */

export const pushAlgoliaPurchasedProductEvent = (eventData = {}) => {
  const { objectIDs = [], objectData = [], ...rest } = eventData;
  const batchSize = ALGOLIA_PURCHASE_EVENT_OBJECT_ID_LIMIT;

  if (!objectIDs.length || !objectData.length) {
    serverLogger.error(
      'Algolia Purchased Event - No objectIDs or objectData provided',
      { eventData },
      'algolia',
      'client'
    );
    return;
  }

  for (let i = 0; i < objectIDs.length; i += batchSize) {
    const batch = {
      objectIDs: objectIDs.slice(i, i + batchSize),
      objectData: objectData.slice(i, i + batchSize),
      ...rest,
    };

    // Log an error and skip processing this batch if the lengths don't match
    if (batch.objectIDs.length !== batch.objectData.length) {
      serverLogger.error(
        'Algolia Purchased Event - Mismatch between objectIDs and objectData',
        { batch },
        'algolia',
        'client'
      );
    } else {
      // Check if any objectData element has a queryId
      const isAfterSearch = batch.objectData.some((data) => data.queryId);
      const eventName = isAfterSearch ? 'Algolia purchased product after search' : 'Algolia purchased product';

      pushAlgoliaEventToDataLayer(eventName, batch);
    }
  }
};

/**
 * Pushes a viewed object event to the data layer for Algolia.
 *
 * @param {string} type - The type of object being viewed. Can be either 'product' or 'brand'.
 * @param {Object} eventData - An object containing the event data.
 * @param {string} eventData.objectID - The ID of the object being viewed.
 */
export const pushAlgoliaViewedObjectEvent = (type = 'product', eventData = {}) => {
  const allowedTypes = ['product', 'brand'];

  if (!allowedTypes.includes(type.toLowerCase())) {
    return;
  }

  pushAlgoliaEventToDataLayer(`Algolia viewed ${type}`, eventData);
};
