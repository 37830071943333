import { createContext } from 'react';
import { noOp } from 'utils/functions';

const BackdropContext = createContext({
  handleShowBackdrop: noOp,
  handleHideBackdrop: noOp,
  showBackdrop: false,
});

export default BackdropContext;
