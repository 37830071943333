import getConfig from 'next/config';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export const {
  GTM_ID,
  NODE_ENV,
  WORDPRESS_URL,
  POPUPSMART_ID,
  NEXT_PUBLIC_ENV,
  PUBLIC_VPS_API_KEY,
  NEXT_PUBLIC_BFF_URL,
  GOOGLE_ANALYTICS_API,
  NEXT_PUBLIC_COOKIE_DOMAIN,
  NEXT_PUBLIC_WHOLESALER_SITE_URL,
  CONTENT_API_KEY,
  CONTENT_API_URL,
  ALGOLIA_INDEX_NAME,
  NEXT_PUBLIC_OTEL_EXPORTER_OTLP_TRACES_ENDPOINT,
} = publicRuntimeConfig;

export const {
  RETAILER_API_KEY,
  RETAILER_API_URL,
  VPS_API_KEY,
  VPS_API_URL,
  BUSINESS_SERVICES_API_KEY,
  GWC_PAGES_GOOGLE_AUTH_SECRET,
  GWC_PAGES_GOOGLE_SHEET_ID,
  DISCOUNT_NOTIFY_ARN,
  REMOVE_PAYMENT_METHOD_ON_CREDIT_CHECK_FAIL,
} = serverRuntimeConfig;
