import PropTypes from 'prop-types';
import { useLocalStorage } from 'react-use';
import { useCallback, useMemo, useEffect } from 'react';

// utils
import { log } from 'utils/functions';
import { brandFlairsList, productFlairsList } from 'constants/flairs-filters';

/** API */
import bffAPI from 'services/api/bff';
import apiFactory from 'services/api/axios';

import TopBarContext from 'context/TopBarContext';
import token from 'services/api/token';

const topBarProviderDefaultPropData = { content: '' };

const TopBarProvider = ({ children, data = topBarProviderDefaultPropData }) => {
  const [categories, setCategories] = useLocalStorage('categories', []);
  const [collections, setCollections] = useLocalStorage('collections', []);
  const [flairs, setFlairs] = useLocalStorage('flairs', { productFlairs: [], brandFlairs: [] });

  const flairsEndpoint = '/api/flairs';
  const categoriesEndpoint = '/categories';
  const collectionsEndpoint = '/collections';

  const fetchFlairs = async () => {
    const fetcher = apiFactory({
      baseURL: window.location.origin,
    });

    const response = await fetcher.get(flairsEndpoint);
    return response.data;
  };

  const getFlairs = useCallback(async () => {
    try {
      const authToken = token.get();
      if (authToken === 'null' || authToken === 'undefined' || !authToken || authToken === undefined) {
        throw Error('Authorization token is missing');
      } else {
        let persistedFlairs = await fetchFlairs();
        if (typeof persistedFlairs === 'string') {
          persistedFlairs = JSON.parse(persistedFlairs);
        }
        setFlairs({
          brandFlairs: persistedFlairs?.brandFlairs || [],
          productFlairs: persistedFlairs?.productFlairs || [],
        });
      }
    } catch (error) {
      log.error('Error fetching flairs:', error);
      setFlairs({
        brandFlairs: brandFlairsList,
        productFlairs: productFlairsList,
      });
    }
  }, [setFlairs]);

  const getCollections = useCallback(async () => {
    try {
      const collectionsArray = [];
      const collectionsData = (await bffAPI.get(collectionsEndpoint)).data;

      collectionsData.map(({ name, slug }) => collectionsArray.push({ name, slug }));
      setCollections(collectionsArray);
    } catch (error) {
      log.error('Error fetching collections:', error);
    }
  }, [setCollections, collectionsEndpoint]);

  const getCategories = useCallback(async () => {
    try {
      const categoriesArray = [];
      const categoriesData = (await bffAPI.get(categoriesEndpoint)).data;

      categoriesData.forEach(({ name, slug }) => {
        categoriesArray.push({ name, slug });
      });
      setCategories(categoriesArray);
    } catch (error) {
      log.error('Error fetching brands categories:', error);
    }
  }, [setCategories, categoriesEndpoint]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allPromises = [];
        allPromises.push(getFlairs());
        if (!collections.length || !categories.length) {
          allPromises.push(getCollections());
          allPromises.push(getCategories());
        }
        if (allPromises.length > 0) {
          await Promise.all(allPromises);
        }
      } catch (error) {
        log.error('Error fetching data on TopBarProvider:', error);
      }
    };

    fetchData();

    return () => {};
  }, [getCategories, getCollections, getFlairs]);

  const brandFlairsIdentifier = flairs.brandFlairs.reduce((acc, flair) => {
    if (flair?.flairType === 'static') {
      acc.push(flair?.flairId);
    } else {
      acc.push(flair?.code);
    }
    return acc;
  }, []);

  // Memoized state
  const state = useMemo(
    () => ({
      ...data,
      brandFlairs: flairs?.brandFlairs || [],
      productFlairs: flairs?.productFlairs || [],
      collections,
      categories,
      getFlairs,
      getCollections,
      getCategories,
      brandFlairsIdentifier,
    }),
    [
      data,
      flairs?.brandFlairs,
      flairs?.productFlairs,
      collections,
      categories,
      getFlairs,
      getCollections,
      getCategories,
      brandFlairsIdentifier,
    ]
  );

  return <TopBarContext.Provider value={state}>{children}</TopBarContext.Provider>;
};

TopBarProvider.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({ content: PropTypes.string }),
};

export default TopBarProvider;
