/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import Router from 'next/router';
import React from 'react';

/** Utils / Consts */
import routes from 'constants/routes';
import { log } from 'utils/functions';

import axios from 'axios';
import GenericError from 'components/Error/GenericError';
import AuthContext from 'context/AuthContext';
import { dumpCookieData } from 'utils/cookies';
import { getBrowserInfo } from 'utils/navigator.utils';
import { convertErrorToEnumerable, serverLogger } from 'utils/serverLogs.utils';
import { dumpLocalStorageData, dumpSessionStorageData } from 'utils/storage.utils';

const isRetryableError = (error) => {
  const thrownError = error.error || error;

  if (axios.isAxiosError(thrownError)) {
    // Check whether the error is a timeout or a network error
    if (thrownError.code === 'ECONNABORTED') {
      return true;
    }

    // Otherwise, check whether the status code is retryable
    const statusCode = error.response ? error.response.status : null;
    const retryableStatusCodes = [502, 503, 504];
    return retryableStatusCodes.includes(statusCode);
  }

  // If the error is not an Axios error, we can't determine if it's retryable
  return false;
};

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    this.setState({ errorInfo });
    log.error({ error, errorInfo });
  }

  render() {
    const onGenericErrorClick = async () => {
      this.setState({ hasError: false, error: null });
      await Router.push(routes.page.root);
    };

    const onRetryClick = async () => {
      this.setState({ hasError: false, error: null });
      await Router.reload();
    };

    // Check if the error is thrown
    if (this.state.hasError) {
      const isRetryable = isRetryableError(this.state.error);

      const { user } = this.context;
      serverLogger.error(
        `Whoops page triggered by a ${isRetryable ? '' : 'non-'}retryable error`,
        {
          retailerId: user?.retailerId,
          error: convertErrorToEnumerable(this.state.error),
          errorInfo: this.state.errorInfo,
          localStorage: dumpLocalStorageData(),
          sessionStorage: dumpSessionStorageData(),
          cookie: dumpCookieData(),
          browser: getBrowserInfo(),
          lastNavigatedPage: Router.asPath,
          userDateTime: new Date().toISOString(),
          isRetryable,
        },
        'generic error',
        'client'
      );

      // You can render any custom fallback UI
      return (
        <GenericError onClickHandler={onGenericErrorClick} isRetryable={isRetryable} onRetryHandler={onRetryClick} />
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
