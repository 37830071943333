import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';

// Matirial UI
import Paper from '@mui/material/Paper';
import Box from '@mui/system/Box';

// Components
import AppBar from 'components/Header/AppBar';
import TopBar from 'components/Header/TopBar';
import TopMenu from 'components/Header/TopMenu';
import MobileTopMenu from 'components/Header/TopMenu/MobileTopMenu';

// Hooks & Utils
import useResponsive from 'hooks/useResponsive';
import { COOKIE_OPTIONS } from 'utils/cookies';
import dynamic from 'next/dynamic';

const DynamicBenefitsTab = dynamic(() => import('components/PriceMatch//BenefitsTab'), {
  loading: () => <Box style={{ minHeight: '38px', backgroundColor: '#DFE3E8' }} />,
  ssr: false,
});
const Header = ({ hideTopBar = false, hideAppBar = false, hideTopMenu = false }) => {
  const closeIconRef = useRef(null);
  const isTablet = useResponsive('down', 'md');
  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');
  const [showTopMenuDrawer, setShowTopMenuDrawer] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const [showTopBar, setShowTopBar] = useState('box');

  const router = useRouter();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['creoate_retailer_referrer', 'creoate_retailer_is_referred']);
  const { referred, referrer } = router.query;

  useEffect(() => {
    if (referred && referrer) {
      setCookie('creoate_retailer_is_referred', referred, COOKIE_OPTIONS);
      setCookie('creoate_retailer_referrer', referrer, COOKIE_OPTIONS);
    }
  }, [referred, referrer, setCookie]);

  const handleTopMenuDrawerToggle = () => {
    setShowTopMenuDrawer(!showTopMenuDrawer);
  };

  const mobileTopMenuCloseHandler = () => {
    setShowTopMenuDrawer(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const shouldSetScrolled = window.scrollY !== 0;
      setScrolled(shouldSetScrolled);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!hideTopBar && isDesktop) {
      setShowTopBar('desktop');
    } else if (!hideTopBar && isTablet) {
      setShowTopBar('tablet');
    }
  }, [hideTopBar, isDesktop, isTablet]);

  return (
    <>
      <Box ref={closeIconRef} />
      <Box sx={{ position: 'sticky', top: 0, zIndex: 5 }}>
        <Paper square elevation={0}>
          {showTopBar === 'box' && !isMobile && !isTablet && (
            <Box style={{ minHeight: '29px', backgroundColor: 'black' }} />
          )}
          {showTopBar === 'desktop' && <TopBar />}
          {!hideTopBar && !isScrolled && isTablet && <TopBar isScrolled={isScrolled} />}
          {!hideAppBar && <AppBar handleTopMenuDrawerToggle={handleTopMenuDrawerToggle} isScrolled={isScrolled} />}
          {isTablet
            ? !hideTopMenu && (
                <MobileTopMenu
                  showTopMenuDrawer={showTopMenuDrawer}
                  onClose={mobileTopMenuCloseHandler}
                  closeIconRef={closeIconRef}
                />
              )
            : !hideTopMenu && <TopMenu />}

          {showTopBar === 'box' && !isMobile && !isTablet && (
            <Box style={{ minHeight: '38px', backgroundColor: '#DFE3E8' }} />
          )}
          {!isScrolled && isTablet && <DynamicBenefitsTab />}
          {isDesktop && <DynamicBenefitsTab />}
        </Paper>
      </Box>
    </>
  );
};

Header.propTypes = {
  hideTopBar: PropTypes.bool,
  hideAppBar: PropTypes.bool,
  hideTopMenu: PropTypes.bool,
};

export default Header;
