/* eslint-disable no-param-reassign */
import { NEXT_PUBLIC_COOKIE_DOMAIN } from 'constants/runtimeConfig';
import {
  GUEST_TOKEN,
  JWT_COOKIE_NAME,
  USER_COOKIE_NAME,
  USER_CREDIT_COOKIE_NAME,
  FEATURE_FLAGS_COOKIE_NAME,
} from 'constants/index';
import FEATURE_FLAGS from 'constants/feature-flags';
import jwtDecode from 'jwt-decode';
import { logger } from 'services/api/logger';

const COOKIE_DOMAIN = NEXT_PUBLIC_COOKIE_DOMAIN;
export const COOKIE_OPTIONS = { path: '/', domain: COOKIE_DOMAIN, maxAge: 60 * 60 * 2 };

/**
 * Extracts only the cookie value with the correspondent name, if exists
 * @type function
 * @param name {string} - Cookie name
 * @returns {string | null}
 */
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2 ? parts.pop().split(';').shift() : null;
};

/**
 * Sets the provided name and value as a cookie
 *
 * @param cname {string}
 * @param cvalue {string}
 * @param exdays {number}
 */
export const setCookie = ({ cname, cvalue, exdays = 30 }) => {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getJwtFromCookie = ({ cookies }, isPublic, defaultJwt = GUEST_TOKEN) => {
  const currentJWT = cookies[JWT_COOKIE_NAME];

  if (currentJWT === defaultJwt || currentJWT === 'undefined' || currentJWT === 'null' || !currentJWT) {
    return defaultJwt;
  }

  if (isPublic && (currentJWT === defaultJwt || !currentJWT)) {
    return defaultJwt;
  }

  try {
    const decodedToken = jwtDecode(currentJWT);
    if (decodedToken.exp * 1000 < Date.now()) {
      return defaultJwt;
    }

    return currentJWT;
  } catch (error) {
    logger.error(
      { instance: 'cookies', type: 'utils' },
      { message: `[getJwtFromCookie] Invalid JWT: ${currentJWT}`, error }
    );
    return defaultJwt;
  }
};

export const getUserFromCookie = ({ cookies }) => {
  try {
    return JSON.parse(cookies[USER_COOKIE_NAME]);
  } catch (e) {
    return null;
  }
};

export const getUserCreditFromCookie = ({ cookies }) => {
  try {
    return JSON.parse(cookies[USER_CREDIT_COOKIE_NAME]);
  } catch (e) {
    return null;
  }
};

export const getFromCookieByKey = ({ cookies, key }) => {
  try {
    return JSON.parse(cookies[key]);
  } catch (e) {
    return null;
  }
};

export const getFiltersFromCookie = async ({ cookies }) => {
  try {
    return JSON.parse(cookies.filters);
  } catch (e) {
    return null;
  }
};
export const getTabFromCookie = async ({ cookies }) => {
  try {
    return cookies.cookieTab;
  } catch (e) {
    return null;
  }
};

/**
 *
 * @param {object} cookies cookies object
 * @param {string} key feature flag key
 * @returns {string} feature flag value
 */
export const getFeatureFlagFromCookie = ({ cookies }, key) => {
  try {
    const featureFlags = JSON.parse(cookies?.[FEATURE_FLAGS_COOKIE_NAME]);
    const flagValue = featureFlags?.find((x) => x.name === FEATURE_FLAGS?.[key])?.value;
    return flagValue;
  } catch (e) {
    return null;
  }
};

export const dumpCookieData = () =>
  document.cookie.split(';').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=').map((c) => c.trim());
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
