/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import useTopMenu from 'hooks/useTopMenu';

const SubTopMenuSSR = () => {
  const { topMenu } = useTopMenu();

  if (typeof window !== 'undefined') return null;

  if (!topMenu || !topMenu?.length) return null;

  return (
    <div>
      {topMenu.map((item) => {
        return item.__component === 'ui.edit' ? (
          <a id={`${item.__component}-${item.id}`} href={item.link.url}>
            {item.link.label}
          </a>
        ) : item.__component === 'ui.menu-section' ? (
          <>
            <a id={`${item.__component}-${item.id}`} href={item.link.url}>
              {item.label}
            </a>
            <h4>Products and their Subcatogries</h4>
            <ul>
              {item.categories.map((category) => {
                return (
                  <li key={`${item.__component}-${category.id}`}>
                    <a href={`https://www.creoate.com/product-category/${category.slug}`}>{category.name}</a>
                    <ul>
                      {category?.subcategories?.map((subCategory) => (
                        <li key={`${item.__component}-subcategory-${subCategory.id}`}>
                          <a href={`https://www.creoate.com/product-category/${subCategory.slug}`}>
                            {subCategory.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              })}
            </ul>
            <h4>Featured Brands</h4>
            <ul>
              {item.wholesalers.map((wholesaler) => (
                <li key={`${item.__component}-subcategory-${wholesaler.id}`}>
                  <a href={`https://www.creoate.com/store/${wholesaler.slug}`}>{wholesaler.brand_name}</a>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <div>
            {item.label}

            <ul>
              <li>{item?.ProductsColumn?.Title}</li>
              <ul>
                {item?.ProductsColumn?.products?.length > 0
                  ? item?.ProductsColumn?.products?.map((product) => {
                      return (
                        <li key={`${item.__component}-${item.id}`}>
                          <a href={`/product/${product.slug}`}>{product.name}</a>
                        </li>
                      );
                    })
                  : item?.ProductsColumn?.links?.length > 0
                  ? item?.ProductsColumn?.links?.map((link) => {
                      return (
                        <li key={`${item.__component}-${item.id}`}>
                          <a href={link.url}>{link.label}</a>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </ul>

            <ul>
              <li>{item?.CategoriesColumn?.Title}</li>
              <ul>
                {item?.CategoriesColumn?.collections?.length > 0
                  ? item?.CategoriesColumn?.collections?.map((collection) => {
                      return (
                        <li key={`${item.__component}-${item.id}`}>
                          <a href={`/collections/${collection.slug}`}>{collection.name}</a>
                        </li>
                      );
                    })
                  : item?.CategoriesColumn?.links?.length > 0
                  ? item?.CategoriesColumn?.links?.map((collection) => {
                      return (
                        <li key={`${item.__component}-${item.id}`}>
                          <a href={collection.url}>{collection.label}</a>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </ul>

            <ul>
              <li>{item?.WholesalersColumn?.Title}</li>
              <ul>
                {item?.WholesalersColumn?.wholesalers?.length > 0
                  ? item?.WholesalersColumn?.wholesalers?.map((wholesaler) => {
                      return (
                        <li key={`${item.__component}-${item.id}`}>
                          <a href={`/store/${wholesaler.slug}`}>{wholesaler.brand_name}</a>
                        </li>
                      );
                    })
                  : item?.WholesalersColumn?.links?.length > 0
                  ? item?.WholesalersColumn?.links?.map((wholesaler) => {
                      return (
                        <li key={`${item.__component}-${item.id}`}>
                          <a href={wholesaler.url}>{wholesaler.label}</a>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default SubTopMenuSSR;
