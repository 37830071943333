import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import useEnhancedSessionStorage from 'utils/useEnhancedSessionStorage';

import * as cartAPI from 'api/cart';
import { log } from 'utils/functions';

import { FACEBOOK_FBC, FACEBOOK_FBP, USER_CART_EMPTY_COOKIE_NAME } from 'constants';
import { NEXT_PUBLIC_COOKIE_DOMAIN } from 'constants/runtimeConfig';

import useAuth from 'hooks/useAuth';
import useFeatureFlags from 'hooks/useFeatureFlags';

import CartContext from 'context/CartContext';

const DEFAULT_CART = [];

const CartProvider = ({ children }) => {
  const [cart, setCart] = useEnhancedSessionStorage('cart', DEFAULT_CART);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [refreshCartTotal, setRefreshCartTotal] = useState(false);

  const [cookies, , removeCookie] = useCookies([FACEBOOK_FBC, FACEBOOK_FBP, USER_CART_EMPTY_COOKIE_NAME]);

  // external dependencies
  const { retailerId, userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const { asPath } = router;

  // configs
  const { IS_WORDPRESS_CONNECTED } = useFeatureFlags();

  const userIdentifier = IS_WORDPRESS_CONNECTED ? userId : retailerId || userId;

  const addExtraDetailsToCart = useCallback(
    (productDetail, quantity, selectedProductId, wpResponse) => {
      const cartDetails = cart;
      const isVariation = productDetail?.productVariationLabels && productDetail?.productVariationLabels?.length > 0;

      const [wpCartProduct] = wpResponse.filter((item) =>
        isVariation
          ? item.variationId === selectedProductId.toString()
          : item.productId === selectedProductId.toString()
      );

      const commonDetails = {
        quantity,
        addedAt: new Date().toISOString(),
        productId: IS_WORDPRESS_CONNECTED ? productDetail.WP_id : productDetail.id,
        productBulkDiscount: productDetail.productBulkDiscount,
        wholesaler: {
          wholesalerId: IS_WORDPRESS_CONNECTED ? productDetail.wholesaler.wpId : productDetail.wholesaler.id,
          minOrderValue: productDetail.wholesaler.minOrderValue,
          storeDiscount: productDetail.wholesaler.storeDiscount,
        },
        ...(wpCartProduct?.queryId && { queryId: wpCartProduct.queryId }),
      };

      if (!isVariation) {
        const extraDetails = {
          ...commonDetails,
          productPrice: productDetail.productPrice,
          productDiscount: productDetail.productDiscount,
        };
        cartDetails.push(extraDetails);
      } else {
        const selectedVariation = productDetail.variations.find(
          (variation) => variation.id === selectedProductId || variation.WP_id === selectedProductId.toString()
        );

        const extraDetails = {
          ...commonDetails,
          variationData: wpCartProduct?.variationData,
          variationId: selectedProductId,
          productPrice: selectedVariation.attributes.productPrice,
          productDiscount: selectedVariation.attributes.productDiscount,
        };
        cartDetails.push(extraDetails);
      }

      setCart(cartDetails);
    },
    [IS_WORDPRESS_CONNECTED, cart, setCart]
  );

  const removeCartItem = useCallback(
    (itemId) => {
      const cartDetails = cart.filter((item) => {
        return item.productId !== itemId && item.variationId !== itemId;
      });
      setCart(cartDetails);
    },
    [cart, setCart]
  );

  const increaseCartItemQuantity = useCallback(
    (itemId, quantity) => {
      const cartDetails = cart.map((item) => {
        if (item.productId === itemId || item?.variationId === itemId) {
          return { ...item, quantity: item.quantity + quantity };
        }
        return item;
      });
      setCart(cartDetails);
    },
    [cart, setCart]
  );

  const decreaseCartItemQuantity = useCallback(
    (itemId, quantity) => {
      const cartDetails = cart.map((item) => {
        if (item.productId === itemId || item?.variationId === itemId) {
          return { ...item, quantity: item.quantity - quantity };
        }
        return item;
      });
      setCart(cartDetails);
    },
    [cart, setCart]
  );

  const removeBrandFromLocalCart = useCallback(
    (brandId) => {
      const newCart = cart.filter((item) => item?.wholesaler?.wholesalerId !== brandId);
      setCart(newCart);
    },
    [cart, setCart]
  );

  // context functions
  const addToCart = useCallback(
    async ({ product, quantity, selectedProductId, queryId = null }) => {
      try {
        if (userIdentifier) {
          setIsCartLoading(true);
          const headers = {
            'X-Fbc': cookies[FACEBOOK_FBC],
            'X-Fbp': cookies[FACEBOOK_FBP],
          };

          // Include queryId in the request body if it exists
          const data = await cartAPI.addToCart(userIdentifier, {
            productId: selectedProductId,
            quantity,
            queryId,
            headers,
          });
          if (data) {
            addExtraDetailsToCart(product, quantity, selectedProductId, data);

            enqueueSnackbar('Product added to the cart', {
              variant: 'success',
            });
            setRefreshCartTotal(true);
          } else {
            log.error('No data in addToCart response ', data);
          }
        }
      } catch (error) {
        log.error('Error on addToCart. Error: ', error);
        enqueueSnackbar(`Couldn't add product to the cart. Please try again`, {
          variant: 'error',
        });

        throw error; // rethrow so we can handle UI resets
      } finally {
        setIsCartLoading(false);
      }
    },
    [userIdentifier, cookies, addExtraDetailsToCart, enqueueSnackbar]
  );

  const removeFromCart = useCallback(
    async ({ itemId }) => {
      try {
        if (userIdentifier) {
          setIsCartLoading(true);
          const data = await cartAPI.removeFromCart(userIdentifier, { itemId });
          if (data) {
            removeCartItem(itemId);
          }

          enqueueSnackbar('Product removed from the cart', {
            variant: 'success',
          });
          setRefreshCartTotal(true);
        }
      } catch (error) {
        log.error('Error on removeFromCart. Error: ', error);
        enqueueSnackbar(`Couldn't remove product from cart. Please try again`, {
          variant: 'error',
        });

        throw error; // rethrow so we can handle UI resets
      } finally {
        setIsCartLoading(false);
      }
    },
    [userIdentifier, enqueueSnackbar, removeCartItem]
  );

  const removeBrandFromCart = useCallback(
    async ({ brandId }) => {
      // brandId is the wholesaler id
      try {
        if (userIdentifier) {
          setIsCartLoading(true);
          const data = await cartAPI.removeBrandFromCart(userIdentifier, { brandId });
          if (data) {
            removeBrandFromLocalCart(brandId);
          }

          enqueueSnackbar('Brand removed from the cart', {
            variant: 'success',
          });
          setRefreshCartTotal(true);
        }
      } catch (error) {
        log.error('Error on removeBrandFromCart. Error: ', error);
        enqueueSnackbar(`Couldn't remove brand from cart. Please try again`, {
          variant: 'error',
        });

        throw error; // rethrow so we can handle UI resets
      } finally {
        setIsCartLoading(false);
      }
    },
    [enqueueSnackbar, userIdentifier, removeBrandFromLocalCart]
  );

  const increaseInCart = useCallback(
    async ({ itemId, quantity }) => {
      try {
        if (userIdentifier) {
          setIsCartLoading(true);
          const data = await cartAPI.increaseInCart(userIdentifier, { itemId, quantity }).then();
          if (data) {
            increaseCartItemQuantity(itemId, quantity);
          }

          enqueueSnackbar(`Added ${quantity} item(s) to the cart`, {
            variant: 'success',
          });
          setRefreshCartTotal(true);
        }
      } catch (error) {
        log.error('Error on increaseInCart. Error: ', error);
        enqueueSnackbar(`Couldn't add item(s) to the cart. Please try again`, {
          variant: 'error',
        });

        throw error; // rethrow so we can handle UI resets
      } finally {
        setIsCartLoading(false);
      }
    },
    [userIdentifier, enqueueSnackbar, increaseCartItemQuantity]
  );

  const decreaseInCart = useCallback(
    async ({ itemId, quantity }) => {
      try {
        if (userIdentifier) {
          setIsCartLoading(true);
          const data = await cartAPI.decreaseInCart(userIdentifier, { itemId, quantity });
          if (data) {
            decreaseCartItemQuantity(itemId, quantity);
          }

          enqueueSnackbar(`Removed ${quantity} item(s) from the cart`, {
            variant: 'success',
          });
          setRefreshCartTotal(true);
        }
      } catch (error) {
        log.error('Error on decreaseInCart. Error: ', error);
        enqueueSnackbar(`Couldn't remove item(s) from the cart. Please try again`, {
          variant: 'error',
        });

        throw error; // rethrow so we can handle UI resets
      } finally {
        setIsCartLoading(false);
      }
    },
    [userIdentifier, enqueueSnackbar, decreaseCartItemQuantity]
  );

  const getCartAndStore = useCallback(async () => {
    try {
      setIsCartLoading(true);
      if (userIdentifier && userIdentifier !== 'undefined') {
        const data = await cartAPI.getCart(userIdentifier);
        setCart(data);
      }
    } catch (error) {
      setCart(DEFAULT_CART);
      log.error('Error on getCart. Error: ', error);
    } finally {
      setIsCartLoading(false);
    }
  }, [setCart, userIdentifier]);

  const getCart = useCallback(async () => {
    if (userIdentifier) {
      await getCartAndStore();
    }
  }, [userIdentifier, getCartAndStore]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    (async () => {
      if (cookies[USER_CART_EMPTY_COOKIE_NAME]) {
        await getCartAndStore();
        removeCookie(USER_CART_EMPTY_COOKIE_NAME, { path: '/', domain: NEXT_PUBLIC_COOKIE_DOMAIN });
      }
    })();
  }, [asPath, cookies, getCartAndStore, removeCookie]);

  // react to log in / log out
  // CANNOT ADD getCart TO DEPENDENCIES !!!!!
  useEffect(() => {
    (async () => {
      if (userIdentifier) {
        await getCart();
      } else {
        await setCart([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdentifier]);

  // wishlist state
  const state = useMemo(
    () => ({
      cart,
      setCart,
      getCart,
      addToCart,
      removeFromCart,
      increaseInCart,
      decreaseInCart,
      removeBrandFromCart,
      isCartLoading,
      setIsCartLoading,
      refreshCartTotal,
      setRefreshCartTotal,
    }),
    [
      cart,
      setCart,
      getCart,
      addToCart,
      removeFromCart,
      increaseInCart,
      decreaseInCart,
      removeBrandFromCart,
      isCartLoading,
      setIsCartLoading,
      refreshCartTotal,
      setRefreshCartTotal,
    ]
  );

  return <CartContext.Provider value={state}>{children}</CartContext.Provider>;
};

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CartProvider;
