import PropTypes from 'prop-types';

// hooks
import useAuth from 'hooks/useAuth';
import useCurrency from 'hooks/useCurrency';

// components
import Label from 'components/Label';

const kycButtonDefaultPropSx = {};

const KycButton = ({ sx = kycButtonDefaultPropSx }) => {
  const { currency } = useCurrency();
  const currencySymbol = currency?.symbol;

  const { isAuthenticated, user, userCredit } = useAuth();
  if (!isAuthenticated || !user || !userCredit) return null;

  const { paymentDays, creditPayUpfrontPercentage } = user;

  const buttonText = `${
    creditPayUpfrontPercentage && creditPayUpfrontPercentage > 0 ? `${creditPayUpfrontPercentage}% upfront | ` : ''
  } ${currencySymbol}${userCredit.creditLimit} credit${paymentDays && paymentDays > 0 ? ` | ${paymentDays} days` : ''}`;

  return (
    <Label variant="filled" type="NEW" sx={{ ...sx }}>
      {buttonText}
    </Label>
  );
};

KycButton.propTypes = {
  sx: PropTypes.shape({}),
};

export default KycButton;
