import Image from 'next/image';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/** Utils */
import { customImageLoader } from 'utils/functions';

const CustomImage = ({
  src = '',
  fallbackSrc = '/assets/images/product/coming-soon.webp',
  alt = 'image-alt-text',
  sx = {},
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState(src);

  // on src change reset imgSrc
  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      data-testid="image"
      loader={customImageLoader}
      src={imgSrc}
      alt={alt}
      {...rest}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
      style={{
        ...sx,
        maxWidth: '100%',
      }}
    />
  );
};

CustomImage.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
};

export default CustomImage;
