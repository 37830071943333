/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import bffAPI from 'services/api/bff';
import { NODE_ENV } from 'constants/runtimeConfig';
import WORDPRESS_ROUTES from 'constants/wordpress-routes';
import { IMAGE_RESIZING_CDN_BASE_URL } from 'constants/index';
import { countriesList, filterUrlQueryParams, minOrderList } from 'constants/category-filters';
import { getProductBulkDiscount, getProductDirectDiscount } from '@creoate/cart-lib/discounts';
import { phoneCodeList } from 'constants/address-form';
import { serverLogger } from './serverLogs.utils';

export const isBrowser = typeof window !== 'undefined';

export const isNavigator = typeof navigator !== 'undefined';

export const isDev = NODE_ENV === 'development';

export const stringToBoolMap = {
  true: true,
  false: false,
};

/**
 * @param obj {Object}
 * @returns {boolean}
 */
export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const noOp = () => {};

export const log = {
  info: (message, context) => serverLogger.info(message, context, 'generic', 'server'),
  warn: (message, context) => serverLogger.warn(message, context, 'generic', 'server'),
  error: (message, context) => serverLogger.error(message, context, 'generic', 'server'),
};

/**
 * Gets a WordPress url with the provided callbackUrl as queryParam
 * @type function
 * @param route {string} - WordPress route enum
 * @param callbackUrl [string] - virgo page url for WordPress to route back to
 * @returns {string | null}
 */
export const getWordpressRoute = (route, callbackUrl) => {
  let wpRoute = WORDPRESS_ROUTES[route];

  if (callbackUrl) {
    wpRoute += `?callbackUrl=${callbackUrl}`;
  }

  return wpRoute;
};

/**
 * Gets a rate provided by Yahoo finance API
 * @type function
 * @param from {'USD' | 'GBP' | 'EUR'} - currency name GBP, USD, EUR ...
 * @param to {'USD' | 'GBP' | 'EUR'} - currency name GBP, USD, EUR ...
 * @returns {Promise<any>} - Promise with the rate
 * @example
 * getRate('GBP').then(rate => console.log(rate));
 * // => { rate: 0.8983, name: 'GBP', symbol: 'GBP' }
 */

// Get rate from Yahoo finance Endpoint
export const getRate = async (from, to) => {
  // Current date in seconds
  const date = Math.round(Date.now() / 1000);
  // We will get the last 7 days (a day is 86400sec) rates(period) just to prevent errors returning data because the market is closed several days in a week
  const url = `https://query1.finance.yahoo.com/v8/finance/chart/${from}${to}=X?symbol=${from}${to}=X&period1=${
    date - 7 * 86400
  }&period2=${date}&interval=1d&includePrePost=false`;
  const { data } = await axios.get(url);
  // Bussiness logic is get the last open rate available. If there are not value in the period, we will get previous close rate value.
  if (data.chart.result[0].indicators.quote[0].open) {
    return data.chart.result[0].indicators.quote[0].open.pop();
  }
  return data.chart.result[0].meta.chartPreviousClose;
};

/**
 * Convert current price from(currentCurrency) to (desired currency)
 * @type function
 * @param currentCurrency {string} - currency name GBP, USD, EUR ...
 * @param desiredCurrency {string} - currency name GBP, USD, EUR ...
 * @param price {number} - current price
 * @returns {number} - converted price
 * @example
 * convertCurrency('USD', 'GBP', 100) // returns 0.8
 * convertCurrency('GBP', 'USD', 100) // returns 1.4
 * convertCurrency('EUR', 'GBP', 100) // returns 1.2
 */
export const convertPrice = async (currentCurrency, desiredCurrency, price) => {
  if (currentCurrency === desiredCurrency) {
    return price;
  }
  const rate = await getRate(currentCurrency, desiredCurrency);
  return price * rate;
};

/**
 * @param price
 * @return {{
 *    price: string,
 *    floating: string
 * }}
 * @example
 * formatPrice(123456)
 * // {
 * //   price: '123,456',
 * //   floating: '123,456.00'
 * // }
 * @example
 * formatPrice(123456.789)
 * // {
 * //   price: '123,456.79',
 * //   floating: '123,456.79'
 * // }
 */
export const splitPrice = (price = 0) => {
  try {
    const priceSplit = price.toFixed(2).toString().split('.');
    return {
      price: priceSplit[0],
      floating: priceSplit[1],
    };
  } catch (e) {
    // should log to sentry js
    log.error(e);
    return {
      price: '0',
      floating: '00',
    };
  }
};

export const bffFetcher = (url) => bffAPI.get(url).then((res) => res.data);

export const setPriceInDesiredCurrency = (
  multiplePrices,
  priceField,
  currencyField,
  desiredCurrencyName,
  uiSetter,
  exchangeRates
) => {
  if (!exchangeRates) {
    return;
  }

  // get a price that exists and use it to convert to selected currency
  const existingPrice = multiplePrices?.filter((x) => x[priceField] > 0).pop();

  if (!existingPrice) {
    return;
  }

  const existingPriceCurrency = existingPrice[currencyField];
  const existingPriceValue = existingPrice[priceField];

  const rate = exchangeRates[`${existingPriceCurrency}${desiredCurrencyName}`];
  const convertedPrice = existingPriceValue * rate;

  uiSetter(convertedPrice / 100);
};

/**
 * @param imageBaseUrl {string} - creoateprod.s3.eu-west-2.amazonaws.com | creoate.com/wp-content
 * @param imageName {string} - wp-content/uploads/2021/04/21181831/sq-0619-tablemock.jpg
 * @param resolution {string} - 640x640
 * @returns {string} <cdn-url>/wp-content/uploads/2021/04/21181831/sq-0619-tablemock-360x360.jpg
 */
export const transformImageUrl = (imageBaseUrl, imageName, resolution = null, addBasePath = false) => {
  if (!imageBaseUrl || !imageName) {
    return '';
  }
  // remove "creoate.com" & concat with remaining of image key
  let finalImageName = imageName;
  if (imageBaseUrl && imageBaseUrl.includes('www.creoate.com/')) {
    finalImageName = `${imageBaseUrl?.replace('www.creoate.com/', '')}/${imageName}`;
  }
  const imageBaseNameArr = finalImageName.split('.');
  const ext = imageBaseNameArr.pop();
  const name = imageBaseNameArr.join('.');

  const imgName = resolution ? `${name}-${resolution}${ext ? `-${ext}.webp` : ''}` : `${name}${ext ? `.${ext}` : ''}`;
  return addBasePath ? `${IMAGE_RESIZING_CDN_BASE_URL}/${imgName}` : imgName;
};

// variationData like {attribute_size-in-cm: "21X30", attribute_paper-finish: "Matte"}
export const parseWordpressVariationData = (variationData) => {
  if (!variationData || Object.keys(variationData).length === 0) {
    return {};
  }

  const parsed = {};
  // eslint-disable-next-line prefer-const
  for (let [key, value] of Object.entries(variationData)) {
    const newKey = key.split('attribute_').pop();
    parsed[newKey] = value;
  }

  return parsed;
};

export const customImageLoader = ({ src }) => {
  if (src.includes('/assets')) return src;
  if (src.includes('/strapi')) return src;
  if (src.includes('/hs-fs/hubfs')) return `https://i0.wp.com/${src}`;
  return `${IMAGE_RESIZING_CDN_BASE_URL}/${src}`;
};

/**
 *
 * @param {array} productVariations - all variations of a product
 * @param {string} currencyName - current currency name
 * @returns {object} object with maxPrice and minPrice keys
 */
export const getMinMaxPriceFromVariations = (productVariations, currencyName) => {
  const allVariationPrices = productVariations?.reduce((acc, curr) => {
    const priceObj = curr?.attributes?.productPrice.find((o) => o.productCurrency === currencyName);
    return [...acc, priceObj.productWSP];
  }, []);

  return {
    minPrice: allVariationPrices.reduce((a, b) => Math.min(a, b)),
    maxPrice: allVariationPrices.reduce((a, b) => Math.max(a, b)),
  };
};

export const getApiQueryParamsFromUrlQueryParams = (query, { defaults }) => {
  const {
    limit,
    [filterUrlQueryParams.SORT_BY]: sortBy,
    [filterUrlQueryParams.ON_OFFER]: onOffer,
    [filterUrlQueryParams.CATEGORY]: category,
    [filterUrlQueryParams.MIN_ORDER]: minOrder,
    [filterUrlQueryParams.COUNTRIES]: countries,
    [filterUrlQueryParams.BRAND_VALUE]: brandValue,
    [filterUrlQueryParams.LEAD_TIME_MAX_DAYS]: leadTimeMaxDays,
    [filterUrlQueryParams.FLAIRS]: flairs,
  } = query;

  let minOrderValue;
  if (minOrder) {
    minOrderValue = minOrderList.find((o) => o.slug === minOrder)?.value;
  }

  const queryParams = new URLSearchParams({
    ...(sortBy ? { sortBy: sortBy.toLowerCase() } : { sortBy: defaults.sortBy }),
    ...(countries && { countries }),
    ...(brandValue && { [filterUrlQueryParams.BRAND_VALUE]: brandValue }),
    ...(onOffer && { onOffer }),
    ...(category && { category }),
    ...(limit && { limit }),
    ...(flairs && { flairs }),
    ...(minOrder && { minOrder: minOrderValue }),
    ...(leadTimeMaxDays && { [filterUrlQueryParams.LEAD_TIME_MAX_DAYS]: leadTimeMaxDays }),
    ...(query?.includeBrands && { includeBrands: true }),
  });

  const params = queryParams.toString() ? `&${decodeURIComponent(queryParams.toString())}` : '';
  return params;
};

/**
 * @param {Object} productDetails - the details of the product
 * @param {string} productDetails.productId - product?.id
 * @param {number} productDetails.productPrice - WSP of the product, normally it's the product.productPrice
 * @param {number} productDetails.productDiscountedPrice - disocunt price of the product, product.productDiscount
 * @param {number} productDetails.productBulkDiscountMinOrderQuantity - minimum order for bulk discount to apply
 * @param {number} productDetails.productBulkDiscountPercentage - bulk discount percentage
 * @param {number} productDetails.quantity - quantity of the product selected
 * @returns {number}
 */

export const getProductSubtotal = ({
  productId = '',
  productPrice = 0,
  quantity = 0,
  productDiscountedPrice = 0,
  productBulkDiscountMinOrderQuantity = 0,
  productBulkDiscountPercentage = 0,
}) => {
  const { total: productSubtotalWithDirectDiscount } = getProductDirectDiscount({
    productId,
    productDiscountedPrice,
    productPrice,
    quantity,
  });

  const { discount: productBulkDiscount } = getProductBulkDiscount({
    productId,
    productPrice: productDiscountedPrice > 0 ? productDiscountedPrice : productPrice,
    quantity,
    bulkDiscountMinOrderQuantity: productBulkDiscountMinOrderQuantity,
    bulkDiscountPercentage: productBulkDiscountPercentage,
  });

  return productSubtotalWithDirectDiscount - productBulkDiscount;
};

export const getCartItemsWithCommonWholesalerId = ({ cart, wholesalerId, currencyName }) => {
  return cart
    ?.filter((cartProduct) => cartProduct?.wholesaler?.wholesalerId === wholesalerId)
    .map((cartProduct) => ({
      productId: cartProduct?.productId,
      productPrice: cartProduct?.productPrice?.find((price) => price?.productCurrency === currencyName)?.productWSP,
      quantity: cartProduct?.quantity,
      productDiscountedPrice: cartProduct?.productDiscount?.find(
        (discountObj) => discountObj?.productDiscountCurrency === currencyName
      )?.productDiscountValue,
      productBulkDiscount: {
        productBulkDiscountPercentage: cartProduct?.productBulkDiscount?.productBulkDiscountPercentage,
        productBulkDiscountMinOrderQuantity: cartProduct?.productBulkDiscount?.productBulkDiscountMinOrderQuantity,
      },
    }));
};

/* Returns no. of products based on the day of week
 * @returns {string}
 */
export const getNumberOfProductsSEO = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const seoProductQuantities = ['10000', '11000', '12000', '13000', '14000', '15000', '16000'];
  return `${seoProductQuantities[dayOfWeek]}+`;
};

/**
 * Get the sum of all non-excluded discounts from the object
 * @param {object} discounts Wholesaler summary object to discount
 * @param {number} discounts.bulk Discount applied when more than an amount of product was bought
 * @param {number} discounts.direct Discount applied when the product is on offer
 * @param {number} discounts.store Discount applied when the brand is giving discount for the order total
 * @param {string} excludedDiscounts Array of discount names to exclude from the summary
 * @returns The sum of all non-excluded discounts from the object
 */
export const getDiscountsTotal = (discounts, excludedDiscounts = ['direct', 'voucher']) =>
  Object.keys(discounts || {}).reduce(
    (prev, curr) => (excludedDiscounts.indexOf(curr) < 0 ? prev + discounts[curr] || 0 : prev),
    0
  );

/**
 * Get the count of all non-excluded discounts from the object
 * @param {object} discounts Wholesaler summary object to discount
 * @param {number} discounts.bulk Discount applied when more than an amount of product was bought
 * @param {number} discounts.direct Discount applied when the product is on offer
 * @param {number} discounts.store Discount applied when the brand is giving discount for the order total
 * @param {string} excludedDiscounts Array of discount names to exclude from the summary
 * @returns The count of all non-excluded and non-zero discounts from the object
 */
export const getDiscountCount = (discounts, excludedDiscounts = ['direct']) =>
  Object.keys(discounts || {}).reduce(
    (prev, curr) => (excludedDiscounts.indexOf(curr) < 0 && (discounts[curr] || 0) > 0 ? prev + 1 : prev),
    0
  );

/**
 *
 * @param {object} objectToCheck The object whose keys we will be checking
 * @param {array} keysToCheck An array of keys to check into
 * @return {boolean}
 */
export const areAllKeysTruthy = (objectToCheck, keysToCheck) => {
  return Boolean(
    objectToCheck &&
      Object.keys(objectToCheck).length &&
      Object.keys(objectToCheck)
        .filter((key) => Object.keys(keysToCheck).includes(key))
        .every((k) => objectToCheck[k])
  );
};

/**
 *
 * @param {string} blogPostResponseData The Rss blog xml file
 * @returns {array}
 */

export const extractBlogPostRss = (blogPostResponseData) => {
  const blogPostData = [];

  const imgRgx = /src="(.*?)"/;
  const titleRgx = /<title>(.*?)<\/title>/;
  const linkRgx = /<link>(.*?)<\/link>/;

  const items = blogPostResponseData.split('<item>');
  items.slice(1).forEach((item, idx) => {
    const title = item.match(titleRgx)[1]?.replace(/&amp;/g, '&');
    const link = item.match(linkRgx)[1];
    const imgSrc = item.match(imgRgx)[1];

    blogPostData.push({ id: idx + 1, name: title, url: link, image: imgSrc });
  });

  return blogPostData;
};

/**
 *
 * @param {number} number Number to truncate
 * @param {number} decimalAmount amount of decimals to truncate
 * @returns {number}
 */

export const truncate = (number, decimalAmount = 2) => {
  const multiplier = 10 ** decimalAmount;
  return Math.trunc(Number(number) * multiplier) / multiplier;
};

/**
 *
 * @param {session_key} string The key of session
 * @param {session_data} string session data
 */

export const setSessionStorage = (sessionKey, sessionData) => {
  sessionStorage.setItem(sessionKey, sessionData);
};

/**
 *
 * @param {session_key} string The key of session
 *
 */

export const getSessionStorage = (sessionKey) => {
  const sessionData = sessionStorage.getItem(sessionKey);
  return sessionData || JSON.stringify([]);
};

/**
 *
 * @param {description}
 *
 */
export const addNoFollow = (description) => {
  // Check if the document object is available (i.e., if the code is running in a browser)
  let modifiedHTML = description;
  if (typeof document !== 'undefined') {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = description;

    // Find all <a> tags within the temporary div
    const links = tempDiv.querySelectorAll('a');

    // Add the rel="nofollow" attribute to each <a> tag
    links.forEach((link) => {
      link.setAttribute('rel', 'nofollow');
    });

    // Extract the modified HTML from the temporary div
    modifiedHTML = tempDiv.innerHTML;
  }
  return modifiedHTML;
};

/**
 *
 * @param {object} description
 * @returns {string}
 */

export const replaceH1withH2 = (description) => {
  const regex = new RegExp(`\\b${`h1`}\\b`, 'gi');
  return description?.replace(regex, 'h2');
};

/**
 *
 * @param {object} phone
 * @returns {string}
 * @param {object} countryCode
 * @returns {string}
 */

export const getPhoneWithoutCode = (phone, countryCode) => {
  if (!phone) {
    return '';
  }

  // Find the country object by code
  const country = phoneCodeList.find((list) => list.code.toLowerCase() === countryCode?.toLowerCase());

  if (country) {
    const dialCode = country.dial_code;
    const formattedPhone = phone?.replace(dialCode, '');
    return formattedPhone;
  }
  return phone;
};

export const transformWPDateToDateString = (dateString) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  const date = new Date(`${year}-${month}-${day}`);

  if (date) {
    const longMonth = date.toLocaleString('en-US', { month: 'long' });
    return `${day} ${longMonth} ${year} `;
  }

  return null;
};

export const countryNameFromCode = (countryCode) =>
  countriesList.find((country) => country.countryCode === countryCode)?.countryName;

const currencies = {
  GBP: '£',
  USD: '$',
  EUR: '€',
};

export const currencySymbolFromCurrencyCode = (currencyCode) => currencies[currencyCode] || '£';

export const isUserTypeVendor = (userType) => ['Wholesaler'].includes(userType);

/**
 * Generates Algolia analytics tags based on user data and mappings.
 *
 * @param {string} reference - A reference identifier.
 * @param {Object} user - The user object containing relevant details.
 * @param {string} user.shippingCountry - The user's shipping country.
 * @param {string} user.storeType - The type of store the user operates.
 * @param {string} user.businessType - The type of business the user operates.
 * @param {string} user.userType - The user's type/category.
 * @param {number} [user.creditLimit] - The user's credit limit.
 * @param {string} user.yearsOfTrading - The number of years the user has been trading.
 * @param {string} user.annualSales - The user's annual sales.
 * @returns {string[]} An array of tags for Algolia analytics.
 */
export const generateAlgoliaAnalyticsTags = (reference, user) => {
  // Return only reference and 'Guest' for anonymous users
  if (!user) return [reference, 'Guest'];

  const mappings = {
    storeType: {
      brick_mortar_lease: 'BM Lease',
      brick_mortar: 'BM Lease',
      brick_mortar_ecommerce: 'BM Lease',
      ecommerce: 'Ecom',
      brick_mortar_popup: 'BM Popup',
    },
    businessType: {
      'Individual / Sole Trader': 'Soletrader/Individual',
      'Limited Company': 'Limited',
      Partnership: 'Partnership',
    },
    yearsOfTrading: {
      'Less than 1 year': '-1',
      'More than 5 years': '5+',
      'Between 1 and 3 years': '1-3',
      'Between 1-3 years': '1-3',
      'Between 1 and 5 years': '1-5',
      'Between 3 and 5 years': '3-5',
      'Between 3-5 years': '3-5',
    },
    annualSales: {
      'Less than 50K': '-50',
      '50K-250K': '50-250',
      '250K-500K': '250-500',
      'More than 500K': '500+',
    },
  };

  const creditLimitMapping = (creditLimit) => {
    if (!creditLimit) return 'hasNoCredit';
    if (creditLimit <= 700) return '1-700';
    if (creditLimit <= 1000) return '701-1000';
    return '1000+';
  };

  return [
    reference,
    user.shippingCountry,
    mappings.storeType[user.storeType] || user.storeType,
    mappings.businessType[user.businessType] || user.businessType,
    user.userType,
    creditLimitMapping(user.creditLimit),
    mappings.yearsOfTrading[user.yearsOfTrading] || user.yearsOfTrading,
    mappings.annualSales[user.annualSales] || user.annualSales,
  ];
};

export const renderKycInfo = (user, userCredit) => {
  if (!user || !userCredit) return false;

  const { userType, creditPayUpfrontPercentage } = user || {};

  if (!userType || isUserTypeVendor(userType)) {
    return false;
  }

  if (!userCredit.creditLimit || userCredit.creditLimit <= 0 || creditPayUpfrontPercentage === 100) {
    return false;
  }

  return true;
};
