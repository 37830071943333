import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import Dialog from '@mui/material/Dialog';

import SignupPopup from 'components/SignupPopup';

import SignupPopupContext from 'context/SignupPopupContext';

const SignupPopupProvider = ({ children }) => {
  const [showSignupPopup, setShowSignupPopup] = useState(false);

  const handleShowSignupPopup = useCallback(() => {
    setShowSignupPopup(true);
  }, []);

  const handleHideSignupPopup = useCallback(() => {
    setShowSignupPopup(false);
  }, []);

  const state = useMemo(
    () => ({
      handleShowSignupPopup,
      handleHideSignupPopup,
    }),
    [handleShowSignupPopup, handleHideSignupPopup]
  );

  return (
    <SignupPopupContext.Provider value={state}>
      <Dialog
        scroll="body"
        unmountOnExit
        open={showSignupPopup}
        onClose={handleHideSignupPopup}
        PaperProps={{ sx: { borderRadius: '16px', maxWidth: '374px', overflow: 'hidden', backgroundColor: 'initial' } }}
      >
        <SignupPopup />
      </Dialog>
      {children}
    </SignupPopupContext.Provider>
  );
};

export default SignupPopupProvider;

SignupPopupProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
