import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

const TitleH1 = styled(Typography)(({ theme }) => ({
  align: 'center',
  color: 'TextPrimary',
  mt: -2,
  fontWeight: '100',
  [theme.breakpoints.down('md')]: {
    fontSize: '72px',
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  align: 'center',
  color: 'TextPrimary',
  marginTop: theme.spacing(1.5),
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}));

const GenericError = ({ onClickHandler, isRetryable = false, onRetryHandler = undefined }) => {
  const theme = useTheme();

  const handleRetryClick = async () => {
    if (onRetryHandler) {
      await onRetryHandler();
    }
  };

  return (
    <Container maxWidth="md" sx={{ my: theme.spacing(3.75) }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <TitleH1 variant="h1"> Whoops! </TitleH1>
        <Text variant="h5">This page is not available</Text>

        {isRetryable ? (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button sx={{ mt: theme.spacing(1.5) }} variant="contained" onClick={handleRetryClick} disableElevation>
              Try Again
            </Button>
            <Text variant="h6">or</Text>
            <Button sx={{ mt: theme.spacing(1.5) }} variant="text" onClick={onClickHandler}>
              Go to homepage
            </Button>
          </Box>
        ) : (
          <>
            <Text variant="h6">Try searching or</Text>
            <Button sx={{ mt: theme.spacing(1.5) }} variant="contained" onClick={onClickHandler}>
              Go to homepage
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

GenericError.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  isRetryable: PropTypes.bool,
  onRetryHandler: PropTypes.func,
};

export default GenericError;
