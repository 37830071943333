export const NOT_SUPPORTED_DATA = 'Not aviailable data';

/**
 * Wrapper function to return a default not supported data string when the data is unavailable
 * @param {*} data The data to be returned when it is available
 * @returns {*} data or `NOT_SUPPORTED_DATA` string
 */
const supportDataWrapper = (data) => (data === undefined || data === null ? NOT_SUPPORTED_DATA : data);

/**
 * Function to get the browser information
 * @returns {object} The browser information object
 */
export const getBrowserInfo = () => {
  const browserInfo = {
    // General browser information
    userAgent: supportDataWrapper(navigator.userAgent),
    platform: supportDataWrapper(navigator.userAgentData?.platform),
    isMobile: supportDataWrapper(navigator.userAgentData?.mobile),

    // Language and data connection
    language: supportDataWrapper(navigator.language),
    online: supportDataWrapper(navigator.onLine),
    connection: navigator.connection
      ? {
          effectiveType: supportDataWrapper(navigator.connection.effectiveType),
          downlink: supportDataWrapper(navigator.connection.downlink),
          downlinkMax: supportDataWrapper(navigator.connection.downlinkMax),
          rtt: supportDataWrapper(navigator.connection.rtt),
          type: supportDataWrapper(navigator.connection.type),
        }
      : NOT_SUPPORTED_DATA,

    // Browser window
    window: {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      outerWidth: window.outerWidth,
      outerHeight: window.outerHeight,
    },

    // Cookies support
    cookiesEnabled: navigator.cookieEnabled,
  };

  return browserInfo;
};
