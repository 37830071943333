import PropTypes from 'prop-types';
import { useLocalStorage, useSessionStorage } from 'react-use';
import { useCallback, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { getCurrenciesExchangeRate } from 'api/currency';

// Context
import CurrencyContext from 'context/CurrencyContext';

// Constants
import currencies from 'constants/currencies';
import { NEXT_PUBLIC_COOKIE_DOMAIN } from 'constants/runtimeConfig';

const COOKIE_NAME = 'creoate_current_currency';
const COOKIE_DOMAIN = NEXT_PUBLIC_COOKIE_DOMAIN;
const COOKIE_OPTIONS = { path: '/', domain: COOKIE_DOMAIN, maxAge: 60 * 60 * 2 };

const CurrencyProvider = ({ children }) => {
  // own state
  const [currency, setLocalCurrency] = useLocalStorage(
    'currency',
    currencies.find((c) => c.name === 'GBP')
  );

  const [exchangeRates, setExchangeRates] = useSessionStorage('exchangeRates');

  // eslint-disable-next-line no-unused-vars
  const [_, setCookie] = useCookies([COOKIE_NAME]);

  // fetch exchange rates from external source
  useEffect(() => {
    if (!exchangeRates) {
      (async () => {
        setExchangeRates(
          (await getCurrenciesExchangeRate()).reduce(
            (prevValue, currValue) => ({
              ...prevValue,
              [`${currValue.tradingPair}`]: currValue.rate,
            }),
            {}
          )
        );
      })();
    }
  }, [exchangeRates, setExchangeRates]);

  // actions
  const setCurrency = useCallback(
    (newCurrencyName) => {
      setLocalCurrency(currencies.find((c) => c.name === newCurrencyName));
      setCookie(COOKIE_NAME, newCurrencyName, COOKIE_OPTIONS);
    },
    [setCookie, setLocalCurrency]
  );

  // currency state
  const state = useMemo(
    () => ({
      currency,
      exchangeRates,
      setCurrency,
    }),
    [exchangeRates, currency, setCurrency]
  );

  return <CurrencyContext.Provider value={state}>{children}</CurrencyContext.Provider>;
};

CurrencyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CurrencyProvider;
