import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';

import TopMenuContext from 'context/TopMenuContext';

const TopMenuProvider = ({ children, data = undefined, error = undefined }) => {
  const target = useRef(null);
  const [selectedMenuSection, setSelectedMenuSection] = useState({
    id: null,
    label: null,
  });
  const [isItemHovered, setIsItemHovered] = useState(false);

  const state = useMemo(
    () => ({
      error,
      target,
      topMenu: data,
      selectedMenuSection,
      setSelectedMenuSection,
      isItemHovered,
      setIsItemHovered,
    }),
    [data, error, isItemHovered, selectedMenuSection, setIsItemHovered]
  );

  return <TopMenuContext.Provider value={state}>{children}</TopMenuContext.Provider>;
};

TopMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.objectOf(PropTypes.any),
};

export default TopMenuProvider;
