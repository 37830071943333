import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useLocalStorage } from 'react-use';
import { useCallback, useMemo, useEffect, useState } from 'react';

import { log } from 'utils/functions';
import useAuth from 'hooks/useAuth';
import * as wishlistAPI from 'api/wishlist';

import { WISHLIST_COOKIE_NAME } from 'constants/constants';
import WishlistContext from 'context/WishlistContext';
import { COOKIE_OPTIONS } from 'utils/cookies';

const WishlistProvider = ({ children }) => {
  // own state
  const [wishlistId, setWishlistId] = useState(null);
  const [, setCookie, removeCookie] = useCookies();
  const [wishlist, setWishlist, removeWishlist] = useLocalStorage('wishlist', []);

  // external dependencies
  const { userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // context functions
  const addToWishlist = useCallback(
    async (productsToAdd) => {
      try {
        if (userId) {
          const data = await wishlistAPI.addToWishlist(wishlistId, productsToAdd);
          setWishlist(data.products);
          setCookie(WISHLIST_COOKIE_NAME, data.products, COOKIE_OPTIONS);

          enqueueSnackbar(`Product(s) added to the wishlist`, {
            variant: 'success',
          });
        }
      } catch (error) {
        log.error('Error on addToWishlist. Error: ', error);

        enqueueSnackbar(`Couldn't add product(s) to the wishlist. Please try again`, {
          variant: 'error',
        });
      }
    },
    [userId, wishlistId, setWishlist, enqueueSnackbar, setCookie]
  );

  const removeFromWishlist = useCallback(
    async (productToRemove) => {
      try {
        if (userId) {
          const data = await wishlistAPI.removeFromWishlist(wishlistId, productToRemove);
          setWishlist(data.products);
          setCookie(WISHLIST_COOKIE_NAME, data.products, COOKIE_OPTIONS);

          enqueueSnackbar(`Product(s) removed from the wishlist`, {
            variant: 'success',
          });
        }
      } catch (error) {
        log.error('Error on removeFromWishlist. Error: ', error);

        enqueueSnackbar(`Couldn't remove product from the wishlist. Please try again`, {
          variant: 'error',
        });
      }
    },
    [userId, wishlistId, setWishlist, enqueueSnackbar, setCookie]
  );

  const cleanWishlistState = useCallback(async () => {
    await removeWishlist();
    removeCookie(WISHLIST_COOKIE_NAME);
    setWishlistId(null);
  }, [setWishlistId, removeWishlist, removeCookie]);

  const getWishlist = useCallback(async () => {
    if (userId) {
      try {
        const remoteWishlist = await wishlistAPI.getWishlist();

        setWishlistId(remoteWishlist.id);
        setWishlist(remoteWishlist.products);
        setCookie(WISHLIST_COOKIE_NAME, remoteWishlist.products, COOKIE_OPTIONS);
      } catch (error) {
        log.error('Error on getWishlist. Error: ', error);
      }
    }
  }, [setWishlistId, setWishlist, userId, setCookie]);

  // react to log in / log out
  // CANNOT ADD getWishlist TO DEPENDENCIES !!!!!
  useEffect(() => {
    (async () => {
      if (userId) {
        await getWishlist();
      } else {
        await cleanWishlistState();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // wishlist state
  const state = useMemo(
    () => ({
      wishlist,
      getWishlist,
      addToWishlist,
      removeFromWishlist,
      cleanWishlistState,
    }),
    [wishlist, getWishlist, addToWishlist, removeFromWishlist, cleanWishlistState]
  );

  return <WishlistContext.Provider value={state}>{children}</WishlistContext.Provider>;
};

WishlistProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WishlistProvider;
