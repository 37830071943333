import PropTypes from 'prop-types';

import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Link from 'components/Link/Link';
import CopyrightText from 'components/Header/TopMenu/MobileTopMenu/CopyrightText';

/** Consts & Utils */
import { DEFAULT_SORT_BY_VALUE } from 'constants/listing-defaults';

const mobileTopMenuCategoryDefaultPropProductSubcategories = [];

const MobileTopMenuCategory = ({
  categoryName = '',
  categorySlug = '',
  productSubcategories = mobileTopMenuCategoryDefaultPropProductSubcategories,
  handleTopMenuCategoryGoBack,
}) => {
  return (
    <Stack direction="column" sx={{ height: '100%', overflow: 'hidden' }}>
      <List>
        <ListItem>
          <ListItemIcon>
            <IconButton onClick={handleTopMenuCategoryGoBack} aria-label="go back" sx={{ p: 0 }}>
              <ChevronLeftIcon color="primary" />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            // sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
            sx={{ m: 0 }}
            primary={
              <Typography variant="h6" noWrap paddingRight="50px" textAlign="center">
                {categoryName}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider />
      <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            href={`/product-category/${categorySlug}?sort-by=${DEFAULT_SORT_BY_VALUE}`}
            passHref
          >
            <ListItemText
              primary={
                <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                  NEW
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        {productSubcategories?.map((subcategory) => (
          <ListItem disablePadding key={subcategory.id}>
            <ListItemButton component={Link} href={`/product-category/${subcategory.slug}`} passHref>
              <ListItemText
                primary={
                  <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                    {subcategory.name}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemText primary={<CopyrightText />} />
        </ListItem>
      </List>
    </Stack>
  );
};

export default MobileTopMenuCategory;

MobileTopMenuCategory.propTypes = {
  categoryName: PropTypes.string,
  productSubcategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ),
  handleTopMenuCategoryGoBack: PropTypes.func.isRequired,
  categorySlug: PropTypes.string,
};
