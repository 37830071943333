import { createContext } from 'react';

import { noOp } from 'utils/functions';

const FollowingContext = createContext({
  following: [],
  getFollowing: noOp,
  follow: noOp,
  unfollow: noOp,
});

export default FollowingContext;
