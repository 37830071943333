const FEATURE_FLAGS = Object.freeze({
  IS_WORDPRESS_CONNECTED: 'IS_WORDPRESS_CONNECTED',
  IS_COUPON_ENABLED: 'IS_COUPON_ENABLED',
  ONE_PAGE_SIGNUP: 'ONE_PAGE_SIGNUP',
  SEARCH_RESULTS_ALGOLIA: 'SEARCH_RESULTS_ALGOLIA',
  FACETED_FILTERS_ENABLED: 'FACETED_FILTERS_ENABLED',
  SHOW_PROFIT_MARGIN: 'SHOW_PROFIT_MARGIN',
  CATEGORY_ALGOLIA: 'CATEGORY_ALGOLIA',
  ALGOLIA_BDP: 'ALGOLIA_BDP',
});

export default FEATURE_FLAGS;
