import PropTypes from 'prop-types';

/** MUI */
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

/** Components */
import Link from 'components/Link/Link';
import CustomImage from 'components/CustomImage';
import CopyrightText from 'components/Header/TopMenu/MobileTopMenu/CopyrightText';

/** Consts & Utils */
import { SORT_BY } from 'constants/listing-defaults';

const mobileTopMenuSectionDefaultPropProductCategories = [];
const mobileTopMenuSectionDefaultPropFeaturedBrands = [];
const mobileTopMenuSectionDefaultPropBanners = [];

const MobileTopMenuSection = ({
  banners = mobileTopMenuSectionDefaultPropBanners,
  sectionName = '',
  categorySlug = '',
  featuredBrands = mobileTopMenuSectionDefaultPropFeaturedBrands,
  productCategories = mobileTopMenuSectionDefaultPropProductCategories,
  handleTopMenuSectionClick,
  handleTopMenuSectionGoBack,
}) => {
  return (
    <Stack direction="column" sx={{ height: '100%', overflow: 'hidden' }}>
      <List>
        <ListItem>
          <ListItemIcon>
            <IconButton onClick={handleTopMenuSectionGoBack} aria-label="go back" sx={{ p: 0 }}>
              <ChevronLeftIcon color="primary" />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
            primary={
              <Typography variant="h6" whiteSpace="nowrap">
                {sectionName}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider />
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton disableRipple>
              <ListItemText
                primary={
                  <Typography variant="body1" fontSize="1.25rem">
                    Product Categories
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              href={`/product-category/${categorySlug}?sort-by=${SORT_BY.LATEST}`}
              passHref
            >
              <ListItemText
                primary={
                  <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                    All {sectionName}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
          {productCategories?.map((category) => (
            <ListItem disablePadding key={category.id}>
              <ListItemButton
                onClick={() => handleTopMenuSectionClick(category.name, category.slug, category.subcategories)}
              >
                <ListItemText
                  primary={
                    <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                      {category.name}
                    </Typography>
                  }
                />
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                  <ChevronRightIcon color="primary" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton disableRipple>
              <ListItemText
                primary={
                  <Typography variant="body1" fontSize="1.25rem">
                    Featured Brands
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
          {featuredBrands?.map((wholesaler) => (
            <ListItem disablePadding key={wholesaler.id}>
              <ListItemButton component={Link} href={`/store/${wholesaler.slug}`} passHref>
                <ListItemText
                  primary={
                    <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                      {wholesaler.brand_name}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {banners?.length ? <Divider /> : null}
        <List>
          {banners?.map((banner) => (
            <ListItem disablePadding key={banner.id}>
              <ListItemButton>
                <Box sx={{ width: '100%' }}>
                  <CustomImage
                    src={banner.image.url}
                    width="100%"
                    height="100%"
                    layout="responsive"
                    objectFit="contain"
                  />
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider />

      <List>
        <ListItem>
          <ListItemText primary={<CopyrightText />} />
        </ListItem>
      </List>
    </Stack>
  );
};

export default MobileTopMenuSection;

MobileTopMenuSection.propTypes = {
  sectionName: PropTypes.string,
  productCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      subcategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  featuredBrands: PropTypes.arrayOf(
    PropTypes.shape({
      brand_name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ),
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      }),
      link: PropTypes.shape({}),
    })
  ),
  categorySlug: PropTypes.string,
  handleTopMenuSectionClick: PropTypes.func.isRequired,
  handleTopMenuSectionGoBack: PropTypes.func.isRequired,
};
