import PropTypes from 'prop-types';

import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import { grey } from '@mui/material/colors';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import useResponsive from 'hooks/useResponsive';

const suggestionsListDefaultPropData = [];

const SuggestionsList = ({ loading, data = suggestionsListDefaultPropData, withAvatar = false, onClickHandler }) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');

  return (
    <List sx={{ py: 0 }}>
      {loading
        ? [1, 2, 3].map((i) => (
            <ListItem
              key={`search-suggestions-list-placeholder-${i}`}
              sx={{
                pl: 2,
                [theme.breakpoints.down('sm')]: {
                  pl: 1.5,
                },
              }}
            >
              {withAvatar && (
                <ListItemAvatar
                  sx={{
                    [theme.breakpoints.down('sm')]: {
                      minWidth: 'initial',
                      mr: theme.spacing(0.75),
                    },
                  }}
                >
                  <Skeleton
                    variant="circular"
                    animation="wave"
                    width={isMobile ? 28 : 40}
                    height={isMobile ? 28 : 40}
                  />
                </ListItemAvatar>
              )}
              <Skeleton variant="text" animation="wave" width={isMobile ? '100%' : '70%'} />
            </ListItem>
          ))
        : data.map((item) => (
            <ListItem key={item.slug} disablePadding onClick={() => onClickHandler(item.slug)}>
              <ListItemButton
                sx={{
                  pl: 2,
                  py: '2px',
                  [theme.breakpoints.down('sm')]: {
                    pl: 1.5,
                  },
                }}
              >
                {withAvatar && (
                  <ListItemAvatar
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        minWidth: 'initial',
                        mr: theme.spacing(0.75),
                      },
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: grey[400],
                        [theme.breakpoints.down('sm')]: {
                          width: '1.75rem',
                          height: '1.75rem',
                        },
                      }}
                      src={item.avatarUrl}
                    />
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      className={item.type === 'brands' && 'notranslate'}
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          fontSize: '0.875rem',
                        },
                      }}
                    >
                      {item.name}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
    </List>
  );
};

export default SuggestionsList;

SuggestionsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  withAvatar: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string,
    })
  ),
  onClickHandler: PropTypes.func.isRequired,
};
