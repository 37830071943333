import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Drawer from '@mui/material/Drawer';
import Portal from '@mui/material/Portal';
import CloseIcon from '@mui/icons-material/Close';

import MobileTopMenuHome from 'components/Header/TopMenu/MobileTopMenu/MobileTopMenuHome';
import MobileTopMenuSection from 'components/Header/TopMenu/MobileTopMenu/MobileTopMenuSection';
import MobileTopMenuCategory from 'components/Header/TopMenu/MobileTopMenu/MobileTopMenuCategory';
import MobileTopMenuSandboxSection from 'components/Header/TopMenu/MobileTopMenu/MobileTopMenuSandboxSection';

import useTopMenu from 'hooks/useTopMenu';
import { Router } from 'next/router';

const DRAWER_WIDTH = '85%';

const mobileTopMenuDefaultPropCloseIconRef = { current: {} };

const MobileTopMenu = ({ showTopMenuDrawer, onClose, closeIconRef = mobileTopMenuDefaultPropCloseIconRef }) => {
  const { topMenu, isLoading } = useTopMenu();
  const [showTopMenuSection, setShowTopMenuSection] = useState(false);
  const [showTopMenuCategory, setShowTopMenuCategory] = useState(false);
  const [topMenuSectionData, setTopMenuSectionData] = useState({});
  const [topMenuCategoryData, setTopMenuCategoryData] = useState({});
  const [topMenuSandboxSectionData, setTopMenuSandboxSectionData] = useState({});

  const [lastClick, setLastClick] = useState('');

  const handleClose = useCallback(() => {
    onClose();
    setShowTopMenuSection(false);
    setShowTopMenuCategory(false);
  }, [onClose]);

  const handleTopMenuHomeClick = (sectionName, productCategories, featuredBrands, banners, url) => {
    // extract categorySlug from url like /product-category/beauty
    const categorySlug = url.split('/')[2];
    setShowTopMenuSection(true);
    setLastClick('menu-section');
    setTopMenuSectionData({
      sectionName,
      productCategories,
      categorySlug,
      featuredBrands,
      banners,
    });
  };

  const handleTopMenuSandboxClick = (label, categoriesColumn, productsColumn, wholesalersColumn, bannersColumn) => {
    setShowTopMenuSection(true);
    setLastClick('sandbox');
    setTopMenuSandboxSectionData({
      label,
      categoriesColumn,
      productsColumn,
      wholesalersColumn,
      bannersColumn,
    });
  };

  const handleTopMenuSectionClick = (categoryName, categorySlug, subcategories) => {
    setTopMenuCategoryData({
      categoryName,
      categorySlug,
      productSubcategories: subcategories,
    });
    setShowTopMenuCategory(true);
  };

  const handleTopMenuSectionGoBack = () => {
    setShowTopMenuSection(false);
  };
  const handleTopMenuCategoryGoBack = () => {
    setShowTopMenuCategory(false);
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', handleClose);

    return () => {
      Router.events.off('routeChangeStart', handleClose);
    };
  }, [handleClose]);

  return (
    <>
      <Portal container={closeIconRef.current}>
        <Fade in={showTopMenuDrawer}>
          <Box
            sx={{ position: 'fixed', left: '0', top: '0', zIndex: 1300, width: '15vw', mt: 0.375 }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ fill: 'white', width: '100%', height: '100%' }} />
          </Box>
        </Fade>
      </Portal>
      <Drawer
        anchor="right"
        variant="temporary"
        open={showTopMenuDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          onClose: handleClose,
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, left: 'auto', right: '0' },
        }}
      >
        {/* Top Menu Home  */}
        <MobileTopMenuHome
          topMenu={topMenu}
          handleTopMenuHomeClick={handleTopMenuHomeClick}
          handleTopMenuSandboxClick={handleTopMenuSandboxClick}
          isLoading={isLoading}
        />

        {/* Top Menu Section  */}
        <Drawer
          anchor="right"
          variant="temporary"
          open={showTopMenuSection}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            onClose: handleClose,
            hideBackdrop: true,
            sx: {
              width: DRAWER_WIDTH,
              right: 0,
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, left: 'auto', right: '0' },
            },
          }}
        >
          {lastClick === 'sandbox' ? (
            <MobileTopMenuSandboxSection
              {...topMenuSandboxSectionData}
              handleTopMenuSectionGoBack={handleTopMenuSectionGoBack}
            />
          ) : (
            <MobileTopMenuSection
              {...topMenuSectionData}
              handleTopMenuSectionClick={handleTopMenuSectionClick}
              handleTopMenuSectionGoBack={handleTopMenuSectionGoBack}
            />
          )}
        </Drawer>

        {/* Top Menu Category  */}
        <Drawer
          anchor="right"
          variant="temporary"
          open={showTopMenuCategory}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            onClose: handleClose,
            hideBackdrop: true,
            sx: {
              width: DRAWER_WIDTH,
              right: 0,
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
            },
          }}
        >
          <MobileTopMenuCategory {...topMenuCategoryData} handleTopMenuCategoryGoBack={handleTopMenuCategoryGoBack} />
        </Drawer>
      </Drawer>
    </>
  );
};

export default MobileTopMenu;

MobileTopMenu.propTypes = {
  showTopMenuDrawer: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeIconRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.elementType }),
    PropTypes.shape({ current: PropTypes.shape({}) }),
  ]),
};
