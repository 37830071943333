import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

/* MUI */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

/* Hooks */
import useAuth from 'hooks/useAuth';
import useResponsive from 'hooks/useResponsive';
import useDebounceValue from 'hooks/useDebounceValue';

/** API */
import { previewSearch } from 'api/search';

/** UTILS */
import { RESOLUTION } from 'constants/image-resolution';
import { noOp, transformImageUrl } from 'utils/functions';

/** CMPS */
import useTopBar from 'hooks/useTopBar';
import SuggestionsTitle from './SuggestionsTitle';
import SuggestionsList from './SuggestionsList';
import SuggestionsChips from './SuggestionsChips';

const defaultProps = {
  handleBrandDataUpdate: noOp,
  closeSearchSuggestions: noOp,
  onChipClick: noOp,
  onChipDelete: noOp,
  recentSearches: [],
};

const SearchSuggestions = (props) => {
  const { searchQuery, closeSearchSuggestions, handleBrandDataUpdate, onChipClick, onChipDelete, recentSearches } = {
    ...defaultProps,
    ...props,
  };
  const router = useRouter();
  const { collections } = useTopBar();

  const [result, setResult] = useState({ loading: true, data: {} });
  const [foundResults, setFoundResults] = useState(true);
  const { loading, data } = result;

  const theme = useTheme();
  const { user } = useAuth();
  const isMobile = useResponsive('down', 'sm');
  const debouncedValue = useDebounceValue(searchQuery, 1000);

  const onCategoryClick = (slug) => {
    router.push(`/product-category/${slug}`);
    closeSearchSuggestions();
  };

  const onCollectionClick = (slug) => {
    router.push(`/collections/${slug}`);
    closeSearchSuggestions();
  };

  const onProductClick = (slug) => {
    router.push(`/product/${slug}`);
    closeSearchSuggestions();
  };

  const onBrandClick = (slug) => {
    router.push(`/store/${slug}`);
    closeSearchSuggestions();
  };

  const sortByHighestScoreFirst = (a, b) => a.score >= b.score;

  useEffect(() => {
    setResult({
      loading: true,
      data: '',
    });
    const fetchData = async () => {
      const searchResults = await previewSearch(debouncedValue, { shippingCountryCode: user?.shippingCountry });
      // only showing collections that exist on strapi
      searchResults.collections =
        searchResults?.collections?.filter((cx) =>
          collections?.some((collection) => collection?.slug === cx?.collectionSlug)
        ) || [];

      setResult({
        loading: false,
        data: searchResults,
      });
      handleBrandDataUpdate(searchResults?.brands || []);

      setFoundResults(
        searchResults?.products?.length > 0 ||
          searchResults?.categories?.length > 0 ||
          searchResults?.brands?.length > 0
      );
    };

    fetchData();
  }, [debouncedValue, user?.shippingCountry]);

  return (
    <Stack direction="column" data-testid="search-suggestions" sx={{ maxHeight: '75vh', overflowY: 'auto' }}>
      {!foundResults ? (
        <Typography
          color="error.main"
          variant="h6"
          sx={{
            p: theme.spacing(0, 0.5, 0.5, 1),
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.625rem',
            },
          }}
        >
          Sorry, No matching results
        </Typography>
      ) : (
        <>
          {/* Recent Searches */}
          {recentSearches?.length > 0 && (
            <Box>
              <SuggestionsTitle loading={loading} title="Recent searches" />
              <SuggestionsChips
                loading={loading}
                onClickHandler={onChipClick}
                onClickDeleteHandler={onChipDelete}
                data={recentSearches}
              />
            </Box>
          )}
          {/* Categories */}
          {data?.categories?.length > 0 && (
            <Box>
              <SuggestionsTitle loading={loading} title="Categories" />
              <SuggestionsList
                loading={loading}
                onClickHandler={onCategoryClick}
                data={data?.categories
                  ?.map((x) => ({ name: x.CategoryName, slug: x.categorySlug }))
                  .sort(sortByHighestScoreFirst)}
              />
            </Box>
          )}

          {/* Collections */}
          {data?.collections?.length > 0 && (
            <Box>
              <SuggestionsTitle loading={loading} title="Collections" />
              <SuggestionsList
                loading={loading}
                onClickHandler={onCollectionClick}
                data={data?.collections
                  ?.map((x) => ({ name: x.collectionName, slug: x.collectionSlug }))
                  .sort(sortByHighestScoreFirst)}
              />
            </Box>
          )}

          {/* Brands */}
          {data?.brands?.length > 0 && (
            <Box>
              <SuggestionsTitle loading={loading} title="brands" />
              <SuggestionsList
                loading={loading}
                withAvatar
                onClickHandler={onBrandClick}
                data={data?.brands
                  ?.map((x) => ({
                    type: 'brands',
                    name: x.wholesalerName,
                    slug: x.wholesalerSlug,
                    avatarUrl: transformImageUrl(
                      x.productWholesalerLogoBasePath,
                      x.productWholesalerLogoImage,
                      isMobile ? RESOLUTION.LOGO.MOBILE : RESOLUTION.LOGO.DESKTOP,
                      true
                    ),
                  }))
                  .sort(sortByHighestScoreFirst)}
              />
            </Box>
          )}

          {/* Products */}
          {data?.products?.length > 0 && (
            <Box sx={{ pb: 1 }}>
              <SuggestionsTitle loading={loading} title="products" />
              <SuggestionsList
                loading={loading}
                withAvatar
                onClickHandler={onProductClick}
                data={data?.products
                  ?.map((x) => ({
                    type: 'products',
                    name: x.productName,
                    slug: x.productSlug,
                    avatarUrl: transformImageUrl(
                      x.productThumbnailBaseUrl,
                      x.productThumbnailName,
                      isMobile ? RESOLUTION.LOGO.MOBILE : RESOLUTION.LOGO.DESKTOP,
                      true
                    ),
                  }))
                  .sort(sortByHighestScoreFirst)}
              />
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};

export default SearchSuggestions;
