import PropTypes from 'prop-types';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import BackdropContext from 'context/BackdropContext';
import { useCallback, useMemo, useState } from 'react';

const BackdropProvider = ({ children }) => {
  const [showBackdrop, setShowBackdrop] = useState(false);

  const handleShowBackdrop = useCallback(() => {
    setShowBackdrop(true);
  }, []);

  const handleHideBackdrop = useCallback(() => {
    setShowBackdrop(false);
  }, []);

  const state = useMemo(
    () => ({
      handleShowBackdrop,
      handleHideBackdrop,
    }),
    [handleShowBackdrop, handleHideBackdrop]
  );

  return (
    <BackdropContext.Provider value={state}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (currTheme) => currTheme.zIndex.drawer + 1 }}
        open={showBackdrop}
        unmountOnExit
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  );
};

export default BackdropProvider;

BackdropProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
