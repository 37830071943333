import bffAPI from 'services/api/bff';

export async function previewSearch(searchQuery, { shippingCountryCode } = {}) {
  const query = new URLSearchParams({
    type: 'preview',
    ...(shippingCountryCode && { shippingCountryCode }),
  });

  const params = query.toString() ? `&${decodeURIComponent(query.toString())}` : '';
  return bffAPI.get(`/search?q=${searchQuery}${params}`).then(({ data }) => data);
}

export async function companyHouseSearch(searchQuery) {
  const response = bffAPI.get(`/search?q=${searchQuery}&type=companySearch`).then(({ data }) => data);

  return response;
}

export async function getMultipleProductsByIds(productIds) {
  return bffAPI.post(`/search/search`, { type: 'product', ids: productIds }).then(({ data }) => data);
}

export async function getMultipleBrandsByIds(brandIds) {
  return bffAPI.post(`/search/search`, { type: 'brand', ids: brandIds }).then(({ data }) => data);
}
