import { WORDPRESS_URL } from 'constants/runtimeConfig';

const WORDPRESS_BASE_URL = WORDPRESS_URL;

const WP_ROUTES = Object.freeze({
  register: `${WORDPRESS_BASE_URL}/signup/retailer/`,
  cart: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=cart`,
  checkout: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=checkout`,
  registerWholesaler: `${WORDPRESS_BASE_URL}/signup/wholesaler/`,
  forYou: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=for-you`,
  account: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=my-account`,
  wishlist: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=wishlist`,
  brandFollowed: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=my-account/following`,
  tradeAccount: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=my-account/trade-account`,
  order: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=my-account/orders`,
  orderDetails: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=my-account/view-order/%s`,
  logout: `${WORDPRESS_BASE_URL}/my-account/customer-logout`,
  paymentAndInvoices: `${WORDPRESS_BASE_URL}/virgo-redirect?endpoint=my-account/payments-and-invoices`,
});

export default WP_ROUTES;
