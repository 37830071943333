import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

import { MAX_BRANDS_VISIT_ALLOWED } from 'constants';

import BrandsVisitedContext from 'context/BrandsVisitedContext';

const BrandsVisitedProvider = ({ children }) => {
  const [brandsVisited, setBrandsVisitedLocalStorage] = useLocalStorage('brandsVisited', []);

  const setBrandsVisited = useCallback(
    (brandId) => {
      if (brandsVisited.includes(brandId)) return;
      setBrandsVisitedLocalStorage([...brandsVisited, brandId].slice(-3)); // only the last 3
    },
    [setBrandsVisitedLocalStorage, brandsVisited]
  );

  const state = useMemo(
    () => ({
      brandsVisited,
      setBrandsVisited,
      showSignupPopup: brandsVisited?.length >= MAX_BRANDS_VISIT_ALLOWED,
    }),
    [brandsVisited, setBrandsVisited]
  );

  return <BrandsVisitedContext.Provider value={state}>{children}</BrandsVisitedContext.Provider>;
};

BrandsVisitedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BrandsVisitedProvider;
