export const FALLBACK_TOP_BAR = {
  content: 'Hundreds of new products added each week. [Shop new arrivals](https://www.creoate.com/new-in/)',
};

export const FALLBACK_TOP_MENU = [
  {
    __component: 'ui.edit',
    id: 6,
    availableForAnonymousUser: true,
    link: {
      id: 69,
      label: 'New In',
      url: '/new-in',
    },
  },
  {
    __component: 'ui.edit',
    id: 19,
    availableForAnonymousUser: true,
    link: {
      id: 68,
      label: 'Home',
      url: '/product-category/home-garden',
    },
  },
  {
    __component: 'ui.edit',
    id: 21,
    availableForAnonymousUser: true,
    link: {
      id: 77,
      label: 'Beauty & Wellbeing',
      url: '/product-category/beauty',
    },
  },
  {
    __component: 'ui.edit',
    id: 20,
    availableForAnonymousUser: true,
    link: {
      id: 73,
      label: 'Fashion & Jewellery',
      url: '/product-category/accessories',
    },
  },
  {
    __component: 'ui.edit',
    id: 22,
    availableForAnonymousUser: true,
    link: {
      id: 81,
      label: 'Stationery & Gifts',
      url: '/product-category/stationery',
    },
  },
  {
    __component: 'ui.edit',
    id: 24,
    availableForAnonymousUser: true,
    link: {
      id: 88,
      label: 'Pets',
      url: '/product-category/pet-supplies',
    },
  },
  {
    __component: 'ui.edit',
    id: 23,
    availableForAnonymousUser: true,
    link: {
      id: 84,
      label: 'Kids',
      url: '/product-category/kids',
    },
  },
  {
    __component: 'ui.edit',
    id: 25,
    availableForAnonymousUser: true,
    link: {
      id: 92,
      label: 'Food & Drink',
      url: '/product-category/gourmet-food',
    },
  },
];
