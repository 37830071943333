import PropTypes from 'prop-types';

/** MUI */
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

/** Components */
import Link from 'components/Link/Link';
import CustomImage from 'components/CustomImage';
import CopyrightText from 'components/Header/TopMenu/MobileTopMenu/CopyrightText';

const mobileTopMenuSandboxSectionDefaultPropCategoriesColumn = {};
const mobileTopMenuSandboxSectionDefaultPropProductsColumn = {};
const mobileTopMenuSandboxSectionDefaultPropWholesalersColumn = {};
const mobileTopMenuSandboxSectionDefaultPropBannersColumn = {};

const MobileTopMenuSandboxSection = ({
  label,
  categoriesColumn = mobileTopMenuSandboxSectionDefaultPropCategoriesColumn,
  productsColumn = mobileTopMenuSandboxSectionDefaultPropProductsColumn,
  wholesalersColumn = mobileTopMenuSandboxSectionDefaultPropWholesalersColumn,
  bannersColumn = mobileTopMenuSandboxSectionDefaultPropBannersColumn,
  handleTopMenuSectionGoBack,
}) => {
  return (
    <Stack direction="column" sx={{ height: '100%', overflow: 'hidden' }}>
      <List>
        <ListItem>
          <ListItemIcon>
            <IconButton onClick={handleTopMenuSectionGoBack} aria-label="go back" sx={{ p: 0 }}>
              <ChevronLeftIcon color="primary" />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
            primary={
              <Typography variant="h6" whiteSpace="nowrap">
                {label}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider />
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {categoriesColumn?.id && (
          <List>
            <ListItem disablePadding>
              <ListItemButton disableRipple>
                <ListItemText
                  primary={
                    <Typography variant="body1" fontSize="1.25rem">
                      {categoriesColumn.Title}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            {categoriesColumn.categories.map((category) => (
              <ListItem disablePadding key={category.id}>
                <ListItemButton component={Link} href={`/product-category/${category.slug}`} passHref>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                        {category.name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
            {categoriesColumn.collections.map((collection) => (
              <ListItem disablePadding key={collection.id}>
                <ListItemButton component={Link} href={`/collections/${collection.slug}`} passHref>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                        {collection.name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
            {categoriesColumn.links.map((link) => (
              <ListItem disablePadding key={link.id}>
                <ListItemButton component={Link} href={link.url} passHref>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                        {link.label}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        {productsColumn?.id && (
          <>
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton disableRipple>
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontSize="1.25rem">
                        {productsColumn.Title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              {productsColumn.products.map((product) => (
                <ListItem disablePadding key={product.id}>
                  <ListItemButton component={Link} href={`/product/${product.slug}`} passHref>
                    <ListItemText
                      primary={
                        <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                          {product.name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              {productsColumn.links.map((link) => (
                <ListItem disablePadding key={link.id}>
                  <ListItemButton component={Link} href={link.url} passHref>
                    <ListItemText
                      primary={
                        <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                          {link.label}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {wholesalersColumn?.id && (
          <>
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton disableRipple>
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontSize="1.25rem">
                        {wholesalersColumn.Title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              {wholesalersColumn.wholesalers.map((wholesaler) => (
                <ListItem disablePadding key={wholesaler.id}>
                  <ListItemButton component={Link} href={`/store/${wholesaler.slug}`} passHref>
                    <ListItemText
                      primary={
                        <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                          {wholesaler.brand_name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}

              {wholesalersColumn.links.map((link) => (
                <ListItem disablePadding key={link.id}>
                  <ListItemButton component={Link} href={link.url} passHref>
                    <ListItemText
                      primary={
                        <Typography variant="h6" fontSize="0.875rem" textTransform="uppercase">
                          {link.label}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {bannersColumn?.id && (
          <>
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton disableRipple>
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontSize="1.25rem">
                        {bannersColumn.Title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              {bannersColumn.banner.Mobile.map((banner) => (
                <ListItem disablePadding key={banner.Image.url}>
                  <Box sx={{ width: '100%' }}>
                    <Link href={banner.Link} passHref key={banner.id}>
                      <CustomImage
                        alt={banner.Link}
                        src={banner.Image.url}
                        width={banner.Image.width}
                        height={banner.Image.height}
                        objectFit="contain"
                      />
                    </Link>
                  </Box>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
      <Divider />
      <List>
        <ListItem>
          <ListItemText primary={<CopyrightText />} />
        </ListItem>
      </List>
    </Stack>
  );
};

export default MobileTopMenuSandboxSection;

MobileTopMenuSandboxSection.propTypes = {
  label: PropTypes.string.isRequired,
  categoriesColumn: PropTypes.shape({
    id: PropTypes.number.isRequired,
    Title: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
    collections: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  productsColumn: PropTypes.shape({
    id: PropTypes.number.isRequired,
    Title: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  wholesalersColumn: PropTypes.shape({
    id: PropTypes.number.isRequired,
    Title: PropTypes.string,
    wholesalers: PropTypes.arrayOf(
      PropTypes.shape({
        brand_name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  bannersColumn: PropTypes.shape({
    id: PropTypes.number.isRequired,
    Title: PropTypes.string,
    banner: PropTypes.shape({
      Type: PropTypes.oneOf(['TopMenu', 'Collection']),
      Mobile: PropTypes.arrayOf(
        PropTypes.shape({
          Link: PropTypes.string.isRequired,
          Image: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        })
      ),
    }),
  }),
  handleTopMenuSectionGoBack: PropTypes.func.isRequired,
};
