import { createContext } from 'react';

import { noOp } from 'utils/functions';

const AddressContext = createContext({
  billingAddress: null,
  shippingAddress: null,
  saveBillingAddress: noOp,
  saveShippingAddress: noOp,
});

export default AddressContext;
