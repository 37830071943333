import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';

import * as configsAPI from 'api/configs';

import { log } from 'utils/functions';

import ConfigsContext from 'context/ConfigsContext';
import { FEATURE_FLAGS_COOKIE_NAME } from 'constants';
import { COOKIE_OPTIONS } from 'utils/cookies';

const ConfigsProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies([FEATURE_FLAGS_COOKIE_NAME]);
  const configs = useMemo(() => cookies[FEATURE_FLAGS_COOKIE_NAME] || [], [cookies]);

  const setConfigs = useCallback(
    async (flags) => {
      setCookie(FEATURE_FLAGS_COOKIE_NAME, flags, COOKIE_OPTIONS);
      return true;
    },
    [setCookie]
  );

  const getConfigs = useCallback(async () => {
    try {
      const flags = await configsAPI.getConfigs();
      await setConfigs(flags);
      return flags;
    } catch (e) {
      log.error('Error on getConfigs. Error: ', e);
      return Promise.reject(e);
    }
  }, [setConfigs]);

  const removeConfigs = useCallback(() => {
    setCookie(FEATURE_FLAGS_COOKIE_NAME, [], COOKIE_OPTIONS);
  }, [setCookie]);

  // Get configs when the application is first loaded (or refreshed)
  useEffect(() => {
    getConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = useMemo(() => ({ configs, getConfigs, removeConfigs }), [configs, getConfigs, removeConfigs]);

  return <ConfigsContext.Provider value={state}>{children}</ConfigsContext.Provider>;
};

ConfigsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConfigsProvider;
