import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

import useResponsive from 'hooks/useResponsive';
import { Box } from '@mui/material';

const suggestionsChipsDefaultPropData = [];
const suggestionsChipsDefaultPropOnClickDeleteHandler = () => {};

const SuggestionsChips = ({
  loading,
  data = suggestionsChipsDefaultPropData,
  onClickDeleteHandler = suggestionsChipsDefaultPropOnClickDeleteHandler,
  onClickHandler,
}) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box sx={{ py: 0 }} data-testid="suggestions-chips-box">
      {loading ? (
        <Box
          sx={{
            pl: 2,
            [theme.breakpoints.down('sm')]: {
              pl: 1.5,
            },
          }}
          display="flex"
          data-testid="suggestions-chips-box-loading"
        >
          {[1, 2, 3].map((i) => (
            <Skeleton
              key={`loading-${i}`}
              variant="text"
              animation="wave"
              width={isMobile ? '40%' : '15%'}
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            pl: 2,
            [theme.breakpoints.down('sm')]: {
              pl: 1.5,
            },
          }}
          data-testid="suggestions-chips-box-items"
        >
          {data.map((item) => (
            <Chip
              key={`item-${item}`}
              label={item}
              onClick={() => onClickHandler(item)}
              onDelete={() => onClickDeleteHandler(item)}
              sx={{ mr: 0.5, mb: 0.5 }}
              variant="outlined"
              data-testid={`suggestion-chip-${item}`}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default SuggestionsChips;
SuggestionsChips.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.string),
  onClickHandler: PropTypes.func.isRequired,
  onClickDeleteHandler: PropTypes.func,
};
