import PropTypes from 'prop-types';

// mui
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

// components
import SecondaryFooter from 'components/common/layouts/SecondaryFooter';
import SecondaryHeader from 'components/common/layouts/SecondaryHeader';

const secondaryLayoutDefaultPropContainerProps = {};
const secondaryLayoutDefaultPropLayoutProps = {};

const SecondaryLayout = ({
  children,
  containerProps = secondaryLayoutDefaultPropContainerProps,
  layoutProps = secondaryLayoutDefaultPropLayoutProps,
}) => {
  const { header, content, footer } = layoutProps || {};

  const theme = useTheme();

  return (
    <>
      {/* AppBar */}
      {!header?.hide && <SecondaryHeader />}
      {/* Content */}
      {!content?.hide && (
        <Container
          disableGutters={content?.noPadding}
          maxWidth={!content?.fullWidth && 'xl'}
          sx={{
            pt: content?.noPadding ? 0 : 2,
            [theme.breakpoints.down('md')]: {
              pt: 'initial',
              px: 1,
            },
          }}
          {...containerProps}
        >
          {children}
        </Container>
      )}
      {/* Footer */}
      {!footer?.hide && <SecondaryFooter />}
    </>
  );
};

SecondaryLayout.propTypes = {
  children: PropTypes.node.isRequired,
  containerProps: PropTypes.objectOf(PropTypes.any),
  layoutProps: PropTypes.shape({
    header: PropTypes.shape({
      hide: PropTypes.bool,
      hideTopBar: PropTypes.bool,
      hideAppBar: PropTypes.bool,
      hideTopMenu: PropTypes.bool,
    }),
    content: PropTypes.shape({
      hide: PropTypes.bool,
      fullWidth: PropTypes.bool,
      noPadding: PropTypes.bool,
    }),
    footer: PropTypes.shape({
      hide: PropTypes.bool,
    }),
  }),
};

export default SecondaryLayout;
