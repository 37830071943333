import apiFactory from 'services/api/axios';
import token from 'services/api/token';

export const SyncCookieEndpointPath = '/retailer/cookie';

export const syncUserCookieAcceptance = () => {
  const fetcher = apiFactory({
    baseURL: window.location.origin,
  });

  return fetcher.post('/api/cookie/sync', {
    jwt: token.get(),
  });
};
