import { createContext } from 'react';

const TopBarContext = createContext({
  topBar: {
    content: '',
  },
  brandFlairs: [],
  productFlairs: [],
  brandFlairsIdentifier: [],
});

export default TopBarContext;
