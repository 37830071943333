import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

/** MUI */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Portal from '@mui/material/Portal';
import { styled } from '@mui/material/styles';

/** Components */
import Link from 'components/Link/Link';
import CustomImage from 'components/CustomImage';

/** Hooks */
import useAuth from 'hooks/useAuth';
import useTopMenu from 'hooks/useTopMenu';

/** Consts & Utils */
import { SORT_BY } from 'constants/listing-defaults';

/** Icons */
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TopMenuArea from 'components/Header/TopMenu/TopMenuArea/TopMenuArea';

/** Blocks */
import PortalTopMenu from 'blocks/PortalTopMenu';

const topMenuSectionDefaultPropCategories = {
  name: '',
  subcategories: [],
};

const topMenuSectionDefaultPropSx = {};

const TopMenuSection = ({
  id,
  label,
  url,
  banners,
  categories = topMenuSectionDefaultPropCategories,
  wholesalers,
  sx = topMenuSectionDefaultPropSx,
  onMouseEnter,
  onCategoriesButtonClick,
  isHighlighted = false,
  ...rest
}) => {
  const { isAuthenticated } = useAuth();
  const { target, selectedMenuSection, isItemHovered } = useTopMenu();

  const [selectedCategory, setSelectedCategory] = useState(0);

  const handleCategoriesButtonHover = useCallback((index) => {
    setSelectedCategory((prev) => (prev === index ? prev : index));
  }, []);

  const handleCategoriesButtonClick = () => {
    setTimeout(() => onCategoriesButtonClick(), 750);
  };

  const topMenuAreaBundle = {
    id,
    sx,
    url,
    label,
    onMouseEnter,
    isHighlighted,
    ...rest,
  };

  const Item = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '398px',
    width: '281px',
    paddingBottom: '-1rem',
    '@media screen and (max-width: 1024px)': {
      width: '207.75px',
      height: '340px',
    },
  }));

  return (
    <TopMenuArea {...topMenuAreaBundle}>
      <Portal container={target.current}>
        {selectedMenuSection.id === id && selectedMenuSection.label === label && isItemHovered && (
          <PortalTopMenu onMouseEnter={onMouseEnter}>
            <Grid
              container
              direction="row"
              display="flex"
              height="100%"
              justifyContent="space-evently"
              columnSpacing={{ md: 1, lg: 2 }}
            >
              {/* Products Column  */}
              <Grid xs={12} md={3} item>
                <PortalTopMenu.Header component="p" variant="h6">
                  View by Product
                </PortalTopMenu.Header>
                <Box>
                  {categories?.map((category, index) => (
                    <PortalTopMenu.Button
                      component={Link}
                      href={`/product-category/${category.slug}`}
                      passHref
                      fullWidth
                      key={`${category.slug}`}
                      sx={{ backgroundColor: selectedCategory === index ? 'grey.300' : '' }}
                      onMouseEnter={() => handleCategoriesButtonHover(index)}
                      onClick={handleCategoriesButtonClick}
                    >
                      <PortalTopMenu.ButtonText variant="body2">{category.name}</PortalTopMenu.ButtonText>
                      {selectedCategory === index ? <NavigateNextIcon /> : ''}
                    </PortalTopMenu.Button>
                  ))}
                </Box>
              </Grid>
              {/* Sub-category Column  */}
              <Grid xs={12} md={3} item>
                <PortalTopMenu.Header variant="h6">{categories[selectedCategory].name}</PortalTopMenu.Header>
                <Box data-testid="top-section__category--section-wrapper">
                  <PortalTopMenu.Button
                    component={Link}
                    href={`/product-category/${categories[selectedCategory]?.slug}?sort-by=${SORT_BY.LATEST}`}
                    passHref
                    fullWidth
                    data-testid="top-section__category--new-button"
                    onClick={handleCategoriesButtonClick}
                  >
                    <PortalTopMenu.ButtonText variant="body2">
                      All {categories[selectedCategory]?.name}
                    </PortalTopMenu.ButtonText>
                  </PortalTopMenu.Button>
                  {categories[selectedCategory]?.subcategories?.length > 8 && (
                    <PortalTopMenu.Button
                      component={Link}
                      href={`/product-category/${categories[selectedCategory]?.slug}`}
                      passHref
                      fullWidth
                      data-testid="top-section__category--shop-all-button"
                    >
                      <PortalTopMenu.ButtonText variant="body2">Shop All</PortalTopMenu.ButtonText>
                    </PortalTopMenu.Button>
                  )}

                  {'subcategories' in categories[selectedCategory]
                    ? categories[selectedCategory].subcategories.slice(0, 8).map((subcategory) => (
                        <PortalTopMenu.Button
                          component={Link}
                          href={`/product-category/${subcategory.slug}`}
                          passHref
                          fullWidth
                          key={subcategory.slug}
                          data-testid="top-section__category--button"
                          onClick={handleCategoriesButtonClick}
                        >
                          <PortalTopMenu.ButtonText variant="body2">{subcategory.name}</PortalTopMenu.ButtonText>
                        </PortalTopMenu.Button>
                      ))
                    : null}
                </Box>
              </Grid>
              {/* Wholesalers Column  */}
              <Grid xs={12} md={3} item>
                {isAuthenticated && wholesalers.length ? (
                  <>
                    <PortalTopMenu.Header variant="h6">Featured Brands</PortalTopMenu.Header>
                    <Box data-testid="top-section__wholesaler--section-wrapper">
                      {wholesalers.map((wholesaler) => (
                        <PortalTopMenu.Button
                          component={Link}
                          href={`/store/${wholesaler.slug}`}
                          passHref
                          fullWidth
                          className="notranslate"
                          key={wholesaler.brand_name}
                          data-testid="top-section__wholesaler--button"
                          onClick={handleCategoriesButtonClick}
                        >
                          <PortalTopMenu.ButtonText variant="body2">{wholesaler.brand_name}</PortalTopMenu.ButtonText>
                        </PortalTopMenu.Button>
                      ))}
                    </Box>
                  </>
                ) : null}
              </Grid>
              {/* Banners Column  */}
              <Grid xs={12} md={3} item>
                {banners.length ? (
                  <Item>
                    {banners.map((banner) => (
                      <Link href={banner.link.url} passHref key={banner.id} onClick={handleCategoriesButtonClick}>
                        <CustomImage
                          alt={banner.link.label}
                          src={banner.image.url}
                          width={banner.image.width}
                          height={banner.image.height}
                          objectFit="contain"
                        />
                      </Link>
                    ))}
                  </Item>
                ) : null}
              </Grid>
            </Grid>
          </PortalTopMenu>
        )}
      </Portal>
    </TopMenuArea>
  );
};

TopMenuSection.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      subcategories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        }).isRequired
      ),
    })
  ),
  wholesalers: PropTypes.arrayOf(
    PropTypes.shape({
      brand_name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  isHighlighted: PropTypes.bool,
  sx: PropTypes.shape({}),
  onMouseEnter: PropTypes.func.isRequired,
  onCategoriesButtonClick: PropTypes.func.isRequired,
};

export default TopMenuSection;
