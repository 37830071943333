/* eslint-disable no-param-reassign */

/**
 * Dumps the indicated storage data into an object
 * @param {'local' | 'session'} storageType The type of storage to dump (`localStorage` or `sessionStorage`)
 * @returns {object} The storage data
 */
const dumpStorageData = (storageType) =>
  Object.keys(storageType === 'local' ? localStorage : sessionStorage).reduce((acc, key) => {
    acc[key] = localStorage.getItem(key);
    return acc;
  }, {});

/**
 * Dumps the Local Storage's data into an object
 * @returns {object} The local storage's data
 */
export const dumpLocalStorageData = () => dumpStorageData('local');
/**
 * Dumps the Session Storage's data into an object
 * @returns {object} The session storage's data
 */
export const dumpSessionStorageData = () => dumpStorageData('session');
