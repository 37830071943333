// ----------------------------------------------------------------------
import { NEXT_PUBLIC_WHOLESALER_SITE_URL } from 'constants/runtimeConfig';

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_HOMEPAGE = '/';
const ROOTS_STORES = '/store';
const ROOTS_CATEGORIES = '/product-category';
const ROOTS_COLLECTIONS = '/collections';
const WHOLESALER_SITE_URL = NEXT_PUBLIC_WHOLESALER_SITE_URL;

// ----------------------------------------------------------------------ƒ

export default Object.freeze({
  page: {
    forYou: '/for-you',
    blog: '/blog',
    cart: '/cart',
    about: '/about',
    delivery: '/delivery',
    wishlist: '/wishlist',
    root: ROOTS_HOMEPAGE,
    joinUs: '/join-as-a-brand',
    privacy: '/privacy-policy',
    returns: '/returns-policy',
    brandFollowed: '/following',
    shippingRestricted: '/shipping-restricted',
    termsService: '/terms-and-conditions',
    creditTerms: '/payment-credit-terms',
    sellOnCreoate: `${WHOLESALER_SITE_URL}/signup`,
    thankYou: '/thank-you',
    pressReleases1:
      'https://techcrunch.com/2021/08/03/independent-retailer-platform-creoate-raises-5m-seed-led-by-fuel-ventures/',
    pressReleases2:
      'https://www.uktech.news/news/this-aspiring-female-founder-raised-35m-from-mark-pearsons-fuel-ventures-for-her-b2b-wholesale-marketplace-20210803',
    pressReleases3:
      'https://www.eu-startups.com/2021/08/london-based-wholesale-marketplace-creoate-nabs-e4-2-million-to-support-independent-retailers/',
  },
  auth: {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    logout: path(ROOTS_AUTH, '/logout'),
    verify: path(ROOTS_AUTH, '/verify'),
    register: path(ROOTS_AUTH, '/register'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  },
  social: {
    twitter: 'https://twitter.com/creoateuk',
    facebook: 'https://www.facebook.com/creoate',
    instagram: 'https://www.instagram.com/creoate',
    linkedin: 'https://www.linkedin.com/company/creoate',
  },
  footer: {
    blog: 'https://www.creoate.com/blog/',
    about: '/about',
    sitemap: '/map',
    delivery: '/delivery',
    joinUs: '/join-as-a-brand',
    privacy: '/privacy-policy',
    returns: '/returns-policy',
    termsService: '/terms-and-conditions',
    creditTerms: '/payment-credit-terms',
    mugs: path(ROOTS_CATEGORIES, '/mugs'),
    gifts: path(ROOTS_CATEGORIES, '/gifts'),
    joinOurTeam: 'https://creoate.breezy.hr',
    twitter: 'https://twitter.com/creoateuk',
    homeware: path(ROOTS_CATEGORIES, '/home'),
    beauty: path(ROOTS_CATEGORIES, '/beauty'),
    candles: path(ROOTS_CATEGORIES, '/candles'),
    discover: '/discover',
    collections: '/collections',
    categories: '/categories',
    facebook: 'https://www.facebook.com/creoate',
    contactUs: '/contact',
    helpCenter: 'https://helpcenter.creoate.com/',
    buyerHelpCenter: 'https://helpcenter.creoate.com/im-a-buyer',
    sellerHelpCenter: 'https://helpcenter.creoate.com/im-a-buyer',
    instagram: 'https://www.instagram.com/creoate',
    jewellery: path(ROOTS_CATEGORIES, '/jewellery'),
    stationery: path(ROOTS_CATEGORIES, '/stationery'),
    birthday: path(ROOTS_CATEGORIES, '/birthday-cards'),
    waxMelts: path(ROOTS_CATEGORIES, '/wax-melts'),
    linkedin: 'https://www.linkedin.com/company/creoate',
    pinterest: 'https://www.pinterest.co.uk/creoate/',
    WhatIsWholesale: 'https://www.creoate.com/blog/what-is-wholesale',
    BestMarketplaces: 'https://www.creoate.com/blog/best-wholesale-marketplaces',
    SellerAcademy: 'https://wholesale.creoate.com/seller-academy-signup',
    tradeDirect: 'https://wholesale.creoate.com/trade-direct-creoate-0',
    affiliates: 'https://wholesale.creoate.com/affiliate',
  },
  cart: {
    changeAddress: 'https://helpcenter.creoate.com/can-i-change-the-shipping-address',
  },
  poduct_categories: {
    womenClothing: path(ROOTS_CATEGORIES, '/women-clothing'),
    petSupplies: path(ROOTS_CATEGORIES, '/pet-supplies'),
    babyToddler: path(ROOTS_CATEGORIES, '/baby-and-toddler'),
    bathBody: path(ROOTS_CATEGORIES, '/bath-and-body'),
    jewellery: path(ROOTS_CATEGORIES, '/jewellery'),
    homeFragances: path(ROOTS_CATEGORIES, '/scented-candles-and-fragrance'),
    healthWellbeing: path(ROOTS_CATEGORIES, '/health-wellbeing'),
    hangingDecorations: path(ROOTS_CATEGORIES, '/hanging-decorations'),
    chocolateSweet: path(ROOTS_CATEGORIES, '/chocolate-sweets'),
    greetingCards: path(ROOTS_CATEGORIES, '/greeting-cards'),
  },
  collections: {
    giftsUnder10: path(ROOTS_COLLECTIONS, '/gifts-under-10'),
    sustainableStockingFillers: path(ROOTS_COLLECTIONS, '/sustainable-stocking--fillers'),
    spiritual: path(ROOTS_COLLECTIONS, '/spiritual'),
    outdoorLining: path(ROOTS_COLLECTIONS, '/outdoor-living'),
    quirkyGifts: path(ROOTS_COLLECTIONS, '/quirky-Gifts'),
    sustainableHome: path(ROOTS_COLLECTIONS, '/sustainable-home'),
    coastalLiving: path(ROOTS_COLLECTIONS, '/coastal-living'),
    workFromHome: path(ROOTS_COLLECTIONS, '/working-from-home'),
    zeroWaste: path(ROOTS_COLLECTIONS, '/zero-waste'),
    veganSweets: path(ROOTS_COLLECTIONS, '/vegan-sweets'),
  },
  stores: {
    global1st: path(ROOTS_STORES, '/global-1st'),
    myga: path(ROOTS_STORES, '/myga'),
    upcircleBeauty: path(ROOTS_STORES, '/upcircle-beauty '),
    umaCantik: path(ROOTS_STORES, '/uma-cantik'),
    ecoEgg: path(ROOTS_STORES, '/ecoegg'),
    graceGreen: path(ROOTS_STORES, '/grace-and-green'),
  },
});
