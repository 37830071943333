const currencies = [
  {
    name: 'GBP',
    symbol: '£',
  },
  {
    name: 'USD',
    symbol: '$',
  },
  {
    name: 'EUR',
    symbol: '€',
  },
];

export default currencies;
