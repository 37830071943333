import bffAPI from 'services/api/bff';

export async function getFollowing() {
  return bffAPI.get(`/users/following`).then(({ data }) => data);
}

export async function follow(brandId) {
  return bffAPI.post(`/users/following/${brandId}/follow`).then(({ data }) => data);
}

export async function unfollow(brandId) {
  return bffAPI.post(`/users/following/${brandId}/unfollow`).then(({ data }) => data);
}
