import { createContext } from 'react';

import { noOp } from 'utils/functions';

const AuthContext = createContext({
  jwt: '',
  user: null,
  userId: null, // this will eventually disappear in favor of retailerId
  retailerId: null,
  login: noOp,
  logout: noOp,
  resetPassword: noOp,
  isAuthenticated: false,
  userCredit: null,
  getUserAndUpdateGTM: noOp,
  fetchAndUpdateCreditAndOverdue: noOp,
  cleanUpCreditAndOverdue: noOp,
});

export default AuthContext;
