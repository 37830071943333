/*
 * Open retailer-site as project for typescript type checking and autocomplete
 * See more about available theme options: https://mui.com/material-ui/customization/default-theme/
 */
/**
 * @type {import('@mui/material').ThemeOptions} ThemeOptions
 */
const themeConfig = {
  palette: {
    mode: 'light',
    grey: {
      500: '#919eab',
      600: '#637381',
      700: '#454F5B',
      800: '#212b36',
    },
    primary: {
      main: '#000000',
    },
    secondary: {
      light: '#40A495',
      main: '#338377',
      dark: '#29695F',
    },
    error: {
      main: '#EA4335',
    },
    background: {
      default: '#FFFFFF',
    },
    warning: {
      main: '#FBBC04',
    },
    success: {
      main: '#34A853',
      light: '#54d62c',
    },
    info: {
      main: '#4285F4',
      light: '#1890ff',
    },
  },
  typography: {
    fontFamily: '"Inter"',
  },
  shape: {
    borderRadius: 4,
  },
  spacing: (factor) => `${factor}rem`,
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: '#000',
          backgroundColor: '#FFF',
        },
      },
      defaultProps: {
        enableColorOnDark: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        head: {
          color: '#637381',
          backgroundColor: '#F4F6F8',
          '&:first-of-type': {
            paddingLeft: `3rem`,
            borderTopLeftRadius: `0.5rem`,
            borderBottomLeftRadius: `0.5rem`,
            boxShadow: `inset 8px 0 0 ${'#fff'}`,
          },
          '&:last-of-type': {
            paddingRight: `3rem`,
            borderTopRightRadius: `0.5rem`,
            borderBottomRightRadius: `0.5rem`,
            boxShadow: `inset -8px 0 0 ${'#fff'}`,
          },
        },
        stickyHeader: {
          backgroundColor: '#fff',
          backgroundImage: `linear-gradient(to bottom, ${'#F4F6F8'} 0%, ${'#F4F6F8'} 100%)`,
        },
        body: {
          '&:first-of-type': {
            paddingLeft: `3rem`,
          },
          '&:last-of-type': {
            paddingRight: `3rem`,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            borderRadius: 8,
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: '2rem',
          paddingRight: '1rem',
          '&.Mui-disabled': {
            opacity: 1,
            color: '#919EAB',
            '& .MuiTypography-root': {
              color: 'inherit',
            },
          },
        },
        expandIconWrapper: {
          color: 'inherit',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
  },
};

export default themeConfig;
