import { useContext } from 'react';
import ReferralsContext from 'context/ReferralsContext';

const useReferrals = () => {
  const context = useContext(ReferralsContext);

  if (!context) {
    throw new Error('useReferrals must be used within a ReferralsProvider');
  }

  return context;
};

export default useReferrals;
