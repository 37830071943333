import { NEXT_PUBLIC_ENV } from './runtimeConfig';

const productionFlairs = {
  brandFlairs: [
    {
      code: 'deliverysuccessrate',
      flairTitle: 'SUPER SHIPPER',
      flairColorBackground: '#34a853',
      flairColorText: '#ffffff',
      flairType: 'dynamic',
      flairTarget: 'brand',
      flairId: '2ccb40c3-5f55-49c9-9c34-53daeb3df959',
    },
    {
      code: 'creoaterecommends',
      flairTitle: 'Creoate Recommends',
      flairColorBackground: '#6b8f82',
      flairColorText: '#ffffff',
      flairType: 'static',
      tooltip: '',
      flairTarget: 'brand',
      flairId: '4f3ed0ab-8e57-4d5c-8be9-fecdb5e3d781',
    },
    {
      code: 'activeseller',
      flairTitle: 'Active Seller',
      flairColorBackground: '#627afa',
      flairColorText: '#fff9c1',
      flairType: 'static',
      tooltip: '',
      flairTarget: 'brand',
      flairId: '8e34e58b-78bd-491d-8b28-bf3b50158825',
    },
    {
      code: 'highsellthrough',
      flairTitle: 'High Sell Through',
      flairColorBackground: '#fff7f1',
      flairColorText: '#e44da8',
      flairType: 'static',
      tooltip: '',
      flairTarget: 'brand',
      flairId: 'd134c289-93d9-444f-8764-5aa3c3a0f452',
    },
  ],
  productFlairs: [
    {
      code: 'highmargin',
      flairTitle: 'HIGH MARGIN',
      flairColorBackground: '#e44da8',
      flairColorText: '#ffffff',
      flairType: 'dynamic',
      flairTarget: 'product',
      flairId: 'c42d53ec-9437-4c88-b556-ec2e23f1770d',
    },
    {
      code: 'bestseller',
      flairTitle: 'BEST SELLER',
      flairColorBackground: '#34a853',
      flairColorText: '#ffffff',
      flairType: 'dynamic',
      flairTarget: 'product',
      flairId: '041701db-fc04-4987-847a-ec26bb4c1535',
    },
  ],
};

const devFlairs = {
  brandFlairs: [
    {
      code: 'creoatespecial',
      flairTitle: 'Creoate Special',
      flairColorBackground: '#d0ff00',
      flairColorText: '#142750',
      flairType: 'static',
      tooltip: '',
      flairTarget: 'brand',
      flairId: 'e33bb1aa-db95-4e53-95cb-ce5140769d8b',
    },
    {
      code: 'creoateloved',
      flairTitle: 'Creoate Loved',
      flairColorBackground: '#3e8075',
      flairColorText: '#ffffff',
      flairType: 'static',
      tooltip: '',
      flairTarget: 'brand',
      flairId: 'e357c810-9db5-43d9-8aa4-d344a484e38f',
    },
    {
      code: 'deliverysuccessrate',
      flairTitle: 'SUPER SHIPPER',
      flairColorBackground: '#34a853',
      flairColorText: '#ffffff',
      flairType: 'dynamic',
      flairTarget: 'brand',
      flairId: 'f5a198b2-7040-4613-a126-b6da5e175317',
    },
    {
      code: 'activeseller',
      flairTitle: 'Active Seller',
      flairColorBackground: '#191f2c',
      flairColorText: '#1a2f5d',
      flairType: 'static',
      flairTarget: 'brand',
      flairId: '0e83f561-084c-4872-8e47-ecd48b5e8b8b',
    },
    {
      code: 'manishstgtest',
      flairTitle: 'manish-stg-test',
      flairColorBackground: '#cacdd2',
      flairColorText: '#bdc4d5',
      flairType: 'static',
      flairTarget: 'brand',
      flairId: '7f23d05e-f908-4ec8-8925-d4cbfc41eb0d',
    },
    {
      code: 'manishflaircachetest',
      flairTitle: 'Manish Flair Cache Test',
      flairColorBackground: '#828ea8',
      flairColorText: '#c2cada',
      flairType: 'static',
      flairTarget: 'brand',
      flairId: '52b0b5d8-f0be-4b8f-a7f7-601570d8b6f2',
    },
    {
      code: 'kieranishere',
      flairTitle: 'Kieran Is Here',
      flairColorBackground: '#f516a7',
      flairColorText: '#fefefe',
      flairType: 'static',
      flairTarget: 'brand',
      flairId: 'f234fe05-f426-4ca1-ac68-423b43943efe',
    },
  ],
  productFlairs: [
    {
      code: 'highmargin',
      flairTitle: 'High Margin',
      flairColorBackground: '#e44da8',
      flairColorText: '#ffffff',
      flairType: 'dynamic',
      flairTarget: 'product',
      flairId: 'dc9d0f03-bef1-4e41-9933-0a68cddcf235',
    },
    {
      code: 'bundle',
      flairTitle: 'Bundle',
      flairColorBackground: '#749b8d',
      flairColorText: '#ffffff',
      flairType: 'dynamic',
      flairTarget: 'product',
      flairId: '285bcd1f-6ce6-4c64-98de-e5b5f5e18aa4',
    },
    {
      code: 'bestseller',
      flairTitle: 'Bestseller',
      flairColorBackground: '#749b8d',
      flairColorText: '#ffffff',
      flairType: 'dynamic',
      flairTarget: 'product',
      flairId: '56983f2e-fba6-41aa-b10a-48fa293d3f4d',
    },
    {
      code: 'starsupplier',
      flairTitle: 'STAR SUPPLIER',
      flairColorBackground: '#4a8d75',
      flairColorText: '#ffffff',
      flairType: 'dynamic',
      flairTarget: 'product',
      flairId: '93b4f0f9-2c31-45db-af51-62e80ef34416',
    },
  ],
};

const flairsToUse = NEXT_PUBLIC_ENV === 'production' ? productionFlairs : devFlairs;

export const brandFlairsList = flairsToUse.brandFlairs;
export const productFlairsList = flairsToUse.productFlairs;
