import NextImage from 'next/image';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

// Add support for the sx prop for consistency with the other branches.
const StyledImage = styled(NextImage)({});

// ----------------------------------------------------------------------

const Logo = ({ onClick = undefined, variant = 'full', width = undefined, height = undefined, ...otherProps }) => {
  return (
    <StyledImage
      width={width || (variant === 'mini' ? 64 : 260)}
      alt="Creoate"
      loading="lazy"
      height={height || (variant === 'mini' ? 64 : 92.44)}
      onClick={onClick}
      data-testid="app-bar-logo"
      src={`/assets/images/logo/${variant}.webp`}
      sx={{ ...(onClick && { cursor: 'pointer' }) }}
      {...otherProps}
    />
  );
};

Logo.propTypes = {
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.oneOf(['mini', 'full', 'pride']),
};

export default Logo;
