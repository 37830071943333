import bffAPI from 'services/api/bff';
import localAPI from 'services/api/local';

export async function getCart(retailerId) {
  return bffAPI.get(`/users/${retailerId}/cart`).then(({ data }) => data);
}

export async function getCheckoutCart(retailerId, currency, voucherCode, jwt) {
  return localAPI
    .post(`api/checkout/checkout-cart`, { retailerId, currency, voucherCode, jwt })
    .then(({ data }) => data);
}

export async function addToCart(retailerId, { quantity, productId, queryId = null, headers = {} }) {
  return bffAPI
    .post(`/users/${retailerId}/cart/add`, { quantity, productId, queryId }, { headers })
    .then(({ data }) => data);
}

export async function removeFromCart(retailerId, { itemId }) {
  return bffAPI.post(`/users/${retailerId}/cart/remove`, { itemId }).then(({ data }) => data);
}

export async function removeBrandFromCart(retailerId, { brandId }) {
  return bffAPI.post(`/users/${retailerId}/cart/remove?isBrandId=true`, { itemId: brandId }).then(({ data }) => data);
}

export async function increaseInCart(retailerId, { itemId, quantity }) {
  return bffAPI
    .post(`/users/${retailerId}/cart/item/${itemId}/add/${quantity}`, { itemId, quantity })
    .then(({ data }) => data);
}

export async function decreaseInCart(retailerId, { itemId, quantity }) {
  return bffAPI
    .post(`/users/${retailerId}/cart/item/${itemId}/remove/${quantity}`, { itemId, quantity })
    .then(({ data }) => data);
}

export async function clearCheckoutCart(retailerId, jwt) {
  return localAPI.post(`/users/${retailerId}/cart/clear`, { retailerId, jwt }).then(({ data }) => data);
}
