import { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { stringSimilarity } from 'string-similarity-js';
import { useLocalStorage } from 'react-use';

/** Hooks */
import useAuth from 'hooks/useAuth';

/* Components */
import SearchSuggestions from 'components/Header/SearchSuggestions';

/* MUI */
import Fade from '@mui/material/Fade';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, useTheme } from '@mui/material/styles';

/* Constants/Utils */
import { GTMRedirectedToBrandPageFromSearchBar, GTMSearchedTermUsedEvent } from 'utils/gtm';
import { ClickAwayListener } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
  border: `solid ${theme.spacing(0.0625)} #000`,
  borderRadius: theme.spacing(0.25),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '2rem',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    height: '2rem',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  height: '0.5rem',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    borderTopLeftRadius: theme.spacing(0.25),
    borderBottomLeftRadius: theme.spacing(0.25),
    width: '2.25rem',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  flexGrow: 1,
}));

const SearchSuggestionsWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'absolute',
  width: '100%',
  transform: 'translateY(100%)',
  bottom: -2,
  left: 0,
  borderRadius: 4,
  zIndex: 3,
}));

const searchBarDefaultPropSx = {};

const SearchBar = ({ sx = searchBarDefaultPropSx, submitEventName = '' }) => {
  const theme = useTheme();
  const router = useRouter();
  const { userId } = useAuth();
  const [, setLastSearch] = useLocalStorage('lastSearch', '');
  const [recentSearches, setRecentSearches] = useLocalStorage('recentSearches', []);

  const [brandsData, setBrandsData] = useState([]);
  const [search, setSearch] = useState(router?.query?.q || '');
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);

  const handleBrandDataUpdate = (data) => {
    setBrandsData(data);
  };

  const handleSearchInputChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
    if (String(value).length > 2) {
      setShowSearchSuggestions(true);
    } else {
      setShowSearchSuggestions(false);
    }
  };

  const handleSearchInputClick = (event) => {
    const {
      target: { value },
    } = event;
    if (String(value).length > 2) {
      setShowSearchSuggestions(true);
    }
  };

  const closeSearchSuggestions = () => {
    setShowSearchSuggestions(false);
  };

  const checkSearchValue = (value, isChipClick = false) => {
    if (value === '') {
      // do nothing if search query is empty
      return;
    }

    if (value) {
      const updatedSearches = [value, ...recentSearches.filter((item) => item !== value)].slice(0, 10);
      setRecentSearches(updatedSearches);
      setLastSearch(value);
      GTMSearchedTermUsedEvent(search, userId, submitEventName);
    }

    const matchBrand = brandsData?.find((brand) => stringSimilarity(brand?.wholesalerName, value) > 0.9);

    if (matchBrand) {
      GTMRedirectedToBrandPageFromSearchBar(
        {
          brandName: matchBrand?.wholesalerName,
          brandSlug: matchBrand?.wholesalerSlug,
          searchTerm: value,
          eventNameSuffix: submitEventName,
        },
        userId
      );
      router.push(`/store/${matchBrand.wholesalerSlug}?ref=stobrand${isChipClick ? '&ref2=preview-search' : ''}`);
    } else {
      router.push(`/search?q=${encodeURIComponent(value)}${isChipClick ? '&ref2=preview-search' : ''}`);
    }
    setShowSearchSuggestions(false);
  };

  const handleOnIconClick = () => {
    checkSearchValue(search);
  };

  const handleOnChipClick = (value) => {
    checkSearchValue(value, true);
  };

  const handleOnChipClickDelete = (value) => {
    const updatedSearches = [...recentSearches].filter((item) => item !== value);
    setRecentSearches(updatedSearches);
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closeSearchSuggestions}>
      <Search data-testid="app-bar-search" sx={{ ...sx }}>
        <StyledInputBase
          value={search}
          onKeyDown={(e) => e.key === 'Enter' && handleOnIconClick()}
          onChange={handleSearchInputChange}
          onClick={handleSearchInputClick}
          inputProps={{ 'aria-label': 'search', 'data-testid': 'search-bar__input' }}
          placeholder="Search for anything..."
          sx={{
            minWidth: '50%',
            fontSize: '0.75rem',
            fontFamily: 'Nunito',
            '@supports (-webkit-overflow-scrolling: touch)': {
              fontSize: '1rem',
            },
            '@media screen and (min-width: 2000px)': {
              fontSize: '1rem',
            },
          }}
        />
        <SearchIconWrapper>
          <SearchIcon
            onClick={handleOnIconClick}
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              [theme.breakpoints.down('sm')]: {
                color: theme.palette.background.default,
                fontSize: theme.spacing(1.125),
              },
            }}
          />
        </SearchIconWrapper>
        <Fade in={showSearchSuggestions} unmountOnExit>
          <SearchSuggestionsWrapper sx={{ boxShadow: 6 }}>
            <SearchSuggestions
              searchQuery={search}
              recentSearches={recentSearches}
              handleBrandDataUpdate={handleBrandDataUpdate}
              closeSearchSuggestions={closeSearchSuggestions}
              onChipClick={handleOnChipClick}
              onChipDelete={handleOnChipClickDelete}
            />
          </SearchSuggestionsWrapper>
        </Fade>
      </Search>
    </ClickAwayListener>
  );
};

export default SearchBar;

SearchBar.propTypes = {
  sx: PropTypes.shape({}),
  submitEventName: PropTypes.string,
};
