import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const textMaxLineDefaultPropsSx = {};

const TextMaxLine = forwardRef(
  ({ asLink = false, variant = 'body1', line = 2, children = null, sx = textMaxLineDefaultPropsSx, ...other }, ref) => {
    const style = {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: line,
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      ...sx,
    };

    if (asLink) {
      return (
        <Link color="inherit" ref={ref} variant={variant} sx={{ ...style }} {...other}>
          {children}
        </Link>
      );
    }

    return (
      <Typography ref={ref} variant={variant} sx={{ ...style }} {...other}>
        {children}
      </Typography>
    );
  }
);

TextMaxLine.propTypes = {
  asLink: PropTypes.bool,
  children: PropTypes.node,
  line: PropTypes.number,
  sx: PropTypes.shape({}),
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2',
  ]),
};

export default TextMaxLine;
