/* eslint-disable no-console */
import apiFactory from 'services/api/axios';

/**
 * Convert an error object to an enumerable object
 * @param {Error} error The error object to be converted
 * @returns {Record<string, any>} The enumerable object
 */
export const convertErrorToEnumerable = (error) => {
  const errorCopy = {};

  Object.getOwnPropertyNames(error ?? {}).forEach((key) => {
    errorCopy[key] = error[key];
  });

  return errorCopy;
};

export const isClientSide = () => typeof window !== 'undefined';

/**
 * Log data to the server
 * @param {any} data The data to be logged
 * @param {'info' | 'warn' | 'error' | 'log' | 'debug'} type The type of log
 * @param {string} message The message to be logged
 * @param {any} context Additional context for the log
 * @param {string} domain The domain/area the log is from
 * @param {string} [origin='client'] The origin of the log
 */
export const logToServer = async (type, message, context, domain, origin = 'client') => {
  const fetcher = apiFactory({
    baseURL: window.location.origin,
  });

  try {
    await fetcher.post('/api/log', {
      type,
      message,
      context,
      domain,
      origin,
    });
  } catch (err) {
    console.error('Error on logToServer. Error: ', err);
  }
};

export const logMessage = (type, message, context, domain, origin) => {
  let logFunction;
  switch (type) {
    case 'info':
      logFunction = console.info;
      break;
    case 'warn':
      logFunction = console.warn;
      break;
    case 'error':
      logFunction = console.error;
      break;
    default:
      logFunction = console.log;
  }

  // Try to stringify the message otherwise log the stringify
  try {
    logFunction(JSON.stringify({ message, domain, origin, context }));
  } catch (err) {
    console.log('Error on logMessage. Error: ', err);
  }
};

const delegateLog = (type, message, context, domain, origin) => {
  logMessage(type, message, context, domain, origin);

  // If the log is originated from the client, send it to the server
  if (isClientSide() && origin === 'client') {
    logToServer(type, message, context, domain, origin);
  }
};

export const serverLogger = {
  /**
   * Log data to the server with "DEBUG" level
   * @param {string} message The message to be logged
   * @param {any} context The payload of the log
   * @param {string} domain The domain which the log is related to
   * @param {'server' | 'client'} origin Where the log is originated from. When origin is `client`, the log will be sent to the server. Default is `server`
   */
  debug: (message, context, domain, origin = 'server') => delegateLog('debug', message, context, domain, origin),
  /**
   * Log data to the server with "INFO" level
   * @param {string} message The message to be logged
   * @param {any} context The payload of the log
   * @param {string} domain The domain which the log is related to
   * @param {'server' | 'client'} origin Where the log is originated from. When origin is `client`, the log will be sent to the server. Default is `server`
   */
  info: (message, context, domain, origin = 'server') => delegateLog('info', message, context, domain, origin),
  /**
   * Log data to the server with "WARN" level
   * @param {string} message The message to be logged
   * @param {any} context The payload of the log
   * @param {string} domain The domain which the log is related to
   * @param {'server' | 'client'} origin Where the log is originated from. When origin is `client`, the log will be sent to the server. Default is `server`.
   */
  warn: (message, context, domain, origin = 'server') => delegateLog('warn', message, context, domain, origin),
  /**
   * Log data to the server with "ERROR" level
   * @param {string} message The message to be logged
   * @param {any} context The payload of the log
   * @param {string} domain The domain which the log is related to
   * @param {'server' | 'client'} origin Where the log is originated from. When origin is `client`, the log will be sent to the server. Default is `server`.
   */
  error: (message, context, domain, origin = 'server') => delegateLog('error', message, context, domain, origin),
};
